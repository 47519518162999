/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const NumberInput: ComponentStyleConfig = {
    // The styles all inputs have in common
    baseStyle: () => ({
        field: {
            fontWeight: "400",
            borderRadius: "base",
            bg: "gray.10",
            _disabled: {
                bg: "gray.10",
                _hover: {
                    bg: "gray.10",
                },
            },
            _placeholder: {
                color: "grey.20",
            },
        },
    }),
    // Two sizes: sm and md
    sizes: {
        sm: {
            fontSize: "sm",
            px: 3,
            py: 3,
        },
        md: {
            fontSize: "md",
            px: 4,
            py: 4,
        },
    },
    variants: {
        filled: ({ colorMode, colorScheme }) => ({
            field: {
                bg: `inputs.${colorScheme}.${colorMode}.bg`,
                color: `inputs.${colorScheme}.${colorMode}.color`,
                _hover: {
                    bg: `inputs.${colorScheme}.${colorMode}.hover.bg`,
                    color: `inputs.${colorScheme}.${colorMode}.hover.color`,
                },
            },
        }),
    },
    defaultProps: {
        size: "md",
        variant: "filled",
        colorScheme: "default",
        focusBorderColor: "blue.100",
    },
};

// Colors are defined in ./Input.ts

export default NumberInput;
