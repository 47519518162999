/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as UnauthImport } from './routes/_unauth'
import { Route as DefaultImport } from './routes/_default'
import { Route as AuthImport } from './routes/_auth'
import { Route as UnauthSignupDoneImport } from './routes/_unauth/signup-done'
import { Route as UnauthSignupImport } from './routes/_unauth/signup'
import { Route as UnauthPrivacyImport } from './routes/_unauth/privacy'
import { Route as UnauthLoginImport } from './routes/_unauth/login'
import { Route as UnauthImprintImport } from './routes/_unauth/imprint'
import { Route as UnauthForgotPasswordImport } from './routes/_unauth/forgot-password'
import { Route as UnauthAgbImport } from './routes/_unauth/agb'
import { Route as AuthLogoutImport } from './routes/_auth/logout'
import { Route as AuthSmallViewImport } from './routes/_auth/_smallView'
import { Route as AuthFullViewImport } from './routes/_auth/_fullView'
import { Route as AuthDefaultViewImport } from './routes/_auth/_defaultView'
import { Route as AuthDefaultViewIndexImport } from './routes/_auth/_defaultView/index'
import { Route as UnauthTicketTokenImport } from './routes/_unauth/ticket.$token'
import { Route as DefaultValidateMailTokenImport } from './routes/_default/validate-mail.$token'
import { Route as DefaultResetPasswordTokenImport } from './routes/_default/reset-password.$token'
import { Route as DefaultConfirmEmailChangeTokenImport } from './routes/_default/confirm-email-change.$token'
import { Route as DefaultAcceptInviteTokenImport } from './routes/_default/accept-invite.$token'
import { Route as AuthSmallViewUnconfirmedImport } from './routes/_auth/_smallView/unconfirmed'
import { Route as AuthSmallViewFaqImport } from './routes/_auth/_smallView/faq'
import { Route as AuthSmallViewApiImport } from './routes/_auth/_smallView/api'
import { Route as AuthSmallViewSettingsLayoutImport } from './routes/_auth/_smallView/_settingsLayout'
import { Route as AuthDefaultViewInspectionImport } from './routes/_auth/_defaultView/inspection'
import { Route as AuthDefaultViewDashboardImport } from './routes/_auth/_defaultView/dashboard'
import { Route as langEnSplatImport } from './routes/(lang)/en.$'
import { Route as langDeSplatImport } from './routes/(lang)/de.$'
import { Route as AuthSmallViewTicketsIndexImport } from './routes/_auth/_smallView/tickets/index'
import { Route as AuthFullViewInvalidSubscriptionIndexImport } from './routes/_auth/_fullView/invalidSubscription/index'
import { Route as AuthDefaultViewUsersIndexImport } from './routes/_auth/_defaultView/users/index'
import { Route as AuthDefaultViewInspectionsIndexImport } from './routes/_auth/_defaultView/inspections/index'
import { Route as AuthDefaultViewCostcentersIndexImport } from './routes/_auth/_defaultView/costcenters/index'
import { Route as AuthSmallViewTicketsAddImport } from './routes/_auth/_smallView/tickets/add'
import { Route as AuthFullViewInvalidSubscriptionPaymentImport } from './routes/_auth/_fullView/invalidSubscription/payment'
import { Route as AuthFullViewInvalidSubscriptionDoneImport } from './routes/_auth/_fullView/invalidSubscription/done'
import { Route as AuthFullViewInvalidSubscriptionCompanyImport } from './routes/_auth/_fullView/invalidSubscription/company'
import { Route as AuthDefaultViewUsersArchivedImport } from './routes/_auth/_defaultView/users/archived'
import { Route as AuthDefaultViewSubscriptionDoneImport } from './routes/_auth/_defaultView/subscription.done'
import { Route as AuthDefaultViewInspectionsAddImport } from './routes/_auth/_defaultView/inspections/add'
import { Route as AuthDefaultViewApparatusesAddImport } from './routes/_auth/_defaultView/apparatuses/add'
import { Route as AuthDefaultViewApparatusesApparatusesMenueImport } from './routes/_auth/_defaultView/apparatuses/_apparatusesMenue'
import { Route as AuthSmallViewSettingsLayoutSettingsIndexImport } from './routes/_auth/_smallView/_settingsLayout/settings/index'
import { Route as AuthDefaultViewApparatusesApparatusesMenueIndexImport } from './routes/_auth/_defaultView/apparatuses/_apparatusesMenue/index'
import { Route as AuthSmallViewTicketsViewTicketIdImport } from './routes/_auth/_smallView/tickets/view.$ticketId'
import { Route as AuthSmallViewSettingsLayoutSettingsPasswordImport } from './routes/_auth/_smallView/_settingsLayout/settings/password'
import { Route as AuthSmallViewSettingsLayoutSettingsFilesImport } from './routes/_auth/_smallView/_settingsLayout/settings/files'
import { Route as AuthSmallViewSettingsLayoutSettingsCustomfieldsImport } from './routes/_auth/_smallView/_settingsLayout/settings/customfields'
import { Route as AuthSmallViewSettingsLayoutSettingsCompanyImport } from './routes/_auth/_smallView/_settingsLayout/settings/company'
import { Route as AuthSmallViewSettingsLayoutSettingsChangeImport } from './routes/_auth/_smallView/_settingsLayout/settings/change'
import { Route as AuthDefaultViewUsersViewIdImport } from './routes/_auth/_defaultView/users/view.$id'
import { Route as AuthDefaultViewTestplansViewIdImport } from './routes/_auth/_defaultView/testplans/view.$id'
import { Route as AuthDefaultViewTestplansEditIdImport } from './routes/_auth/_defaultView/testplans/edit.$id'
import { Route as AuthDefaultViewTestplansAddApparatusIdImport } from './routes/_auth/_defaultView/testplans/add.$apparatusId'
import { Route as AuthDefaultViewInspectionsViewIdImport } from './routes/_auth/_defaultView/inspections/view.$id'
import { Route as AuthDefaultViewInspectionsEditIdImport } from './routes/_auth/_defaultView/inspections/edit.$id'
import { Route as AuthDefaultViewInspectionsAddApparatusIdImport } from './routes/_auth/_defaultView/inspections/add.$apparatusId'
import { Route as AuthDefaultViewCostcentersViewIdImport } from './routes/_auth/_defaultView/costcenters/view.$id'
import { Route as AuthDefaultViewApparatusesViewIdImport } from './routes/_auth/_defaultView/apparatuses/view.$id'
import { Route as AuthDefaultViewApparatusesEditIdImport } from './routes/_auth/_defaultView/apparatuses/edit.$id'
import { Route as AuthDefaultViewApparatusesApparatusesMenueExportImport } from './routes/_auth/_defaultView/apparatuses/_apparatusesMenue/export'
import { Route as AuthSmallViewSettingsLayoutSettingsMandantorIndexImport } from './routes/_auth/_smallView/_settingsLayout/settings/mandantor/index'
import { Route as AuthSmallViewSettingsLayoutSettingsSubscriptionInfoImport } from './routes/_auth/_smallView/_settingsLayout/settings/subscription/info'
import { Route as AuthSmallViewSettingsLayoutSettingsSubscriptionDoneImport } from './routes/_auth/_smallView/_settingsLayout/settings/subscription/done'
import { Route as AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutImport } from './routes/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout'
import { Route as AuthSmallViewSettingsLayoutSettingsMandantorChangeImport } from './routes/_auth/_smallView/_settingsLayout/settings/mandantor/change'
import { Route as AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexImport } from './routes/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/index'
import { Route as AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentImport } from './routes/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/payment'

// Create Virtual Routes

const AuthDefaultViewApparatusesImport = createFileRoute(
  '/_auth/_defaultView/apparatuses',
)()
const AuthSmallViewSettingsLayoutSettingsSubscriptionImport = createFileRoute(
  '/_auth/_smallView/_settingsLayout/settings/subscription',
)()

// Create/Update Routes

const UnauthRoute = UnauthImport.update({
  id: '/_unauth',
  getParentRoute: () => rootRoute,
} as any)

const DefaultRoute = DefaultImport.update({
  id: '/_default',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const UnauthSignupDoneRoute = UnauthSignupDoneImport.update({
  id: '/signup-done',
  path: '/signup-done',
  getParentRoute: () => UnauthRoute,
} as any)

const UnauthSignupRoute = UnauthSignupImport.update({
  id: '/signup',
  path: '/signup',
  getParentRoute: () => UnauthRoute,
} as any)

const UnauthPrivacyRoute = UnauthPrivacyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => UnauthRoute,
} as any)

const UnauthLoginRoute = UnauthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => UnauthRoute,
} as any)

const UnauthImprintRoute = UnauthImprintImport.update({
  id: '/imprint',
  path: '/imprint',
  getParentRoute: () => UnauthRoute,
} as any)

const UnauthForgotPasswordRoute = UnauthForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => UnauthRoute,
} as any)

const UnauthAgbRoute = UnauthAgbImport.update({
  id: '/agb',
  path: '/agb',
  getParentRoute: () => UnauthRoute,
} as any)

const AuthLogoutRoute = AuthLogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSmallViewRoute = AuthSmallViewImport.update({
  id: '/_smallView',
  getParentRoute: () => AuthRoute,
} as any)

const AuthFullViewRoute = AuthFullViewImport.update({
  id: '/_fullView',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDefaultViewRoute = AuthDefaultViewImport.update({
  id: '/_defaultView',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDefaultViewApparatusesRoute = AuthDefaultViewApparatusesImport.update(
  {
    id: '/apparatuses',
    path: '/apparatuses',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any,
)

const AuthDefaultViewIndexRoute = AuthDefaultViewIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthDefaultViewRoute,
} as any).lazy(() =>
  import('./routes/_auth/_defaultView/index.lazy').then((d) => d.Route),
)

const UnauthTicketTokenRoute = UnauthTicketTokenImport.update({
  id: '/ticket/$token',
  path: '/ticket/$token',
  getParentRoute: () => UnauthRoute,
} as any)

const DefaultValidateMailTokenRoute = DefaultValidateMailTokenImport.update({
  id: '/validate-mail/$token',
  path: '/validate-mail/$token',
  getParentRoute: () => DefaultRoute,
} as any)

const DefaultResetPasswordTokenRoute = DefaultResetPasswordTokenImport.update({
  id: '/reset-password/$token',
  path: '/reset-password/$token',
  getParentRoute: () => DefaultRoute,
} as any)

const DefaultConfirmEmailChangeTokenRoute =
  DefaultConfirmEmailChangeTokenImport.update({
    id: '/confirm-email-change/$token',
    path: '/confirm-email-change/$token',
    getParentRoute: () => DefaultRoute,
  } as any)

const DefaultAcceptInviteTokenRoute = DefaultAcceptInviteTokenImport.update({
  id: '/accept-invite/$token',
  path: '/accept-invite/$token',
  getParentRoute: () => DefaultRoute,
} as any)

const AuthSmallViewUnconfirmedRoute = AuthSmallViewUnconfirmedImport.update({
  id: '/unconfirmed',
  path: '/unconfirmed',
  getParentRoute: () => AuthSmallViewRoute,
} as any)

const AuthSmallViewFaqRoute = AuthSmallViewFaqImport.update({
  id: '/faq',
  path: '/faq',
  getParentRoute: () => AuthSmallViewRoute,
} as any)

const AuthSmallViewApiRoute = AuthSmallViewApiImport.update({
  id: '/api',
  path: '/api',
  getParentRoute: () => AuthSmallViewRoute,
} as any)

const AuthSmallViewSettingsLayoutRoute =
  AuthSmallViewSettingsLayoutImport.update({
    id: '/_settingsLayout',
    getParentRoute: () => AuthSmallViewRoute,
  } as any)

const AuthDefaultViewInspectionRoute = AuthDefaultViewInspectionImport.update({
  id: '/inspection',
  path: '/inspection',
  getParentRoute: () => AuthDefaultViewRoute,
} as any)

const AuthDefaultViewDashboardRoute = AuthDefaultViewDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => AuthDefaultViewRoute,
} as any).lazy(() =>
  import('./routes/_auth/_defaultView/dashboard.lazy').then((d) => d.Route),
)

const langEnSplatRoute = langEnSplatImport.update({
  id: '/(lang)/en/$',
  path: '/en/$',
  getParentRoute: () => rootRoute,
} as any)

const langDeSplatRoute = langDeSplatImport.update({
  id: '/(lang)/de/$',
  path: '/de/$',
  getParentRoute: () => rootRoute,
} as any)

const AuthSmallViewTicketsIndexRoute = AuthSmallViewTicketsIndexImport.update({
  id: '/tickets/',
  path: '/tickets/',
  getParentRoute: () => AuthSmallViewRoute,
} as any)

const AuthFullViewInvalidSubscriptionIndexRoute =
  AuthFullViewInvalidSubscriptionIndexImport.update({
    id: '/invalidSubscription/',
    path: '/invalidSubscription/',
    getParentRoute: () => AuthFullViewRoute,
  } as any)

const AuthDefaultViewUsersIndexRoute = AuthDefaultViewUsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => AuthDefaultViewRoute,
} as any)

const AuthDefaultViewInspectionsIndexRoute =
  AuthDefaultViewInspectionsIndexImport.update({
    id: '/inspections/',
    path: '/inspections/',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthDefaultViewCostcentersIndexRoute =
  AuthDefaultViewCostcentersIndexImport.update({
    id: '/costcenters/',
    path: '/costcenters/',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthSmallViewTicketsAddRoute = AuthSmallViewTicketsAddImport.update({
  id: '/tickets/add',
  path: '/tickets/add',
  getParentRoute: () => AuthSmallViewRoute,
} as any)

const AuthFullViewInvalidSubscriptionPaymentRoute =
  AuthFullViewInvalidSubscriptionPaymentImport.update({
    id: '/invalidSubscription/payment',
    path: '/invalidSubscription/payment',
    getParentRoute: () => AuthFullViewRoute,
  } as any)

const AuthFullViewInvalidSubscriptionDoneRoute =
  AuthFullViewInvalidSubscriptionDoneImport.update({
    id: '/invalidSubscription/done',
    path: '/invalidSubscription/done',
    getParentRoute: () => AuthFullViewRoute,
  } as any)

const AuthFullViewInvalidSubscriptionCompanyRoute =
  AuthFullViewInvalidSubscriptionCompanyImport.update({
    id: '/invalidSubscription/company',
    path: '/invalidSubscription/company',
    getParentRoute: () => AuthFullViewRoute,
  } as any)

const AuthDefaultViewUsersArchivedRoute =
  AuthDefaultViewUsersArchivedImport.update({
    id: '/users/archived',
    path: '/users/archived',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthDefaultViewSubscriptionDoneRoute =
  AuthDefaultViewSubscriptionDoneImport.update({
    id: '/subscription/done',
    path: '/subscription/done',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthDefaultViewInspectionsAddRoute =
  AuthDefaultViewInspectionsAddImport.update({
    id: '/inspections/add',
    path: '/inspections/add',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthDefaultViewApparatusesAddRoute =
  AuthDefaultViewApparatusesAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => AuthDefaultViewApparatusesRoute,
  } as any)

const AuthDefaultViewApparatusesApparatusesMenueRoute =
  AuthDefaultViewApparatusesApparatusesMenueImport.update({
    id: '/_apparatusesMenue',
    getParentRoute: () => AuthDefaultViewApparatusesRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsSubscriptionRoute =
  AuthSmallViewSettingsLayoutSettingsSubscriptionImport.update({
    id: '/settings/subscription',
    path: '/settings/subscription',
    getParentRoute: () => AuthSmallViewSettingsLayoutRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsIndexRoute =
  AuthSmallViewSettingsLayoutSettingsIndexImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => AuthSmallViewSettingsLayoutRoute,
  } as any)

const AuthDefaultViewApparatusesApparatusesMenueIndexRoute =
  AuthDefaultViewApparatusesApparatusesMenueIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthDefaultViewApparatusesApparatusesMenueRoute,
  } as any)

const AuthSmallViewTicketsViewTicketIdRoute =
  AuthSmallViewTicketsViewTicketIdImport.update({
    id: '/tickets/view/$ticketId',
    path: '/tickets/view/$ticketId',
    getParentRoute: () => AuthSmallViewRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsPasswordRoute =
  AuthSmallViewSettingsLayoutSettingsPasswordImport.update({
    id: '/settings/password',
    path: '/settings/password',
    getParentRoute: () => AuthSmallViewSettingsLayoutRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsFilesRoute =
  AuthSmallViewSettingsLayoutSettingsFilesImport.update({
    id: '/settings/files',
    path: '/settings/files',
    getParentRoute: () => AuthSmallViewSettingsLayoutRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsCustomfieldsRoute =
  AuthSmallViewSettingsLayoutSettingsCustomfieldsImport.update({
    id: '/settings/customfields',
    path: '/settings/customfields',
    getParentRoute: () => AuthSmallViewSettingsLayoutRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsCompanyRoute =
  AuthSmallViewSettingsLayoutSettingsCompanyImport.update({
    id: '/settings/company',
    path: '/settings/company',
    getParentRoute: () => AuthSmallViewSettingsLayoutRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsChangeRoute =
  AuthSmallViewSettingsLayoutSettingsChangeImport.update({
    id: '/settings/change',
    path: '/settings/change',
    getParentRoute: () => AuthSmallViewSettingsLayoutRoute,
  } as any)

const AuthDefaultViewUsersViewIdRoute = AuthDefaultViewUsersViewIdImport.update(
  {
    id: '/users/view/$id',
    path: '/users/view/$id',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any,
)

const AuthDefaultViewTestplansViewIdRoute =
  AuthDefaultViewTestplansViewIdImport.update({
    id: '/testplans/view/$id',
    path: '/testplans/view/$id',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthDefaultViewTestplansEditIdRoute =
  AuthDefaultViewTestplansEditIdImport.update({
    id: '/testplans/edit/$id',
    path: '/testplans/edit/$id',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthDefaultViewTestplansAddApparatusIdRoute =
  AuthDefaultViewTestplansAddApparatusIdImport.update({
    id: '/testplans/add/$apparatusId',
    path: '/testplans/add/$apparatusId',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthDefaultViewInspectionsViewIdRoute =
  AuthDefaultViewInspectionsViewIdImport.update({
    id: '/inspections/view/$id',
    path: '/inspections/view/$id',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthDefaultViewInspectionsEditIdRoute =
  AuthDefaultViewInspectionsEditIdImport.update({
    id: '/inspections/edit/$id',
    path: '/inspections/edit/$id',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthDefaultViewInspectionsAddApparatusIdRoute =
  AuthDefaultViewInspectionsAddApparatusIdImport.update({
    id: '/$apparatusId',
    path: '/$apparatusId',
    getParentRoute: () => AuthDefaultViewInspectionsAddRoute,
  } as any)

const AuthDefaultViewCostcentersViewIdRoute =
  AuthDefaultViewCostcentersViewIdImport.update({
    id: '/costcenters/view/$id',
    path: '/costcenters/view/$id',
    getParentRoute: () => AuthDefaultViewRoute,
  } as any)

const AuthDefaultViewApparatusesViewIdRoute =
  AuthDefaultViewApparatusesViewIdImport.update({
    id: '/view/$id',
    path: '/view/$id',
    getParentRoute: () => AuthDefaultViewApparatusesRoute,
  } as any)

const AuthDefaultViewApparatusesEditIdRoute =
  AuthDefaultViewApparatusesEditIdImport.update({
    id: '/edit/$id',
    path: '/edit/$id',
    getParentRoute: () => AuthDefaultViewApparatusesRoute,
  } as any)

const AuthDefaultViewApparatusesApparatusesMenueExportRoute =
  AuthDefaultViewApparatusesApparatusesMenueExportImport.update({
    id: '/export',
    path: '/export',
    getParentRoute: () => AuthDefaultViewApparatusesApparatusesMenueRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsMandantorIndexRoute =
  AuthSmallViewSettingsLayoutSettingsMandantorIndexImport.update({
    id: '/settings/mandantor/',
    path: '/settings/mandantor/',
    getParentRoute: () => AuthSmallViewSettingsLayoutRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsSubscriptionInfoRoute =
  AuthSmallViewSettingsLayoutSettingsSubscriptionInfoImport.update({
    id: '/info',
    path: '/info',
    getParentRoute: () => AuthSmallViewSettingsLayoutSettingsSubscriptionRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsSubscriptionDoneRoute =
  AuthSmallViewSettingsLayoutSettingsSubscriptionDoneImport.update({
    id: '/done',
    path: '/done',
    getParentRoute: () => AuthSmallViewSettingsLayoutSettingsSubscriptionRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRoute =
  AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutImport.update(
    {
      id: '/_subscriptionLayout',
      getParentRoute: () =>
        AuthSmallViewSettingsLayoutSettingsSubscriptionRoute,
    } as any,
  )

const AuthSmallViewSettingsLayoutSettingsMandantorChangeRoute =
  AuthSmallViewSettingsLayoutSettingsMandantorChangeImport.update({
    id: '/settings/mandantor/change',
    path: '/settings/mandantor/change',
    getParentRoute: () => AuthSmallViewSettingsLayoutRoute,
  } as any)

const AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexRoute =
  AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexImport.update(
    {
      id: '/',
      path: '/',
      getParentRoute: () =>
        AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRoute,
    } as any,
  )

const AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentRoute =
  AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentImport.update(
    {
      id: '/payment',
      path: '/payment',
      getParentRoute: () =>
        AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_default': {
      id: '/_default'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DefaultImport
      parentRoute: typeof rootRoute
    }
    '/_unauth': {
      id: '/_unauth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof UnauthImport
      parentRoute: typeof rootRoute
    }
    '/_auth/_defaultView': {
      id: '/_auth/_defaultView'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthDefaultViewImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_fullView': {
      id: '/_auth/_fullView'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthFullViewImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_smallView': {
      id: '/_auth/_smallView'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthSmallViewImport
      parentRoute: typeof AuthImport
    }
    '/_auth/logout': {
      id: '/_auth/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof AuthLogoutImport
      parentRoute: typeof AuthImport
    }
    '/_unauth/agb': {
      id: '/_unauth/agb'
      path: '/agb'
      fullPath: '/agb'
      preLoaderRoute: typeof UnauthAgbImport
      parentRoute: typeof UnauthImport
    }
    '/_unauth/forgot-password': {
      id: '/_unauth/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof UnauthForgotPasswordImport
      parentRoute: typeof UnauthImport
    }
    '/_unauth/imprint': {
      id: '/_unauth/imprint'
      path: '/imprint'
      fullPath: '/imprint'
      preLoaderRoute: typeof UnauthImprintImport
      parentRoute: typeof UnauthImport
    }
    '/_unauth/login': {
      id: '/_unauth/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof UnauthLoginImport
      parentRoute: typeof UnauthImport
    }
    '/_unauth/privacy': {
      id: '/_unauth/privacy'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof UnauthPrivacyImport
      parentRoute: typeof UnauthImport
    }
    '/_unauth/signup': {
      id: '/_unauth/signup'
      path: '/signup'
      fullPath: '/signup'
      preLoaderRoute: typeof UnauthSignupImport
      parentRoute: typeof UnauthImport
    }
    '/_unauth/signup-done': {
      id: '/_unauth/signup-done'
      path: '/signup-done'
      fullPath: '/signup-done'
      preLoaderRoute: typeof UnauthSignupDoneImport
      parentRoute: typeof UnauthImport
    }
    '/(lang)/de/$': {
      id: '/(lang)/de/$'
      path: '/de/$'
      fullPath: '/de/$'
      preLoaderRoute: typeof langDeSplatImport
      parentRoute: typeof rootRoute
    }
    '/(lang)/en/$': {
      id: '/(lang)/en/$'
      path: '/en/$'
      fullPath: '/en/$'
      preLoaderRoute: typeof langEnSplatImport
      parentRoute: typeof rootRoute
    }
    '/_auth/_defaultView/dashboard': {
      id: '/_auth/_defaultView/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof AuthDefaultViewDashboardImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/inspection': {
      id: '/_auth/_defaultView/inspection'
      path: '/inspection'
      fullPath: '/inspection'
      preLoaderRoute: typeof AuthDefaultViewInspectionImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_smallView/_settingsLayout': {
      id: '/_auth/_smallView/_settingsLayout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutImport
      parentRoute: typeof AuthSmallViewImport
    }
    '/_auth/_smallView/api': {
      id: '/_auth/_smallView/api'
      path: '/api'
      fullPath: '/api'
      preLoaderRoute: typeof AuthSmallViewApiImport
      parentRoute: typeof AuthSmallViewImport
    }
    '/_auth/_smallView/faq': {
      id: '/_auth/_smallView/faq'
      path: '/faq'
      fullPath: '/faq'
      preLoaderRoute: typeof AuthSmallViewFaqImport
      parentRoute: typeof AuthSmallViewImport
    }
    '/_auth/_smallView/unconfirmed': {
      id: '/_auth/_smallView/unconfirmed'
      path: '/unconfirmed'
      fullPath: '/unconfirmed'
      preLoaderRoute: typeof AuthSmallViewUnconfirmedImport
      parentRoute: typeof AuthSmallViewImport
    }
    '/_default/accept-invite/$token': {
      id: '/_default/accept-invite/$token'
      path: '/accept-invite/$token'
      fullPath: '/accept-invite/$token'
      preLoaderRoute: typeof DefaultAcceptInviteTokenImport
      parentRoute: typeof DefaultImport
    }
    '/_default/confirm-email-change/$token': {
      id: '/_default/confirm-email-change/$token'
      path: '/confirm-email-change/$token'
      fullPath: '/confirm-email-change/$token'
      preLoaderRoute: typeof DefaultConfirmEmailChangeTokenImport
      parentRoute: typeof DefaultImport
    }
    '/_default/reset-password/$token': {
      id: '/_default/reset-password/$token'
      path: '/reset-password/$token'
      fullPath: '/reset-password/$token'
      preLoaderRoute: typeof DefaultResetPasswordTokenImport
      parentRoute: typeof DefaultImport
    }
    '/_default/validate-mail/$token': {
      id: '/_default/validate-mail/$token'
      path: '/validate-mail/$token'
      fullPath: '/validate-mail/$token'
      preLoaderRoute: typeof DefaultValidateMailTokenImport
      parentRoute: typeof DefaultImport
    }
    '/_unauth/ticket/$token': {
      id: '/_unauth/ticket/$token'
      path: '/ticket/$token'
      fullPath: '/ticket/$token'
      preLoaderRoute: typeof UnauthTicketTokenImport
      parentRoute: typeof UnauthImport
    }
    '/_auth/_defaultView/': {
      id: '/_auth/_defaultView/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthDefaultViewIndexImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/apparatuses': {
      id: '/_auth/_defaultView/apparatuses'
      path: '/apparatuses'
      fullPath: '/apparatuses'
      preLoaderRoute: typeof AuthDefaultViewApparatusesImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/apparatuses/_apparatusesMenue': {
      id: '/_auth/_defaultView/apparatuses/_apparatusesMenue'
      path: '/apparatuses'
      fullPath: '/apparatuses'
      preLoaderRoute: typeof AuthDefaultViewApparatusesApparatusesMenueImport
      parentRoute: typeof AuthDefaultViewApparatusesRoute
    }
    '/_auth/_defaultView/apparatuses/add': {
      id: '/_auth/_defaultView/apparatuses/add'
      path: '/add'
      fullPath: '/apparatuses/add'
      preLoaderRoute: typeof AuthDefaultViewApparatusesAddImport
      parentRoute: typeof AuthDefaultViewApparatusesImport
    }
    '/_auth/_defaultView/inspections/add': {
      id: '/_auth/_defaultView/inspections/add'
      path: '/inspections/add'
      fullPath: '/inspections/add'
      preLoaderRoute: typeof AuthDefaultViewInspectionsAddImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/subscription/done': {
      id: '/_auth/_defaultView/subscription/done'
      path: '/subscription/done'
      fullPath: '/subscription/done'
      preLoaderRoute: typeof AuthDefaultViewSubscriptionDoneImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/users/archived': {
      id: '/_auth/_defaultView/users/archived'
      path: '/users/archived'
      fullPath: '/users/archived'
      preLoaderRoute: typeof AuthDefaultViewUsersArchivedImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_fullView/invalidSubscription/company': {
      id: '/_auth/_fullView/invalidSubscription/company'
      path: '/invalidSubscription/company'
      fullPath: '/invalidSubscription/company'
      preLoaderRoute: typeof AuthFullViewInvalidSubscriptionCompanyImport
      parentRoute: typeof AuthFullViewImport
    }
    '/_auth/_fullView/invalidSubscription/done': {
      id: '/_auth/_fullView/invalidSubscription/done'
      path: '/invalidSubscription/done'
      fullPath: '/invalidSubscription/done'
      preLoaderRoute: typeof AuthFullViewInvalidSubscriptionDoneImport
      parentRoute: typeof AuthFullViewImport
    }
    '/_auth/_fullView/invalidSubscription/payment': {
      id: '/_auth/_fullView/invalidSubscription/payment'
      path: '/invalidSubscription/payment'
      fullPath: '/invalidSubscription/payment'
      preLoaderRoute: typeof AuthFullViewInvalidSubscriptionPaymentImport
      parentRoute: typeof AuthFullViewImport
    }
    '/_auth/_smallView/tickets/add': {
      id: '/_auth/_smallView/tickets/add'
      path: '/tickets/add'
      fullPath: '/tickets/add'
      preLoaderRoute: typeof AuthSmallViewTicketsAddImport
      parentRoute: typeof AuthSmallViewImport
    }
    '/_auth/_defaultView/costcenters/': {
      id: '/_auth/_defaultView/costcenters/'
      path: '/costcenters'
      fullPath: '/costcenters'
      preLoaderRoute: typeof AuthDefaultViewCostcentersIndexImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/inspections/': {
      id: '/_auth/_defaultView/inspections/'
      path: '/inspections'
      fullPath: '/inspections'
      preLoaderRoute: typeof AuthDefaultViewInspectionsIndexImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/users/': {
      id: '/_auth/_defaultView/users/'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof AuthDefaultViewUsersIndexImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_fullView/invalidSubscription/': {
      id: '/_auth/_fullView/invalidSubscription/'
      path: '/invalidSubscription'
      fullPath: '/invalidSubscription'
      preLoaderRoute: typeof AuthFullViewInvalidSubscriptionIndexImport
      parentRoute: typeof AuthFullViewImport
    }
    '/_auth/_smallView/tickets/': {
      id: '/_auth/_smallView/tickets/'
      path: '/tickets'
      fullPath: '/tickets'
      preLoaderRoute: typeof AuthSmallViewTicketsIndexImport
      parentRoute: typeof AuthSmallViewImport
    }
    '/_auth/_defaultView/apparatuses/_apparatusesMenue/export': {
      id: '/_auth/_defaultView/apparatuses/_apparatusesMenue/export'
      path: '/export'
      fullPath: '/apparatuses/export'
      preLoaderRoute: typeof AuthDefaultViewApparatusesApparatusesMenueExportImport
      parentRoute: typeof AuthDefaultViewApparatusesApparatusesMenueImport
    }
    '/_auth/_defaultView/apparatuses/edit/$id': {
      id: '/_auth/_defaultView/apparatuses/edit/$id'
      path: '/edit/$id'
      fullPath: '/apparatuses/edit/$id'
      preLoaderRoute: typeof AuthDefaultViewApparatusesEditIdImport
      parentRoute: typeof AuthDefaultViewApparatusesImport
    }
    '/_auth/_defaultView/apparatuses/view/$id': {
      id: '/_auth/_defaultView/apparatuses/view/$id'
      path: '/view/$id'
      fullPath: '/apparatuses/view/$id'
      preLoaderRoute: typeof AuthDefaultViewApparatusesViewIdImport
      parentRoute: typeof AuthDefaultViewApparatusesImport
    }
    '/_auth/_defaultView/costcenters/view/$id': {
      id: '/_auth/_defaultView/costcenters/view/$id'
      path: '/costcenters/view/$id'
      fullPath: '/costcenters/view/$id'
      preLoaderRoute: typeof AuthDefaultViewCostcentersViewIdImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/inspections/add/$apparatusId': {
      id: '/_auth/_defaultView/inspections/add/$apparatusId'
      path: '/$apparatusId'
      fullPath: '/inspections/add/$apparatusId'
      preLoaderRoute: typeof AuthDefaultViewInspectionsAddApparatusIdImport
      parentRoute: typeof AuthDefaultViewInspectionsAddImport
    }
    '/_auth/_defaultView/inspections/edit/$id': {
      id: '/_auth/_defaultView/inspections/edit/$id'
      path: '/inspections/edit/$id'
      fullPath: '/inspections/edit/$id'
      preLoaderRoute: typeof AuthDefaultViewInspectionsEditIdImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/inspections/view/$id': {
      id: '/_auth/_defaultView/inspections/view/$id'
      path: '/inspections/view/$id'
      fullPath: '/inspections/view/$id'
      preLoaderRoute: typeof AuthDefaultViewInspectionsViewIdImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/testplans/add/$apparatusId': {
      id: '/_auth/_defaultView/testplans/add/$apparatusId'
      path: '/testplans/add/$apparatusId'
      fullPath: '/testplans/add/$apparatusId'
      preLoaderRoute: typeof AuthDefaultViewTestplansAddApparatusIdImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/testplans/edit/$id': {
      id: '/_auth/_defaultView/testplans/edit/$id'
      path: '/testplans/edit/$id'
      fullPath: '/testplans/edit/$id'
      preLoaderRoute: typeof AuthDefaultViewTestplansEditIdImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/testplans/view/$id': {
      id: '/_auth/_defaultView/testplans/view/$id'
      path: '/testplans/view/$id'
      fullPath: '/testplans/view/$id'
      preLoaderRoute: typeof AuthDefaultViewTestplansViewIdImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_defaultView/users/view/$id': {
      id: '/_auth/_defaultView/users/view/$id'
      path: '/users/view/$id'
      fullPath: '/users/view/$id'
      preLoaderRoute: typeof AuthDefaultViewUsersViewIdImport
      parentRoute: typeof AuthDefaultViewImport
    }
    '/_auth/_smallView/_settingsLayout/settings/change': {
      id: '/_auth/_smallView/_settingsLayout/settings/change'
      path: '/settings/change'
      fullPath: '/settings/change'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsChangeImport
      parentRoute: typeof AuthSmallViewSettingsLayoutImport
    }
    '/_auth/_smallView/_settingsLayout/settings/company': {
      id: '/_auth/_smallView/_settingsLayout/settings/company'
      path: '/settings/company'
      fullPath: '/settings/company'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsCompanyImport
      parentRoute: typeof AuthSmallViewSettingsLayoutImport
    }
    '/_auth/_smallView/_settingsLayout/settings/customfields': {
      id: '/_auth/_smallView/_settingsLayout/settings/customfields'
      path: '/settings/customfields'
      fullPath: '/settings/customfields'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsCustomfieldsImport
      parentRoute: typeof AuthSmallViewSettingsLayoutImport
    }
    '/_auth/_smallView/_settingsLayout/settings/files': {
      id: '/_auth/_smallView/_settingsLayout/settings/files'
      path: '/settings/files'
      fullPath: '/settings/files'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsFilesImport
      parentRoute: typeof AuthSmallViewSettingsLayoutImport
    }
    '/_auth/_smallView/_settingsLayout/settings/password': {
      id: '/_auth/_smallView/_settingsLayout/settings/password'
      path: '/settings/password'
      fullPath: '/settings/password'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsPasswordImport
      parentRoute: typeof AuthSmallViewSettingsLayoutImport
    }
    '/_auth/_smallView/tickets/view/$ticketId': {
      id: '/_auth/_smallView/tickets/view/$ticketId'
      path: '/tickets/view/$ticketId'
      fullPath: '/tickets/view/$ticketId'
      preLoaderRoute: typeof AuthSmallViewTicketsViewTicketIdImport
      parentRoute: typeof AuthSmallViewImport
    }
    '/_auth/_defaultView/apparatuses/_apparatusesMenue/': {
      id: '/_auth/_defaultView/apparatuses/_apparatusesMenue/'
      path: '/'
      fullPath: '/apparatuses/'
      preLoaderRoute: typeof AuthDefaultViewApparatusesApparatusesMenueIndexImport
      parentRoute: typeof AuthDefaultViewApparatusesApparatusesMenueImport
    }
    '/_auth/_smallView/_settingsLayout/settings/': {
      id: '/_auth/_smallView/_settingsLayout/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsIndexImport
      parentRoute: typeof AuthSmallViewSettingsLayoutImport
    }
    '/_auth/_smallView/_settingsLayout/settings/mandantor/change': {
      id: '/_auth/_smallView/_settingsLayout/settings/mandantor/change'
      path: '/settings/mandantor/change'
      fullPath: '/settings/mandantor/change'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsMandantorChangeImport
      parentRoute: typeof AuthSmallViewSettingsLayoutImport
    }
    '/_auth/_smallView/_settingsLayout/settings/subscription': {
      id: '/_auth/_smallView/_settingsLayout/settings/subscription'
      path: '/settings/subscription'
      fullPath: '/settings/subscription'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionImport
      parentRoute: typeof AuthSmallViewSettingsLayoutImport
    }
    '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout': {
      id: '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout'
      path: '/settings/subscription'
      fullPath: '/settings/subscription'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutImport
      parentRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionRoute
    }
    '/_auth/_smallView/_settingsLayout/settings/subscription/done': {
      id: '/_auth/_smallView/_settingsLayout/settings/subscription/done'
      path: '/done'
      fullPath: '/settings/subscription/done'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionDoneImport
      parentRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionImport
    }
    '/_auth/_smallView/_settingsLayout/settings/subscription/info': {
      id: '/_auth/_smallView/_settingsLayout/settings/subscription/info'
      path: '/info'
      fullPath: '/settings/subscription/info'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionInfoImport
      parentRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionImport
    }
    '/_auth/_smallView/_settingsLayout/settings/mandantor/': {
      id: '/_auth/_smallView/_settingsLayout/settings/mandantor/'
      path: '/settings/mandantor'
      fullPath: '/settings/mandantor'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsMandantorIndexImport
      parentRoute: typeof AuthSmallViewSettingsLayoutImport
    }
    '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/payment': {
      id: '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/payment'
      path: '/payment'
      fullPath: '/settings/subscription/payment'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentImport
      parentRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutImport
    }
    '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/': {
      id: '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/'
      path: '/'
      fullPath: '/settings/subscription/'
      preLoaderRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexImport
      parentRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutImport
    }
  }
}

// Create and export the route tree

interface AuthDefaultViewApparatusesApparatusesMenueRouteChildren {
  AuthDefaultViewApparatusesApparatusesMenueExportRoute: typeof AuthDefaultViewApparatusesApparatusesMenueExportRoute
  AuthDefaultViewApparatusesApparatusesMenueIndexRoute: typeof AuthDefaultViewApparatusesApparatusesMenueIndexRoute
}

const AuthDefaultViewApparatusesApparatusesMenueRouteChildren: AuthDefaultViewApparatusesApparatusesMenueRouteChildren =
  {
    AuthDefaultViewApparatusesApparatusesMenueExportRoute:
      AuthDefaultViewApparatusesApparatusesMenueExportRoute,
    AuthDefaultViewApparatusesApparatusesMenueIndexRoute:
      AuthDefaultViewApparatusesApparatusesMenueIndexRoute,
  }

const AuthDefaultViewApparatusesApparatusesMenueRouteWithChildren =
  AuthDefaultViewApparatusesApparatusesMenueRoute._addFileChildren(
    AuthDefaultViewApparatusesApparatusesMenueRouteChildren,
  )

interface AuthDefaultViewApparatusesRouteChildren {
  AuthDefaultViewApparatusesApparatusesMenueRoute: typeof AuthDefaultViewApparatusesApparatusesMenueRouteWithChildren
  AuthDefaultViewApparatusesAddRoute: typeof AuthDefaultViewApparatusesAddRoute
  AuthDefaultViewApparatusesEditIdRoute: typeof AuthDefaultViewApparatusesEditIdRoute
  AuthDefaultViewApparatusesViewIdRoute: typeof AuthDefaultViewApparatusesViewIdRoute
}

const AuthDefaultViewApparatusesRouteChildren: AuthDefaultViewApparatusesRouteChildren =
  {
    AuthDefaultViewApparatusesApparatusesMenueRoute:
      AuthDefaultViewApparatusesApparatusesMenueRouteWithChildren,
    AuthDefaultViewApparatusesAddRoute: AuthDefaultViewApparatusesAddRoute,
    AuthDefaultViewApparatusesEditIdRoute:
      AuthDefaultViewApparatusesEditIdRoute,
    AuthDefaultViewApparatusesViewIdRoute:
      AuthDefaultViewApparatusesViewIdRoute,
  }

const AuthDefaultViewApparatusesRouteWithChildren =
  AuthDefaultViewApparatusesRoute._addFileChildren(
    AuthDefaultViewApparatusesRouteChildren,
  )

interface AuthDefaultViewInspectionsAddRouteChildren {
  AuthDefaultViewInspectionsAddApparatusIdRoute: typeof AuthDefaultViewInspectionsAddApparatusIdRoute
}

const AuthDefaultViewInspectionsAddRouteChildren: AuthDefaultViewInspectionsAddRouteChildren =
  {
    AuthDefaultViewInspectionsAddApparatusIdRoute:
      AuthDefaultViewInspectionsAddApparatusIdRoute,
  }

const AuthDefaultViewInspectionsAddRouteWithChildren =
  AuthDefaultViewInspectionsAddRoute._addFileChildren(
    AuthDefaultViewInspectionsAddRouteChildren,
  )

interface AuthDefaultViewRouteChildren {
  AuthDefaultViewDashboardRoute: typeof AuthDefaultViewDashboardRoute
  AuthDefaultViewInspectionRoute: typeof AuthDefaultViewInspectionRoute
  AuthDefaultViewIndexRoute: typeof AuthDefaultViewIndexRoute
  AuthDefaultViewApparatusesRoute: typeof AuthDefaultViewApparatusesRouteWithChildren
  AuthDefaultViewInspectionsAddRoute: typeof AuthDefaultViewInspectionsAddRouteWithChildren
  AuthDefaultViewSubscriptionDoneRoute: typeof AuthDefaultViewSubscriptionDoneRoute
  AuthDefaultViewUsersArchivedRoute: typeof AuthDefaultViewUsersArchivedRoute
  AuthDefaultViewCostcentersIndexRoute: typeof AuthDefaultViewCostcentersIndexRoute
  AuthDefaultViewInspectionsIndexRoute: typeof AuthDefaultViewInspectionsIndexRoute
  AuthDefaultViewUsersIndexRoute: typeof AuthDefaultViewUsersIndexRoute
  AuthDefaultViewCostcentersViewIdRoute: typeof AuthDefaultViewCostcentersViewIdRoute
  AuthDefaultViewInspectionsEditIdRoute: typeof AuthDefaultViewInspectionsEditIdRoute
  AuthDefaultViewInspectionsViewIdRoute: typeof AuthDefaultViewInspectionsViewIdRoute
  AuthDefaultViewTestplansAddApparatusIdRoute: typeof AuthDefaultViewTestplansAddApparatusIdRoute
  AuthDefaultViewTestplansEditIdRoute: typeof AuthDefaultViewTestplansEditIdRoute
  AuthDefaultViewTestplansViewIdRoute: typeof AuthDefaultViewTestplansViewIdRoute
  AuthDefaultViewUsersViewIdRoute: typeof AuthDefaultViewUsersViewIdRoute
}

const AuthDefaultViewRouteChildren: AuthDefaultViewRouteChildren = {
  AuthDefaultViewDashboardRoute: AuthDefaultViewDashboardRoute,
  AuthDefaultViewInspectionRoute: AuthDefaultViewInspectionRoute,
  AuthDefaultViewIndexRoute: AuthDefaultViewIndexRoute,
  AuthDefaultViewApparatusesRoute: AuthDefaultViewApparatusesRouteWithChildren,
  AuthDefaultViewInspectionsAddRoute:
    AuthDefaultViewInspectionsAddRouteWithChildren,
  AuthDefaultViewSubscriptionDoneRoute: AuthDefaultViewSubscriptionDoneRoute,
  AuthDefaultViewUsersArchivedRoute: AuthDefaultViewUsersArchivedRoute,
  AuthDefaultViewCostcentersIndexRoute: AuthDefaultViewCostcentersIndexRoute,
  AuthDefaultViewInspectionsIndexRoute: AuthDefaultViewInspectionsIndexRoute,
  AuthDefaultViewUsersIndexRoute: AuthDefaultViewUsersIndexRoute,
  AuthDefaultViewCostcentersViewIdRoute: AuthDefaultViewCostcentersViewIdRoute,
  AuthDefaultViewInspectionsEditIdRoute: AuthDefaultViewInspectionsEditIdRoute,
  AuthDefaultViewInspectionsViewIdRoute: AuthDefaultViewInspectionsViewIdRoute,
  AuthDefaultViewTestplansAddApparatusIdRoute:
    AuthDefaultViewTestplansAddApparatusIdRoute,
  AuthDefaultViewTestplansEditIdRoute: AuthDefaultViewTestplansEditIdRoute,
  AuthDefaultViewTestplansViewIdRoute: AuthDefaultViewTestplansViewIdRoute,
  AuthDefaultViewUsersViewIdRoute: AuthDefaultViewUsersViewIdRoute,
}

const AuthDefaultViewRouteWithChildren = AuthDefaultViewRoute._addFileChildren(
  AuthDefaultViewRouteChildren,
)

interface AuthFullViewRouteChildren {
  AuthFullViewInvalidSubscriptionCompanyRoute: typeof AuthFullViewInvalidSubscriptionCompanyRoute
  AuthFullViewInvalidSubscriptionDoneRoute: typeof AuthFullViewInvalidSubscriptionDoneRoute
  AuthFullViewInvalidSubscriptionPaymentRoute: typeof AuthFullViewInvalidSubscriptionPaymentRoute
  AuthFullViewInvalidSubscriptionIndexRoute: typeof AuthFullViewInvalidSubscriptionIndexRoute
}

const AuthFullViewRouteChildren: AuthFullViewRouteChildren = {
  AuthFullViewInvalidSubscriptionCompanyRoute:
    AuthFullViewInvalidSubscriptionCompanyRoute,
  AuthFullViewInvalidSubscriptionDoneRoute:
    AuthFullViewInvalidSubscriptionDoneRoute,
  AuthFullViewInvalidSubscriptionPaymentRoute:
    AuthFullViewInvalidSubscriptionPaymentRoute,
  AuthFullViewInvalidSubscriptionIndexRoute:
    AuthFullViewInvalidSubscriptionIndexRoute,
}

const AuthFullViewRouteWithChildren = AuthFullViewRoute._addFileChildren(
  AuthFullViewRouteChildren,
)

interface AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRouteChildren {
  AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentRoute
  AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexRoute
}

const AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRouteChildren: AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRouteChildren =
  {
    AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentRoute:
      AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentRoute,
    AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexRoute:
      AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexRoute,
  }

const AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRouteWithChildren =
  AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRoute._addFileChildren(
    AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRouteChildren,
  )

interface AuthSmallViewSettingsLayoutSettingsSubscriptionRouteChildren {
  AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRouteWithChildren
  AuthSmallViewSettingsLayoutSettingsSubscriptionDoneRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionDoneRoute
  AuthSmallViewSettingsLayoutSettingsSubscriptionInfoRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionInfoRoute
}

const AuthSmallViewSettingsLayoutSettingsSubscriptionRouteChildren: AuthSmallViewSettingsLayoutSettingsSubscriptionRouteChildren =
  {
    AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRoute:
      AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRouteWithChildren,
    AuthSmallViewSettingsLayoutSettingsSubscriptionDoneRoute:
      AuthSmallViewSettingsLayoutSettingsSubscriptionDoneRoute,
    AuthSmallViewSettingsLayoutSettingsSubscriptionInfoRoute:
      AuthSmallViewSettingsLayoutSettingsSubscriptionInfoRoute,
  }

const AuthSmallViewSettingsLayoutSettingsSubscriptionRouteWithChildren =
  AuthSmallViewSettingsLayoutSettingsSubscriptionRoute._addFileChildren(
    AuthSmallViewSettingsLayoutSettingsSubscriptionRouteChildren,
  )

interface AuthSmallViewSettingsLayoutRouteChildren {
  AuthSmallViewSettingsLayoutSettingsChangeRoute: typeof AuthSmallViewSettingsLayoutSettingsChangeRoute
  AuthSmallViewSettingsLayoutSettingsCompanyRoute: typeof AuthSmallViewSettingsLayoutSettingsCompanyRoute
  AuthSmallViewSettingsLayoutSettingsCustomfieldsRoute: typeof AuthSmallViewSettingsLayoutSettingsCustomfieldsRoute
  AuthSmallViewSettingsLayoutSettingsFilesRoute: typeof AuthSmallViewSettingsLayoutSettingsFilesRoute
  AuthSmallViewSettingsLayoutSettingsPasswordRoute: typeof AuthSmallViewSettingsLayoutSettingsPasswordRoute
  AuthSmallViewSettingsLayoutSettingsIndexRoute: typeof AuthSmallViewSettingsLayoutSettingsIndexRoute
  AuthSmallViewSettingsLayoutSettingsMandantorChangeRoute: typeof AuthSmallViewSettingsLayoutSettingsMandantorChangeRoute
  AuthSmallViewSettingsLayoutSettingsSubscriptionRoute: typeof AuthSmallViewSettingsLayoutSettingsSubscriptionRouteWithChildren
  AuthSmallViewSettingsLayoutSettingsMandantorIndexRoute: typeof AuthSmallViewSettingsLayoutSettingsMandantorIndexRoute
}

const AuthSmallViewSettingsLayoutRouteChildren: AuthSmallViewSettingsLayoutRouteChildren =
  {
    AuthSmallViewSettingsLayoutSettingsChangeRoute:
      AuthSmallViewSettingsLayoutSettingsChangeRoute,
    AuthSmallViewSettingsLayoutSettingsCompanyRoute:
      AuthSmallViewSettingsLayoutSettingsCompanyRoute,
    AuthSmallViewSettingsLayoutSettingsCustomfieldsRoute:
      AuthSmallViewSettingsLayoutSettingsCustomfieldsRoute,
    AuthSmallViewSettingsLayoutSettingsFilesRoute:
      AuthSmallViewSettingsLayoutSettingsFilesRoute,
    AuthSmallViewSettingsLayoutSettingsPasswordRoute:
      AuthSmallViewSettingsLayoutSettingsPasswordRoute,
    AuthSmallViewSettingsLayoutSettingsIndexRoute:
      AuthSmallViewSettingsLayoutSettingsIndexRoute,
    AuthSmallViewSettingsLayoutSettingsMandantorChangeRoute:
      AuthSmallViewSettingsLayoutSettingsMandantorChangeRoute,
    AuthSmallViewSettingsLayoutSettingsSubscriptionRoute:
      AuthSmallViewSettingsLayoutSettingsSubscriptionRouteWithChildren,
    AuthSmallViewSettingsLayoutSettingsMandantorIndexRoute:
      AuthSmallViewSettingsLayoutSettingsMandantorIndexRoute,
  }

const AuthSmallViewSettingsLayoutRouteWithChildren =
  AuthSmallViewSettingsLayoutRoute._addFileChildren(
    AuthSmallViewSettingsLayoutRouteChildren,
  )

interface AuthSmallViewRouteChildren {
  AuthSmallViewSettingsLayoutRoute: typeof AuthSmallViewSettingsLayoutRouteWithChildren
  AuthSmallViewApiRoute: typeof AuthSmallViewApiRoute
  AuthSmallViewFaqRoute: typeof AuthSmallViewFaqRoute
  AuthSmallViewUnconfirmedRoute: typeof AuthSmallViewUnconfirmedRoute
  AuthSmallViewTicketsAddRoute: typeof AuthSmallViewTicketsAddRoute
  AuthSmallViewTicketsIndexRoute: typeof AuthSmallViewTicketsIndexRoute
  AuthSmallViewTicketsViewTicketIdRoute: typeof AuthSmallViewTicketsViewTicketIdRoute
}

const AuthSmallViewRouteChildren: AuthSmallViewRouteChildren = {
  AuthSmallViewSettingsLayoutRoute:
    AuthSmallViewSettingsLayoutRouteWithChildren,
  AuthSmallViewApiRoute: AuthSmallViewApiRoute,
  AuthSmallViewFaqRoute: AuthSmallViewFaqRoute,
  AuthSmallViewUnconfirmedRoute: AuthSmallViewUnconfirmedRoute,
  AuthSmallViewTicketsAddRoute: AuthSmallViewTicketsAddRoute,
  AuthSmallViewTicketsIndexRoute: AuthSmallViewTicketsIndexRoute,
  AuthSmallViewTicketsViewTicketIdRoute: AuthSmallViewTicketsViewTicketIdRoute,
}

const AuthSmallViewRouteWithChildren = AuthSmallViewRoute._addFileChildren(
  AuthSmallViewRouteChildren,
)

interface AuthRouteChildren {
  AuthDefaultViewRoute: typeof AuthDefaultViewRouteWithChildren
  AuthFullViewRoute: typeof AuthFullViewRouteWithChildren
  AuthSmallViewRoute: typeof AuthSmallViewRouteWithChildren
  AuthLogoutRoute: typeof AuthLogoutRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthDefaultViewRoute: AuthDefaultViewRouteWithChildren,
  AuthFullViewRoute: AuthFullViewRouteWithChildren,
  AuthSmallViewRoute: AuthSmallViewRouteWithChildren,
  AuthLogoutRoute: AuthLogoutRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface DefaultRouteChildren {
  DefaultAcceptInviteTokenRoute: typeof DefaultAcceptInviteTokenRoute
  DefaultConfirmEmailChangeTokenRoute: typeof DefaultConfirmEmailChangeTokenRoute
  DefaultResetPasswordTokenRoute: typeof DefaultResetPasswordTokenRoute
  DefaultValidateMailTokenRoute: typeof DefaultValidateMailTokenRoute
}

const DefaultRouteChildren: DefaultRouteChildren = {
  DefaultAcceptInviteTokenRoute: DefaultAcceptInviteTokenRoute,
  DefaultConfirmEmailChangeTokenRoute: DefaultConfirmEmailChangeTokenRoute,
  DefaultResetPasswordTokenRoute: DefaultResetPasswordTokenRoute,
  DefaultValidateMailTokenRoute: DefaultValidateMailTokenRoute,
}

const DefaultRouteWithChildren =
  DefaultRoute._addFileChildren(DefaultRouteChildren)

interface UnauthRouteChildren {
  UnauthAgbRoute: typeof UnauthAgbRoute
  UnauthForgotPasswordRoute: typeof UnauthForgotPasswordRoute
  UnauthImprintRoute: typeof UnauthImprintRoute
  UnauthLoginRoute: typeof UnauthLoginRoute
  UnauthPrivacyRoute: typeof UnauthPrivacyRoute
  UnauthSignupRoute: typeof UnauthSignupRoute
  UnauthSignupDoneRoute: typeof UnauthSignupDoneRoute
  UnauthTicketTokenRoute: typeof UnauthTicketTokenRoute
}

const UnauthRouteChildren: UnauthRouteChildren = {
  UnauthAgbRoute: UnauthAgbRoute,
  UnauthForgotPasswordRoute: UnauthForgotPasswordRoute,
  UnauthImprintRoute: UnauthImprintRoute,
  UnauthLoginRoute: UnauthLoginRoute,
  UnauthPrivacyRoute: UnauthPrivacyRoute,
  UnauthSignupRoute: UnauthSignupRoute,
  UnauthSignupDoneRoute: UnauthSignupDoneRoute,
  UnauthTicketTokenRoute: UnauthTicketTokenRoute,
}

const UnauthRouteWithChildren =
  UnauthRoute._addFileChildren(UnauthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthSmallViewSettingsLayoutRouteWithChildren
  '/logout': typeof AuthLogoutRoute
  '/agb': typeof UnauthAgbRoute
  '/forgot-password': typeof UnauthForgotPasswordRoute
  '/imprint': typeof UnauthImprintRoute
  '/login': typeof UnauthLoginRoute
  '/privacy': typeof UnauthPrivacyRoute
  '/signup': typeof UnauthSignupRoute
  '/signup-done': typeof UnauthSignupDoneRoute
  '/de/$': typeof langDeSplatRoute
  '/en/$': typeof langEnSplatRoute
  '/dashboard': typeof AuthDefaultViewDashboardRoute
  '/inspection': typeof AuthDefaultViewInspectionRoute
  '/api': typeof AuthSmallViewApiRoute
  '/faq': typeof AuthSmallViewFaqRoute
  '/unconfirmed': typeof AuthSmallViewUnconfirmedRoute
  '/accept-invite/$token': typeof DefaultAcceptInviteTokenRoute
  '/confirm-email-change/$token': typeof DefaultConfirmEmailChangeTokenRoute
  '/reset-password/$token': typeof DefaultResetPasswordTokenRoute
  '/validate-mail/$token': typeof DefaultValidateMailTokenRoute
  '/ticket/$token': typeof UnauthTicketTokenRoute
  '/': typeof AuthDefaultViewIndexRoute
  '/apparatuses': typeof AuthDefaultViewApparatusesApparatusesMenueRouteWithChildren
  '/apparatuses/add': typeof AuthDefaultViewApparatusesAddRoute
  '/inspections/add': typeof AuthDefaultViewInspectionsAddRouteWithChildren
  '/subscription/done': typeof AuthDefaultViewSubscriptionDoneRoute
  '/users/archived': typeof AuthDefaultViewUsersArchivedRoute
  '/invalidSubscription/company': typeof AuthFullViewInvalidSubscriptionCompanyRoute
  '/invalidSubscription/done': typeof AuthFullViewInvalidSubscriptionDoneRoute
  '/invalidSubscription/payment': typeof AuthFullViewInvalidSubscriptionPaymentRoute
  '/tickets/add': typeof AuthSmallViewTicketsAddRoute
  '/costcenters': typeof AuthDefaultViewCostcentersIndexRoute
  '/inspections': typeof AuthDefaultViewInspectionsIndexRoute
  '/users': typeof AuthDefaultViewUsersIndexRoute
  '/invalidSubscription': typeof AuthFullViewInvalidSubscriptionIndexRoute
  '/tickets': typeof AuthSmallViewTicketsIndexRoute
  '/apparatuses/export': typeof AuthDefaultViewApparatusesApparatusesMenueExportRoute
  '/apparatuses/edit/$id': typeof AuthDefaultViewApparatusesEditIdRoute
  '/apparatuses/view/$id': typeof AuthDefaultViewApparatusesViewIdRoute
  '/costcenters/view/$id': typeof AuthDefaultViewCostcentersViewIdRoute
  '/inspections/add/$apparatusId': typeof AuthDefaultViewInspectionsAddApparatusIdRoute
  '/inspections/edit/$id': typeof AuthDefaultViewInspectionsEditIdRoute
  '/inspections/view/$id': typeof AuthDefaultViewInspectionsViewIdRoute
  '/testplans/add/$apparatusId': typeof AuthDefaultViewTestplansAddApparatusIdRoute
  '/testplans/edit/$id': typeof AuthDefaultViewTestplansEditIdRoute
  '/testplans/view/$id': typeof AuthDefaultViewTestplansViewIdRoute
  '/users/view/$id': typeof AuthDefaultViewUsersViewIdRoute
  '/settings/change': typeof AuthSmallViewSettingsLayoutSettingsChangeRoute
  '/settings/company': typeof AuthSmallViewSettingsLayoutSettingsCompanyRoute
  '/settings/customfields': typeof AuthSmallViewSettingsLayoutSettingsCustomfieldsRoute
  '/settings/files': typeof AuthSmallViewSettingsLayoutSettingsFilesRoute
  '/settings/password': typeof AuthSmallViewSettingsLayoutSettingsPasswordRoute
  '/tickets/view/$ticketId': typeof AuthSmallViewTicketsViewTicketIdRoute
  '/apparatuses/': typeof AuthDefaultViewApparatusesApparatusesMenueIndexRoute
  '/settings': typeof AuthSmallViewSettingsLayoutSettingsIndexRoute
  '/settings/mandantor/change': typeof AuthSmallViewSettingsLayoutSettingsMandantorChangeRoute
  '/settings/subscription': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRouteWithChildren
  '/settings/subscription/done': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionDoneRoute
  '/settings/subscription/info': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionInfoRoute
  '/settings/mandantor': typeof AuthSmallViewSettingsLayoutSettingsMandantorIndexRoute
  '/settings/subscription/payment': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentRoute
  '/settings/subscription/': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthSmallViewSettingsLayoutRouteWithChildren
  '/logout': typeof AuthLogoutRoute
  '/agb': typeof UnauthAgbRoute
  '/forgot-password': typeof UnauthForgotPasswordRoute
  '/imprint': typeof UnauthImprintRoute
  '/login': typeof UnauthLoginRoute
  '/privacy': typeof UnauthPrivacyRoute
  '/signup': typeof UnauthSignupRoute
  '/signup-done': typeof UnauthSignupDoneRoute
  '/de/$': typeof langDeSplatRoute
  '/en/$': typeof langEnSplatRoute
  '/dashboard': typeof AuthDefaultViewDashboardRoute
  '/inspection': typeof AuthDefaultViewInspectionRoute
  '/api': typeof AuthSmallViewApiRoute
  '/faq': typeof AuthSmallViewFaqRoute
  '/unconfirmed': typeof AuthSmallViewUnconfirmedRoute
  '/accept-invite/$token': typeof DefaultAcceptInviteTokenRoute
  '/confirm-email-change/$token': typeof DefaultConfirmEmailChangeTokenRoute
  '/reset-password/$token': typeof DefaultResetPasswordTokenRoute
  '/validate-mail/$token': typeof DefaultValidateMailTokenRoute
  '/ticket/$token': typeof UnauthTicketTokenRoute
  '/': typeof AuthDefaultViewIndexRoute
  '/apparatuses': typeof AuthDefaultViewApparatusesApparatusesMenueIndexRoute
  '/apparatuses/add': typeof AuthDefaultViewApparatusesAddRoute
  '/inspections/add': typeof AuthDefaultViewInspectionsAddRouteWithChildren
  '/subscription/done': typeof AuthDefaultViewSubscriptionDoneRoute
  '/users/archived': typeof AuthDefaultViewUsersArchivedRoute
  '/invalidSubscription/company': typeof AuthFullViewInvalidSubscriptionCompanyRoute
  '/invalidSubscription/done': typeof AuthFullViewInvalidSubscriptionDoneRoute
  '/invalidSubscription/payment': typeof AuthFullViewInvalidSubscriptionPaymentRoute
  '/tickets/add': typeof AuthSmallViewTicketsAddRoute
  '/costcenters': typeof AuthDefaultViewCostcentersIndexRoute
  '/inspections': typeof AuthDefaultViewInspectionsIndexRoute
  '/users': typeof AuthDefaultViewUsersIndexRoute
  '/invalidSubscription': typeof AuthFullViewInvalidSubscriptionIndexRoute
  '/tickets': typeof AuthSmallViewTicketsIndexRoute
  '/apparatuses/export': typeof AuthDefaultViewApparatusesApparatusesMenueExportRoute
  '/apparatuses/edit/$id': typeof AuthDefaultViewApparatusesEditIdRoute
  '/apparatuses/view/$id': typeof AuthDefaultViewApparatusesViewIdRoute
  '/costcenters/view/$id': typeof AuthDefaultViewCostcentersViewIdRoute
  '/inspections/add/$apparatusId': typeof AuthDefaultViewInspectionsAddApparatusIdRoute
  '/inspections/edit/$id': typeof AuthDefaultViewInspectionsEditIdRoute
  '/inspections/view/$id': typeof AuthDefaultViewInspectionsViewIdRoute
  '/testplans/add/$apparatusId': typeof AuthDefaultViewTestplansAddApparatusIdRoute
  '/testplans/edit/$id': typeof AuthDefaultViewTestplansEditIdRoute
  '/testplans/view/$id': typeof AuthDefaultViewTestplansViewIdRoute
  '/users/view/$id': typeof AuthDefaultViewUsersViewIdRoute
  '/settings/change': typeof AuthSmallViewSettingsLayoutSettingsChangeRoute
  '/settings/company': typeof AuthSmallViewSettingsLayoutSettingsCompanyRoute
  '/settings/customfields': typeof AuthSmallViewSettingsLayoutSettingsCustomfieldsRoute
  '/settings/files': typeof AuthSmallViewSettingsLayoutSettingsFilesRoute
  '/settings/password': typeof AuthSmallViewSettingsLayoutSettingsPasswordRoute
  '/tickets/view/$ticketId': typeof AuthSmallViewTicketsViewTicketIdRoute
  '/settings': typeof AuthSmallViewSettingsLayoutSettingsIndexRoute
  '/settings/mandantor/change': typeof AuthSmallViewSettingsLayoutSettingsMandantorChangeRoute
  '/settings/subscription': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexRoute
  '/settings/subscription/done': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionDoneRoute
  '/settings/subscription/info': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionInfoRoute
  '/settings/mandantor': typeof AuthSmallViewSettingsLayoutSettingsMandantorIndexRoute
  '/settings/subscription/payment': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_default': typeof DefaultRouteWithChildren
  '/_unauth': typeof UnauthRouteWithChildren
  '/_auth/_defaultView': typeof AuthDefaultViewRouteWithChildren
  '/_auth/_fullView': typeof AuthFullViewRouteWithChildren
  '/_auth/_smallView': typeof AuthSmallViewRouteWithChildren
  '/_auth/logout': typeof AuthLogoutRoute
  '/_unauth/agb': typeof UnauthAgbRoute
  '/_unauth/forgot-password': typeof UnauthForgotPasswordRoute
  '/_unauth/imprint': typeof UnauthImprintRoute
  '/_unauth/login': typeof UnauthLoginRoute
  '/_unauth/privacy': typeof UnauthPrivacyRoute
  '/_unauth/signup': typeof UnauthSignupRoute
  '/_unauth/signup-done': typeof UnauthSignupDoneRoute
  '/(lang)/de/$': typeof langDeSplatRoute
  '/(lang)/en/$': typeof langEnSplatRoute
  '/_auth/_defaultView/dashboard': typeof AuthDefaultViewDashboardRoute
  '/_auth/_defaultView/inspection': typeof AuthDefaultViewInspectionRoute
  '/_auth/_smallView/_settingsLayout': typeof AuthSmallViewSettingsLayoutRouteWithChildren
  '/_auth/_smallView/api': typeof AuthSmallViewApiRoute
  '/_auth/_smallView/faq': typeof AuthSmallViewFaqRoute
  '/_auth/_smallView/unconfirmed': typeof AuthSmallViewUnconfirmedRoute
  '/_default/accept-invite/$token': typeof DefaultAcceptInviteTokenRoute
  '/_default/confirm-email-change/$token': typeof DefaultConfirmEmailChangeTokenRoute
  '/_default/reset-password/$token': typeof DefaultResetPasswordTokenRoute
  '/_default/validate-mail/$token': typeof DefaultValidateMailTokenRoute
  '/_unauth/ticket/$token': typeof UnauthTicketTokenRoute
  '/_auth/_defaultView/': typeof AuthDefaultViewIndexRoute
  '/_auth/_defaultView/apparatuses': typeof AuthDefaultViewApparatusesRouteWithChildren
  '/_auth/_defaultView/apparatuses/_apparatusesMenue': typeof AuthDefaultViewApparatusesApparatusesMenueRouteWithChildren
  '/_auth/_defaultView/apparatuses/add': typeof AuthDefaultViewApparatusesAddRoute
  '/_auth/_defaultView/inspections/add': typeof AuthDefaultViewInspectionsAddRouteWithChildren
  '/_auth/_defaultView/subscription/done': typeof AuthDefaultViewSubscriptionDoneRoute
  '/_auth/_defaultView/users/archived': typeof AuthDefaultViewUsersArchivedRoute
  '/_auth/_fullView/invalidSubscription/company': typeof AuthFullViewInvalidSubscriptionCompanyRoute
  '/_auth/_fullView/invalidSubscription/done': typeof AuthFullViewInvalidSubscriptionDoneRoute
  '/_auth/_fullView/invalidSubscription/payment': typeof AuthFullViewInvalidSubscriptionPaymentRoute
  '/_auth/_smallView/tickets/add': typeof AuthSmallViewTicketsAddRoute
  '/_auth/_defaultView/costcenters/': typeof AuthDefaultViewCostcentersIndexRoute
  '/_auth/_defaultView/inspections/': typeof AuthDefaultViewInspectionsIndexRoute
  '/_auth/_defaultView/users/': typeof AuthDefaultViewUsersIndexRoute
  '/_auth/_fullView/invalidSubscription/': typeof AuthFullViewInvalidSubscriptionIndexRoute
  '/_auth/_smallView/tickets/': typeof AuthSmallViewTicketsIndexRoute
  '/_auth/_defaultView/apparatuses/_apparatusesMenue/export': typeof AuthDefaultViewApparatusesApparatusesMenueExportRoute
  '/_auth/_defaultView/apparatuses/edit/$id': typeof AuthDefaultViewApparatusesEditIdRoute
  '/_auth/_defaultView/apparatuses/view/$id': typeof AuthDefaultViewApparatusesViewIdRoute
  '/_auth/_defaultView/costcenters/view/$id': typeof AuthDefaultViewCostcentersViewIdRoute
  '/_auth/_defaultView/inspections/add/$apparatusId': typeof AuthDefaultViewInspectionsAddApparatusIdRoute
  '/_auth/_defaultView/inspections/edit/$id': typeof AuthDefaultViewInspectionsEditIdRoute
  '/_auth/_defaultView/inspections/view/$id': typeof AuthDefaultViewInspectionsViewIdRoute
  '/_auth/_defaultView/testplans/add/$apparatusId': typeof AuthDefaultViewTestplansAddApparatusIdRoute
  '/_auth/_defaultView/testplans/edit/$id': typeof AuthDefaultViewTestplansEditIdRoute
  '/_auth/_defaultView/testplans/view/$id': typeof AuthDefaultViewTestplansViewIdRoute
  '/_auth/_defaultView/users/view/$id': typeof AuthDefaultViewUsersViewIdRoute
  '/_auth/_smallView/_settingsLayout/settings/change': typeof AuthSmallViewSettingsLayoutSettingsChangeRoute
  '/_auth/_smallView/_settingsLayout/settings/company': typeof AuthSmallViewSettingsLayoutSettingsCompanyRoute
  '/_auth/_smallView/_settingsLayout/settings/customfields': typeof AuthSmallViewSettingsLayoutSettingsCustomfieldsRoute
  '/_auth/_smallView/_settingsLayout/settings/files': typeof AuthSmallViewSettingsLayoutSettingsFilesRoute
  '/_auth/_smallView/_settingsLayout/settings/password': typeof AuthSmallViewSettingsLayoutSettingsPasswordRoute
  '/_auth/_smallView/tickets/view/$ticketId': typeof AuthSmallViewTicketsViewTicketIdRoute
  '/_auth/_defaultView/apparatuses/_apparatusesMenue/': typeof AuthDefaultViewApparatusesApparatusesMenueIndexRoute
  '/_auth/_smallView/_settingsLayout/settings/': typeof AuthSmallViewSettingsLayoutSettingsIndexRoute
  '/_auth/_smallView/_settingsLayout/settings/mandantor/change': typeof AuthSmallViewSettingsLayoutSettingsMandantorChangeRoute
  '/_auth/_smallView/_settingsLayout/settings/subscription': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionRouteWithChildren
  '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutRouteWithChildren
  '/_auth/_smallView/_settingsLayout/settings/subscription/done': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionDoneRoute
  '/_auth/_smallView/_settingsLayout/settings/subscription/info': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionInfoRoute
  '/_auth/_smallView/_settingsLayout/settings/mandantor/': typeof AuthSmallViewSettingsLayoutSettingsMandantorIndexRoute
  '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/payment': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutPaymentRoute
  '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/': typeof AuthSmallViewSettingsLayoutSettingsSubscriptionSubscriptionLayoutIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/logout'
    | '/agb'
    | '/forgot-password'
    | '/imprint'
    | '/login'
    | '/privacy'
    | '/signup'
    | '/signup-done'
    | '/de/$'
    | '/en/$'
    | '/dashboard'
    | '/inspection'
    | '/api'
    | '/faq'
    | '/unconfirmed'
    | '/accept-invite/$token'
    | '/confirm-email-change/$token'
    | '/reset-password/$token'
    | '/validate-mail/$token'
    | '/ticket/$token'
    | '/'
    | '/apparatuses'
    | '/apparatuses/add'
    | '/inspections/add'
    | '/subscription/done'
    | '/users/archived'
    | '/invalidSubscription/company'
    | '/invalidSubscription/done'
    | '/invalidSubscription/payment'
    | '/tickets/add'
    | '/costcenters'
    | '/inspections'
    | '/users'
    | '/invalidSubscription'
    | '/tickets'
    | '/apparatuses/export'
    | '/apparatuses/edit/$id'
    | '/apparatuses/view/$id'
    | '/costcenters/view/$id'
    | '/inspections/add/$apparatusId'
    | '/inspections/edit/$id'
    | '/inspections/view/$id'
    | '/testplans/add/$apparatusId'
    | '/testplans/edit/$id'
    | '/testplans/view/$id'
    | '/users/view/$id'
    | '/settings/change'
    | '/settings/company'
    | '/settings/customfields'
    | '/settings/files'
    | '/settings/password'
    | '/tickets/view/$ticketId'
    | '/apparatuses/'
    | '/settings'
    | '/settings/mandantor/change'
    | '/settings/subscription'
    | '/settings/subscription/done'
    | '/settings/subscription/info'
    | '/settings/mandantor'
    | '/settings/subscription/payment'
    | '/settings/subscription/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/logout'
    | '/agb'
    | '/forgot-password'
    | '/imprint'
    | '/login'
    | '/privacy'
    | '/signup'
    | '/signup-done'
    | '/de/$'
    | '/en/$'
    | '/dashboard'
    | '/inspection'
    | '/api'
    | '/faq'
    | '/unconfirmed'
    | '/accept-invite/$token'
    | '/confirm-email-change/$token'
    | '/reset-password/$token'
    | '/validate-mail/$token'
    | '/ticket/$token'
    | '/'
    | '/apparatuses'
    | '/apparatuses/add'
    | '/inspections/add'
    | '/subscription/done'
    | '/users/archived'
    | '/invalidSubscription/company'
    | '/invalidSubscription/done'
    | '/invalidSubscription/payment'
    | '/tickets/add'
    | '/costcenters'
    | '/inspections'
    | '/users'
    | '/invalidSubscription'
    | '/tickets'
    | '/apparatuses/export'
    | '/apparatuses/edit/$id'
    | '/apparatuses/view/$id'
    | '/costcenters/view/$id'
    | '/inspections/add/$apparatusId'
    | '/inspections/edit/$id'
    | '/inspections/view/$id'
    | '/testplans/add/$apparatusId'
    | '/testplans/edit/$id'
    | '/testplans/view/$id'
    | '/users/view/$id'
    | '/settings/change'
    | '/settings/company'
    | '/settings/customfields'
    | '/settings/files'
    | '/settings/password'
    | '/tickets/view/$ticketId'
    | '/settings'
    | '/settings/mandantor/change'
    | '/settings/subscription'
    | '/settings/subscription/done'
    | '/settings/subscription/info'
    | '/settings/mandantor'
    | '/settings/subscription/payment'
  id:
    | '__root__'
    | '/_auth'
    | '/_default'
    | '/_unauth'
    | '/_auth/_defaultView'
    | '/_auth/_fullView'
    | '/_auth/_smallView'
    | '/_auth/logout'
    | '/_unauth/agb'
    | '/_unauth/forgot-password'
    | '/_unauth/imprint'
    | '/_unauth/login'
    | '/_unauth/privacy'
    | '/_unauth/signup'
    | '/_unauth/signup-done'
    | '/(lang)/de/$'
    | '/(lang)/en/$'
    | '/_auth/_defaultView/dashboard'
    | '/_auth/_defaultView/inspection'
    | '/_auth/_smallView/_settingsLayout'
    | '/_auth/_smallView/api'
    | '/_auth/_smallView/faq'
    | '/_auth/_smallView/unconfirmed'
    | '/_default/accept-invite/$token'
    | '/_default/confirm-email-change/$token'
    | '/_default/reset-password/$token'
    | '/_default/validate-mail/$token'
    | '/_unauth/ticket/$token'
    | '/_auth/_defaultView/'
    | '/_auth/_defaultView/apparatuses'
    | '/_auth/_defaultView/apparatuses/_apparatusesMenue'
    | '/_auth/_defaultView/apparatuses/add'
    | '/_auth/_defaultView/inspections/add'
    | '/_auth/_defaultView/subscription/done'
    | '/_auth/_defaultView/users/archived'
    | '/_auth/_fullView/invalidSubscription/company'
    | '/_auth/_fullView/invalidSubscription/done'
    | '/_auth/_fullView/invalidSubscription/payment'
    | '/_auth/_smallView/tickets/add'
    | '/_auth/_defaultView/costcenters/'
    | '/_auth/_defaultView/inspections/'
    | '/_auth/_defaultView/users/'
    | '/_auth/_fullView/invalidSubscription/'
    | '/_auth/_smallView/tickets/'
    | '/_auth/_defaultView/apparatuses/_apparatusesMenue/export'
    | '/_auth/_defaultView/apparatuses/edit/$id'
    | '/_auth/_defaultView/apparatuses/view/$id'
    | '/_auth/_defaultView/costcenters/view/$id'
    | '/_auth/_defaultView/inspections/add/$apparatusId'
    | '/_auth/_defaultView/inspections/edit/$id'
    | '/_auth/_defaultView/inspections/view/$id'
    | '/_auth/_defaultView/testplans/add/$apparatusId'
    | '/_auth/_defaultView/testplans/edit/$id'
    | '/_auth/_defaultView/testplans/view/$id'
    | '/_auth/_defaultView/users/view/$id'
    | '/_auth/_smallView/_settingsLayout/settings/change'
    | '/_auth/_smallView/_settingsLayout/settings/company'
    | '/_auth/_smallView/_settingsLayout/settings/customfields'
    | '/_auth/_smallView/_settingsLayout/settings/files'
    | '/_auth/_smallView/_settingsLayout/settings/password'
    | '/_auth/_smallView/tickets/view/$ticketId'
    | '/_auth/_defaultView/apparatuses/_apparatusesMenue/'
    | '/_auth/_smallView/_settingsLayout/settings/'
    | '/_auth/_smallView/_settingsLayout/settings/mandantor/change'
    | '/_auth/_smallView/_settingsLayout/settings/subscription'
    | '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout'
    | '/_auth/_smallView/_settingsLayout/settings/subscription/done'
    | '/_auth/_smallView/_settingsLayout/settings/subscription/info'
    | '/_auth/_smallView/_settingsLayout/settings/mandantor/'
    | '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/payment'
    | '/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  DefaultRoute: typeof DefaultRouteWithChildren
  UnauthRoute: typeof UnauthRouteWithChildren
  langDeSplatRoute: typeof langDeSplatRoute
  langEnSplatRoute: typeof langEnSplatRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  DefaultRoute: DefaultRouteWithChildren,
  UnauthRoute: UnauthRouteWithChildren,
  langDeSplatRoute: langDeSplatRoute,
  langEnSplatRoute: langEnSplatRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_default",
        "/_unauth",
        "/(lang)/de/$",
        "/(lang)/en/$"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/_defaultView",
        "/_auth/_fullView",
        "/_auth/_smallView",
        "/_auth/logout"
      ]
    },
    "/_default": {
      "filePath": "_default.tsx",
      "children": [
        "/_default/accept-invite/$token",
        "/_default/confirm-email-change/$token",
        "/_default/reset-password/$token",
        "/_default/validate-mail/$token"
      ]
    },
    "/_unauth": {
      "filePath": "_unauth.tsx",
      "children": [
        "/_unauth/agb",
        "/_unauth/forgot-password",
        "/_unauth/imprint",
        "/_unauth/login",
        "/_unauth/privacy",
        "/_unauth/signup",
        "/_unauth/signup-done",
        "/_unauth/ticket/$token"
      ]
    },
    "/_auth/_defaultView": {
      "filePath": "_auth/_defaultView.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_defaultView/dashboard",
        "/_auth/_defaultView/inspection",
        "/_auth/_defaultView/",
        "/_auth/_defaultView/apparatuses",
        "/_auth/_defaultView/inspections/add",
        "/_auth/_defaultView/subscription/done",
        "/_auth/_defaultView/users/archived",
        "/_auth/_defaultView/costcenters/",
        "/_auth/_defaultView/inspections/",
        "/_auth/_defaultView/users/",
        "/_auth/_defaultView/costcenters/view/$id",
        "/_auth/_defaultView/inspections/edit/$id",
        "/_auth/_defaultView/inspections/view/$id",
        "/_auth/_defaultView/testplans/add/$apparatusId",
        "/_auth/_defaultView/testplans/edit/$id",
        "/_auth/_defaultView/testplans/view/$id",
        "/_auth/_defaultView/users/view/$id"
      ]
    },
    "/_auth/_fullView": {
      "filePath": "_auth/_fullView.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_fullView/invalidSubscription/company",
        "/_auth/_fullView/invalidSubscription/done",
        "/_auth/_fullView/invalidSubscription/payment",
        "/_auth/_fullView/invalidSubscription/"
      ]
    },
    "/_auth/_smallView": {
      "filePath": "_auth/_smallView.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_smallView/_settingsLayout",
        "/_auth/_smallView/api",
        "/_auth/_smallView/faq",
        "/_auth/_smallView/unconfirmed",
        "/_auth/_smallView/tickets/add",
        "/_auth/_smallView/tickets/",
        "/_auth/_smallView/tickets/view/$ticketId"
      ]
    },
    "/_auth/logout": {
      "filePath": "_auth/logout.tsx",
      "parent": "/_auth"
    },
    "/_unauth/agb": {
      "filePath": "_unauth/agb.tsx",
      "parent": "/_unauth"
    },
    "/_unauth/forgot-password": {
      "filePath": "_unauth/forgot-password.tsx",
      "parent": "/_unauth"
    },
    "/_unauth/imprint": {
      "filePath": "_unauth/imprint.tsx",
      "parent": "/_unauth"
    },
    "/_unauth/login": {
      "filePath": "_unauth/login.tsx",
      "parent": "/_unauth"
    },
    "/_unauth/privacy": {
      "filePath": "_unauth/privacy.tsx",
      "parent": "/_unauth"
    },
    "/_unauth/signup": {
      "filePath": "_unauth/signup.tsx",
      "parent": "/_unauth"
    },
    "/_unauth/signup-done": {
      "filePath": "_unauth/signup-done.tsx",
      "parent": "/_unauth"
    },
    "/(lang)/de/$": {
      "filePath": "(lang)/de.$.tsx"
    },
    "/(lang)/en/$": {
      "filePath": "(lang)/en.$.tsx"
    },
    "/_auth/_defaultView/dashboard": {
      "filePath": "_auth/_defaultView/dashboard.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/inspection": {
      "filePath": "_auth/_defaultView/inspection.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_smallView/_settingsLayout": {
      "filePath": "_auth/_smallView/_settingsLayout.tsx",
      "parent": "/_auth/_smallView",
      "children": [
        "/_auth/_smallView/_settingsLayout/settings/change",
        "/_auth/_smallView/_settingsLayout/settings/company",
        "/_auth/_smallView/_settingsLayout/settings/customfields",
        "/_auth/_smallView/_settingsLayout/settings/files",
        "/_auth/_smallView/_settingsLayout/settings/password",
        "/_auth/_smallView/_settingsLayout/settings/",
        "/_auth/_smallView/_settingsLayout/settings/mandantor/change",
        "/_auth/_smallView/_settingsLayout/settings/subscription",
        "/_auth/_smallView/_settingsLayout/settings/mandantor/"
      ]
    },
    "/_auth/_smallView/api": {
      "filePath": "_auth/_smallView/api.tsx",
      "parent": "/_auth/_smallView"
    },
    "/_auth/_smallView/faq": {
      "filePath": "_auth/_smallView/faq.tsx",
      "parent": "/_auth/_smallView"
    },
    "/_auth/_smallView/unconfirmed": {
      "filePath": "_auth/_smallView/unconfirmed.tsx",
      "parent": "/_auth/_smallView"
    },
    "/_default/accept-invite/$token": {
      "filePath": "_default/accept-invite.$token.tsx",
      "parent": "/_default"
    },
    "/_default/confirm-email-change/$token": {
      "filePath": "_default/confirm-email-change.$token.tsx",
      "parent": "/_default"
    },
    "/_default/reset-password/$token": {
      "filePath": "_default/reset-password.$token.tsx",
      "parent": "/_default"
    },
    "/_default/validate-mail/$token": {
      "filePath": "_default/validate-mail.$token.tsx",
      "parent": "/_default"
    },
    "/_unauth/ticket/$token": {
      "filePath": "_unauth/ticket.$token.tsx",
      "parent": "/_unauth"
    },
    "/_auth/_defaultView/": {
      "filePath": "_auth/_defaultView/index.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/apparatuses": {
      "filePath": "_auth/_defaultView/apparatuses",
      "parent": "/_auth/_defaultView",
      "children": [
        "/_auth/_defaultView/apparatuses/_apparatusesMenue",
        "/_auth/_defaultView/apparatuses/add",
        "/_auth/_defaultView/apparatuses/edit/$id",
        "/_auth/_defaultView/apparatuses/view/$id"
      ]
    },
    "/_auth/_defaultView/apparatuses/_apparatusesMenue": {
      "filePath": "_auth/_defaultView/apparatuses/_apparatusesMenue.tsx",
      "parent": "/_auth/_defaultView/apparatuses",
      "children": [
        "/_auth/_defaultView/apparatuses/_apparatusesMenue/export",
        "/_auth/_defaultView/apparatuses/_apparatusesMenue/"
      ]
    },
    "/_auth/_defaultView/apparatuses/add": {
      "filePath": "_auth/_defaultView/apparatuses/add.tsx",
      "parent": "/_auth/_defaultView/apparatuses"
    },
    "/_auth/_defaultView/inspections/add": {
      "filePath": "_auth/_defaultView/inspections/add.tsx",
      "parent": "/_auth/_defaultView",
      "children": [
        "/_auth/_defaultView/inspections/add/$apparatusId"
      ]
    },
    "/_auth/_defaultView/subscription/done": {
      "filePath": "_auth/_defaultView/subscription.done.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/users/archived": {
      "filePath": "_auth/_defaultView/users/archived.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_fullView/invalidSubscription/company": {
      "filePath": "_auth/_fullView/invalidSubscription/company.tsx",
      "parent": "/_auth/_fullView"
    },
    "/_auth/_fullView/invalidSubscription/done": {
      "filePath": "_auth/_fullView/invalidSubscription/done.tsx",
      "parent": "/_auth/_fullView"
    },
    "/_auth/_fullView/invalidSubscription/payment": {
      "filePath": "_auth/_fullView/invalidSubscription/payment.tsx",
      "parent": "/_auth/_fullView"
    },
    "/_auth/_smallView/tickets/add": {
      "filePath": "_auth/_smallView/tickets/add.tsx",
      "parent": "/_auth/_smallView"
    },
    "/_auth/_defaultView/costcenters/": {
      "filePath": "_auth/_defaultView/costcenters/index.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/inspections/": {
      "filePath": "_auth/_defaultView/inspections/index.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/users/": {
      "filePath": "_auth/_defaultView/users/index.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_fullView/invalidSubscription/": {
      "filePath": "_auth/_fullView/invalidSubscription/index.tsx",
      "parent": "/_auth/_fullView"
    },
    "/_auth/_smallView/tickets/": {
      "filePath": "_auth/_smallView/tickets/index.tsx",
      "parent": "/_auth/_smallView"
    },
    "/_auth/_defaultView/apparatuses/_apparatusesMenue/export": {
      "filePath": "_auth/_defaultView/apparatuses/_apparatusesMenue/export.tsx",
      "parent": "/_auth/_defaultView/apparatuses/_apparatusesMenue"
    },
    "/_auth/_defaultView/apparatuses/edit/$id": {
      "filePath": "_auth/_defaultView/apparatuses/edit.$id.tsx",
      "parent": "/_auth/_defaultView/apparatuses"
    },
    "/_auth/_defaultView/apparatuses/view/$id": {
      "filePath": "_auth/_defaultView/apparatuses/view.$id.tsx",
      "parent": "/_auth/_defaultView/apparatuses"
    },
    "/_auth/_defaultView/costcenters/view/$id": {
      "filePath": "_auth/_defaultView/costcenters/view.$id.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/inspections/add/$apparatusId": {
      "filePath": "_auth/_defaultView/inspections/add.$apparatusId.tsx",
      "parent": "/_auth/_defaultView/inspections/add"
    },
    "/_auth/_defaultView/inspections/edit/$id": {
      "filePath": "_auth/_defaultView/inspections/edit.$id.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/inspections/view/$id": {
      "filePath": "_auth/_defaultView/inspections/view.$id.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/testplans/add/$apparatusId": {
      "filePath": "_auth/_defaultView/testplans/add.$apparatusId.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/testplans/edit/$id": {
      "filePath": "_auth/_defaultView/testplans/edit.$id.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/testplans/view/$id": {
      "filePath": "_auth/_defaultView/testplans/view.$id.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_defaultView/users/view/$id": {
      "filePath": "_auth/_defaultView/users/view.$id.tsx",
      "parent": "/_auth/_defaultView"
    },
    "/_auth/_smallView/_settingsLayout/settings/change": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/change.tsx",
      "parent": "/_auth/_smallView/_settingsLayout"
    },
    "/_auth/_smallView/_settingsLayout/settings/company": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/company.tsx",
      "parent": "/_auth/_smallView/_settingsLayout"
    },
    "/_auth/_smallView/_settingsLayout/settings/customfields": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/customfields.tsx",
      "parent": "/_auth/_smallView/_settingsLayout"
    },
    "/_auth/_smallView/_settingsLayout/settings/files": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/files.tsx",
      "parent": "/_auth/_smallView/_settingsLayout"
    },
    "/_auth/_smallView/_settingsLayout/settings/password": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/password.tsx",
      "parent": "/_auth/_smallView/_settingsLayout"
    },
    "/_auth/_smallView/tickets/view/$ticketId": {
      "filePath": "_auth/_smallView/tickets/view.$ticketId.tsx",
      "parent": "/_auth/_smallView"
    },
    "/_auth/_defaultView/apparatuses/_apparatusesMenue/": {
      "filePath": "_auth/_defaultView/apparatuses/_apparatusesMenue/index.tsx",
      "parent": "/_auth/_defaultView/apparatuses/_apparatusesMenue"
    },
    "/_auth/_smallView/_settingsLayout/settings/": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/index.tsx",
      "parent": "/_auth/_smallView/_settingsLayout"
    },
    "/_auth/_smallView/_settingsLayout/settings/mandantor/change": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/mandantor/change.tsx",
      "parent": "/_auth/_smallView/_settingsLayout"
    },
    "/_auth/_smallView/_settingsLayout/settings/subscription": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/subscription",
      "parent": "/_auth/_smallView/_settingsLayout",
      "children": [
        "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout",
        "/_auth/_smallView/_settingsLayout/settings/subscription/done",
        "/_auth/_smallView/_settingsLayout/settings/subscription/info"
      ]
    },
    "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout.tsx",
      "parent": "/_auth/_smallView/_settingsLayout/settings/subscription",
      "children": [
        "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/payment",
        "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/"
      ]
    },
    "/_auth/_smallView/_settingsLayout/settings/subscription/done": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/subscription/done.tsx",
      "parent": "/_auth/_smallView/_settingsLayout/settings/subscription"
    },
    "/_auth/_smallView/_settingsLayout/settings/subscription/info": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/subscription/info.tsx",
      "parent": "/_auth/_smallView/_settingsLayout/settings/subscription"
    },
    "/_auth/_smallView/_settingsLayout/settings/mandantor/": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/mandantor/index.tsx",
      "parent": "/_auth/_smallView/_settingsLayout"
    },
    "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/payment": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/payment.tsx",
      "parent": "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout"
    },
    "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/": {
      "filePath": "_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/index.tsx",
      "parent": "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout"
    }
  }
}
ROUTE_MANIFEST_END */
