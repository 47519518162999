/** @format */

import SuspenseLoad from "@components/SuspenseLoad/SuspenseLoad";
import TestplansView from "@screen/authenticated/Testplans/TestplansView/TestplansView";
import { createFileRoute } from "@tanstack/react-router";
import { Suspense } from "react";

export const Route = createFileRoute("/_auth/_defaultView/testplans/view/$id")({
    component: () => (
        <Suspense fallback={<SuspenseLoad />}>
            <TestplansView />
        </Suspense>
    ),
});
