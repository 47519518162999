/** @format */

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Divider,
    Box,
    ModalProps,
} from "@chakra-ui/react";
import { useRouter } from "@tanstack/react-router";
import React, { useEffect, useState } from "react";

type ModalViewProps = Omit<ModalProps, "isOpen" | "onClose"> & {
    title?: string;
};

const ModalView: React.FC<ModalViewProps> = (props) => {
    const { children, title } = props;

    const router = useRouter();

    const [isOpen, setIsOpen] = useState<boolean>(true);

    const catchEscape = (event: KeyboardEvent) => {
        if (event.keyCode === 27) {
            handleClose();
        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        document.addEventListener("keydown", catchEscape, false);

        return () => {
            document.removeEventListener("keydown", catchEscape, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            onClose={() => handleClose()}
            onCloseComplete={() => router.history.go(-1)}
            isOpen={isOpen}
            isCentered
            {...props}
        >
            <ModalOverlay />
            <ModalContent>
                {title && (
                    <>
                        <ModalHeader>{title}</ModalHeader>{" "}
                        <Box pr={{ base: 2, md: 3, "2xl": 5 }} pl={{ base: 2, md: 3, "2xl": 5 }}>
                            <Divider size="xl" />
                        </Box>
                    </>
                )}

                <ModalCloseButton />
                <ModalBody>{children}</ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ModalView;
