/** @format */

import InspectionsView from "@screen/authenticated/Inspections/InspectionsView/InspectionsView";
import i18n from "@services/i18n";
import { fetchInspection } from "@services/Models/InspectionService";
import { queryOptions } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

export const inspectionViewQueryOptions = (id: string) =>
    queryOptions({
        queryKey: ["inspections", "view", id],
        queryFn: () => fetchInspection(id),
        enabled: !!id,
    });

export const Route = createFileRoute("/_auth/_defaultView/inspections/view/$id")({
    loader: ({ context, params }) => {
        return context.queryClient.ensureQueryData(inspectionViewQueryOptions(params.id));
    },
    component: InspectionsView,
    head: ({ loaderData }) => {
        return {
            meta: [
                {
                    titleElement: {
                        title: loaderData?.apparatus.identno,
                        mini: false,
                        miniHeadline: i18n.t("inspection:add.title", "Prüfbericht"),
                        info: i18n.t("inspection:add.title", "Prüfbericht"),
                    },
                },
            ],
        };
    },
});
