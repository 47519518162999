/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";

import defaultColors from "../defaultColors";

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const Input: ComponentStyleConfig = {
    // The styles all inputs have in common
    baseStyle: () => ({
        field: {
            fontWeight: "400",
            borderRadius: "base",
            boxShadow: "xs",
            bg: "gray.10",
            _disabled: {
                bg: "gray.10",
                _hover: {
                    bg: "gray.10",
                },
            },
            _placeholder: {
                color: "grey.30",
            },
        },
    }),
    // Two sizes: sm and md
    sizes: {
        sm: {
            fontSize: "sm",
            px: 4,
            py: 3,
        },
        md: {
            fontSize: "md",
            px: 6,
            py: 4,
        },
        lg: {
            fontSize: "lg",
            px: 12,
            py: 15,
        },
    },
    variants: {
        filled: ({ colorMode, colorScheme }) => ({
            field: {
                bg: `inputs.${colorScheme}.${colorMode}.bg`,
                color: `inputs.${colorScheme}.${colorMode}.color`,
                _placeholder: {
                    color: `inputs.${colorScheme}.${colorMode}.placeholder`,
                },
                _hover: {
                    bg: `inputs.${colorScheme}.${colorMode}.hover.bg`,
                    color: `inputs.${colorScheme}.${colorMode}.hover.color`,
                    _placeholder: {
                        _placeholder: `inputs.${colorScheme}.${colorMode}.hover.placeholder`,
                    },
                },
                _disabled: {
                    bg: `inputs.${colorScheme}.${colorMode}.bg`,
                    color: `inputs.${colorScheme}.${colorMode}.color`,
                    pointerEvents: "none",
                },
                _focus: {
                    bg: `inputs.${colorScheme}.${colorMode}.bg`,
                    color: `inputs.${colorScheme}.${colorMode}.color`,
                    _placeholder: {
                        _placeholder: `inputs.${colorScheme}.${colorMode}.placeholder`,
                    },
                },
            },
        }),
    },
    defaultProps: {
        size: "md",
        variant: "filled",
        colorScheme: "default",
        focusBorderColor: "blue.100",
    },
};

export const inputColors = {
    default: {
        light: {
            bg: defaultColors.grey[20],
            color: defaultColors.grey[90],
            placeholder: defaultColors.grey[70],
            hover: {
                bg: defaultColors.grey[16],
                color: defaultColors.grey[90],
                placeholder: defaultColors.grey[25],
            },
        },
        dark: {
            bg: defaultColors.grey[90],
            color: defaultColors.grey[10],
            placeholder: defaultColors.grey[80],
            hover: {
                bg: defaultColors.grey[80],
                color: defaultColors.grey[10],
                placeholder: defaultColors.grey[20],
            },
        },
    },
    inverse: {
        light: {
            bg: defaultColors.grey[20],
            color: defaultColors.grey[90],
            placeholder: defaultColors.grey[70],
            hover: {
                bg: defaultColors.grey[16],
                color: defaultColors.grey[90],
                placeholder: defaultColors.grey[25],
            },
        },
        dark: {
            bg: defaultColors.grey[100],
            color: defaultColors.grey[10],
            placeholder: defaultColors.grey[20],
            hover: {
                bg: defaultColors.grey[80],
                color: defaultColors.grey[10],
                placeholder: defaultColors.grey[20],
            },
        },
    },
};

export default Input;
