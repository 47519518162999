/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";

import defaultColors from "../defaultColors";

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const Textarea: ComponentStyleConfig = {
    // The styles all inputs have in common
    baseStyle: () => ({
        fontWeight: "400",
        borderRadius: "base",
        rounded: "md",
        bg: defaultColors.grey[20],
        _disabled: {
            bg: "gray.10",
            _hover: {
                bg: "gray.10",
            },
        },
        _placeholder: {
            color: "grey.20",
        },
    }),
    // Two sizes: sm and md
    sizes: {
        sm: {
            fontSize: "sm",
            px: 3,
            py: 3,
        },
        md: {
            fontSize: "md",
            px: 4,
            py: 3,
        },
    },
    variants: {
        filled: ({ colorMode, colorScheme }) => ({
            bg: `inputs.${colorScheme}.${colorMode}.bg`,
            color: `inputs.${colorScheme}.${colorMode}.color`,
            _placeholder: `inputs.${colorScheme}.${colorMode}.placeholder`,
            _hover: {
                bg: `inputs.${colorScheme}.${colorMode}.hover.bg`,
                color: `inputs.${colorScheme}.${colorMode}.hover.color`,
                _placeholder: `inputs.${colorScheme}.${colorMode}.hover.placeholder`,
            },
        }),
    },
    defaultProps: {
        size: "md",
        variant: "filled",
        colorScheme: "default",
        focusBorderColor: "blue.100",
    },
};

// Colors are defined in ./Input.ts

export default Textarea;
