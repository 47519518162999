/** @format */

import LoadingContext from "@context/LoadingProvider";
import { useContext, useEffect } from "react";

export const useLoading = (): {
    setLoading: (loading: boolean) => void;
} => {
    const { setLoading } = useContext(LoadingContext);

    useEffect(() => {
        setLoading(true);

        return () => {
            setLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        setLoading,
    };
};
