/** @format */
import { uploadAdapter } from "@api/ApiRequest";
import { FileUploads } from "@api/ext/ApparatusAdapter";
import { useToast } from "@chakra-ui/react";
import useApparatusBasedataFileType from "@hook/translations/useApparatusBasedataFileType";
import useApparatusFileType from "@hook/translations/useApparatusFileType";
import { FileUrlType, SelectType, getFileTypeFromFile } from "@services/FileTypes";
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { getFileTypeFromFileUpload } from "@utils/file";
import { uniqueId } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const removeUpload = async (postData: { id: string; index: number }) =>
    await uploadAdapter.removeUpload(postData.id);

const defaultValue: {
    handleRemoveUploadFile: (id: number) => void;
    handleFileSelect: (list: FileList, scrollRef: React.RefObject<HTMLDivElement>) => void;
    files: FileUrlType[];
    removeSelectedFile: (index: number) => void;
    handleSelectFile: (index: number) => void;
    handleSelectType: (type: SelectType) => void;
    clearFiles: () => void;
    clearFilesUploads: () => void;
    selectTypesList: SelectType[];
} = {
    handleRemoveUploadFile: () => null,
    handleFileSelect: () => null,
    files: [],
    removeSelectedFile: () => null,
    handleSelectFile: () => null,
    handleSelectType: () => null,
    clearFiles: () => null,
    clearFilesUploads: () => null,
    selectTypesList: [],
};

const FileUploadContext = React.createContext(defaultValue);

interface FileUploadProviderProps {
    children?: React.ReactNode;
    queryKey?: QueryKey;
    defaultFileUrls?: FileUploads[];
    isDefaultSelect?: boolean;
    defaultSelect?: {
        selectId: number;
        types: string[];
    };
}

export const useFileUploadContext = (): typeof defaultValue => {
    const ctx = useContext(FileUploadContext);

    if (ctx === null) {
        throw new Error("FileUploadContext missing in parent tree");
    }

    return ctx;
};

const FileUploadProvider: React.FC<FileUploadProviderProps> = ({
    children,
    queryKey,
    defaultFileUrls,
    isDefaultSelect = true,
    defaultSelect,
}) => {
    const [t] = useTranslation();
    const queryClient = useQueryClient();

    const [files, setFiles] = useState<FileUrlType[]>([]);

    const toast = useToast();

    const selectTypeApparatus = useApparatusFileType();
    const selectTypeBasedata = useApparatusBasedataFileType();

    const selectTypesList = useMemo(
        () => [...selectTypeApparatus, ...selectTypeBasedata],
        [selectTypeApparatus, selectTypeBasedata]
    );

    useEffect(() => {
        setFiles((oldFiles) => {
            if (defaultFileUrls && defaultFileUrls.length !== 0) {
                const filesDefault: FileUrlType[] = [];
                defaultFileUrls.map((value, index) => {
                    filesDefault.push({
                        id: String(value.id),
                        type: getFileTypeFromFile(value),
                        state: "default",
                        selected: isDefaultSelect && index === 0,
                        fileDefault: value,
                        base: value.upload_info === "Base",
                    });
                });
                const mergedArray = [
                    ...filesDefault,
                    ...oldFiles.filter(
                        (item2) => item2.state !== "default" && item2.state !== "deleted"
                    ),
                ];

                return mergedArray;
            }

            return oldFiles;
        });
    }, [defaultFileUrls, isDefaultSelect]);

    const { mutate: removeUploadFile } = useMutation({
        mutationFn: removeUpload,

        onSuccess: (data, oldUpload) => {
            if (!defaultFileUrls || !queryKey) {
                return;
            }

            queryClient.setQueryData(queryKey, (oldData?: { uploads: FileUploads[] }) => {
                if (oldData && oldData.uploads) {
                    let changedUpload = { ...oldData };

                    const updatedData = oldData.uploads.filter((item, index) => {
                        return index !== oldUpload.index;
                    });

                    changedUpload = { ...changedUpload, uploads: updatedData };

                    return changedUpload;
                }

                return oldData;
            });

            toast({
                title: t("global:upload.remove.info", "Datei entfernt", { ns: "global" }),
                description: t(
                    "global:upload.remove.description",
                    "Datei wurde erfolgreich entfernt",
                    { ns: "global" }
                ),
                status: "success",
                duration: 2000,
            });
        },
    });

    const handleFileSelect = useCallback(
        (filesList: FileList | null, scrollRef: React.RefObject<HTMLDivElement>) => {
            if (!filesList) {
                return;
            }

            const selectedIndex = files.find((value) => value.selected === true);

            for (let i = 0; i < filesList.length; i++) {
                const id = uniqueId();

                setFiles((updatedList) => {
                    const fileType = getFileTypeFromFileUpload(filesList[i]);

                    const fileState: FileUrlType = {
                        id,
                        state: "tmp",
                        type: fileType,
                        file: filesList[i],
                        selected: !!(!selectedIndex && i === 0),
                    };

                    if (defaultSelect && defaultSelect.types.includes(filesList[i].type)) {
                        const select = selectTypesList.find(
                            (value) => value.key === defaultSelect.selectId
                        );

                        fileState.fileType = select;
                    }

                    if (!updatedList) {
                        return [fileState];
                    }

                    return [...updatedList, fileState];
                });
                const reader = new FileReader();

                reader.onload = (result) => {
                    setFiles((updatedList) => {
                        const fileFound = updatedList.findIndex((value) => value.id === id);

                        if (fileFound === -1) {
                            return updatedList;
                        }

                        const copyList = [...updatedList];
                        copyList[fileFound].url = result?.target?.result as string;
                        copyList[fileFound].state = "uploaded";

                        return copyList;
                    });
                };

                reader.readAsDataURL(filesList[i]);
            }

            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
                }
            }, 100);
        },
        [defaultSelect, files, selectTypesList]
    );

    const removeSelectedFile = useCallback(
        (indexFile: number) => {
            if (!files) {
                return;
            }

            setFiles((oldFiles) => {
                const foundIndex = oldFiles.findIndex((value, index) => index === indexFile);

                const copyFiles = [...oldFiles];

                if (foundIndex !== -1) {
                    copyFiles[foundIndex].state = "deleted";
                }

                return copyFiles;
            });
        },
        [files]
    );

    const handleRemoveUploadFile = useCallback(
        (id: number) => {
            if (!defaultFileUrls && !files.length) {
                return;
            }

            const imageUrlCopy = files.find((item, index) => {
                return index === id && item.state === "default";
            });

            removeSelectedFile(id);

            if (imageUrlCopy) {
                removeUploadFile({ id: String(imageUrlCopy.id), index: id });
            }
        },
        [defaultFileUrls, files, removeSelectedFile, removeUploadFile]
    );

    const handleSelectFile = useCallback((index: number) => {
        setFiles((filesList) => {
            const filesListCopy = [...filesList];

            const findSelectedIndex = filesListCopy.findIndex((value) => value.selected === true);

            if (findSelectedIndex !== -1) {
                filesListCopy[findSelectedIndex].selected = false;
            }

            filesListCopy[index].selected = true;

            return filesListCopy;
        });
    }, []);

    const handleSelectType = useCallback(
        (type?: SelectType) => {
            if (!files) {
                return;
            }

            const selectedIndex = files.findIndex((value) => value.selected === true);

            if (selectedIndex === -1) return;

            setFiles((filesInfo) => {
                const fileCopy = [...filesInfo];
                fileCopy[selectedIndex].fileType = type;

                return fileCopy;
            });
        },
        [files]
    );

    const clearFiles = useCallback(() => {
        setFiles(() => []);
    }, []);

    const clearFilesUploads = useCallback(() => {
        setFiles((oldFiles) => {
            const copyFiles = oldFiles.filter((file) => file.state === "default");

            return copyFiles;
        });
    }, []);

    const providerValue = {
        handleRemoveUploadFile,
        handleFileSelect,
        files,
        removeSelectedFile,
        handleSelectFile,
        handleSelectType,
        clearFiles,
        clearFilesUploads,
        selectTypesList,
    };

    return (
        <FileUploadContext.Provider value={providerValue}>{children}</FileUploadContext.Provider>
    );
};

export { FileUploadProvider };
export default FileUploadContext;
