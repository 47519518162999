/** @format */

import UserIndex from "@screen/authenticated/Users/UserIndex/UserIndex";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_defaultView/users/archived")({
    component: UserIndex,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("user:title", "Benutzer"),
                    },
                },
            ],
        };
    },
});
