/** @format */

import { useMatches, useRouter, useRouterState } from "@tanstack/react-router";
import React, { useCallback, useEffect, useState } from "react";
import { PageTitleState } from "../App";

interface HistoryStackInterface {
    title: string;
    info: string;
    link: string;
}

export const defaultContextValue: {
    historyStack: HistoryStackInterface[];
} = {
    historyStack: [],
};

const HistoryStackContext = React.createContext(defaultContextValue);

interface HistoryStackProviderProps {
    children?: React.ReactNode;
}

let unlisten = (): void | null => null;

const HistoryStackProvider: React.FC<HistoryStackProviderProps> = ({ children }) => {
    const [historyStack, setHistoryStack] = useState<HistoryStackInterface[]>([]);

    const { history } = useRouter();

    const matches = useMatches();
    const location = useRouterState({ select: (s) => s.location });

    const getCurrTitle = useCallback(() => {
        const match = matches.findLast(
            (value) =>
                value.pathname === location?.pathname || value.pathname === location?.pathname + "/"
        );

        if (!match) {
            return undefined;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const titleElement: { titleElement?: PageTitleState } = match?.meta?.findLast(
            (value: { titleElement?: PageTitleState }) => value.titleElement
        );

        if (!titleElement || !titleElement.titleElement) {
            return undefined;
        }

        const info = titleElement.titleElement;

        return {
            title: info.title,
            info: info?.info ?? "",
            path: match.pathname,
        };
    }, [matches, location]);

    useEffect(() => {
        unlisten();
        unlisten = history.subscribe(({ action }) => {
            if (action.type !== "PUSH") {
                return;
            }

            setHistoryStack((old) => {
                if (old.length >= 4) {
                    old.pop();
                }

                const title = getCurrTitle();

                if (!title) {
                    return old;
                }

                const info = title.info;

                const updateHistory = title.path;

                if (updateHistory === "/") {
                    return old;
                }

                if (old.length === 0) {
                    return [{ link: updateHistory, title: title.title, info }];
                }

                if (old.length >= 1) {
                    return [{ link: updateHistory, title: title.title, info }, ...old];
                }

                return [];
            });
        });

        return () => {
            unlisten();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCurrTitle]);

    const providerValue = {
        historyStack,
    };

    return (
        <HistoryStackContext.Provider value={providerValue}>
            {children}
        </HistoryStackContext.Provider>
    );
};

export { HistoryStackProvider };
export default HistoryStackContext;
