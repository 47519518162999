/** @format */

import { createFileRoute, Outlet } from "@tanstack/react-router";
import { Flex, Text } from "@chakra-ui/react";
import ExtraNavigation from "@components/Navigation/ExtraNavigation/ExtraNavigation";
import AuthContext from "@context/AuthProvider";
import { useLoading } from "@hook/useLoading";
import useDefaultColors from "@theme/useDefaultColors";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

const SettingsLayout: React.FC = () => {
    const [t] = useTranslation();

    const { lightTextColor } = useDefaultColors();

    const { user, subscription } = useContext(AuthContext);

    const { setLoading } = useLoading();

    const subscriptionMode = subscription && !subscription?.is_active && user && user.is_admin;

    useEffect(() => {
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Flex w="full" flexDirection={{ base: "column", lg: "row" }}>
            <Flex
                flexDirection={{ base: "row", lg: "column" }}
                overflowX={{ base: "scroll", lg: "unset" }}
                overflowY="hidden"
                minW="200px"
                gap={5}
                mb={{ base: 5, lg: 0 }}
            >
                <Flex display={{ base: "none", lg: "flex" }}>
                    <Text fontSize="sm" color={lightTextColor} fontWeight="bold">
                        {t("global:personal", "Persönliches", { ns: "global" })}
                    </Text>
                </Flex>
                <ExtraNavigation
                    to="/settings"
                    exact
                    text={t("profile.profile", "Benutzerprofil")}
                />

                <ExtraNavigation
                    to="/settings/change"
                    exact
                    text={t("profile.change", "Anpassungen")}
                />

                <ExtraNavigation to="/settings/password" text={t("profile.password", "Kennwort")} />

                {!subscriptionMode && (
                    <ExtraNavigation
                        to="/settings/files"
                        text={t("profile.downloads", "Dateien")}
                    />
                )}

                {user?.is_admin && !subscriptionMode ? (
                    <>
                        <Flex mt={5} display={{ base: "none", lg: "flex" }}>
                            <Text fontSize="sm" color={lightTextColor} fontWeight="bold">
                                {t("global:mandantor", "Unternehmen", { ns: "global" })}
                            </Text>
                        </Flex>
                        <ExtraNavigation
                            to="/settings/mandantor"
                            exact
                            text={t("global:mandantor", "Unternehmen", { ns: "global" })}
                        />

                        <ExtraNavigation
                            to="/settings/mandantor/change"
                            exact
                            text={t("profile.change", "Anpassungen")}
                        />
                        {user && user.subscription_rights.canManageCompany && (
                            <ExtraNavigation
                                to="/settings/company"
                                exact
                                text={t("mandantor.companies.view.title", "Firmen")}
                            />
                        )}

                        {!subscriptionMode && (
                            <ExtraNavigation
                                to="/settings/subscription/info"
                                text={t("profile.payplan", "Konto")}
                            />
                        )}

                        <ExtraNavigation
                            to="/settings/customfields"
                            text={t("profile.customfields", "Zusätzliche Felder")}
                        />
                    </>
                ) : null}
            </Flex>

            <Flex w="full" flexDirection="column">
                <Outlet />
            </Flex>
        </Flex>
    );
};

export const Route = createFileRoute("/_auth/_smallView/_settingsLayout")({
    component: SettingsLayout,
});
