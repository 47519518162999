/** @format */

import {
    FileType,
    allowedArchiveFormats,
    allowedAudioFormats,
    allowedFileTypesImg,
    allowedPdfFormats,
    allowedSheetFormats,
} from "@services/FileTypes";

export function getFileTypeFromFileUpload(file: File): FileType {
    let fileType: FileType;

    switch (true) {
        case allowedFileTypesImg.includes(file.type): {
            fileType = "img";
            break;
        }
        case allowedPdfFormats.includes(file.type): {
            fileType = "pdf";
            break;
        }
        case allowedSheetFormats.includes(file.type): {
            fileType = "sheet";
            break;
        }
        case allowedArchiveFormats.includes(file.type): {
            fileType = "archive";
            break;
        }
        case allowedAudioFormats.includes(file.type): {
            fileType = "sheet";
            break;
        }
        default:
            fileType = "other";
    }

    return fileType;
}
