/** @format */

import { Flex } from "@chakra-ui/react";
import ExtraNavigation from "@components/Navigation/ExtraNavigation/ExtraNavigation";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

export const Route = createFileRoute("/_auth/_defaultView/apparatuses/_apparatusesMenue")({
    component: RouteComponent,
});

function RouteComponent() {
    const [t] = useTranslation();

    return (
        <Flex w="full" flexDirection={{ base: "column", lg: "row" }}>
            <Flex
                flexDirection={{ base: "row", lg: "column" }}
                minW="200px"
                gap={5}
                mb={{ base: 5, lg: 0 }}
            >
                <ExtraNavigation
                    to="/apparatuses"
                    exact
                    text={t("apparatus:index.title", "Prüfmittel")}
                />
                <ExtraNavigation
                    exact
                    to="/apparatuses/export"
                    text={t("apparatus:export.title", "Export")}
                />
            </Flex>

            <Flex w="full" maxW={{ base: "full", lg: "calc(100% - 200px)" }} flexDirection="column">
                <Outlet />
            </Flex>
        </Flex>
    );
}
