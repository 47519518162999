/** @format */

import { Box, Flex, Img, Text } from "@chakra-ui/react";
import RenderFileIcon from "@components/RenderFileIcon/RenderFileIcon";
import { useFileUploadContext } from "@context/FileUploadProvider";
import { FileType } from "@services/FileTypes";
import defaultColors from "@theme/defaultColors";
import useDefaultColors from "@theme/useDefaultColors";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { FiXCircle } from "react-icons/fi";

interface SingleFileViewProps {
    type: FileType;
    url?: string;
    index: number;
    file?: File;
    selected?: boolean;
    typeName?: string;
    defaultFile?: boolean;
}

let timeoutId: ReturnType<typeof setTimeout>;

const SingleFileView: React.FC<SingleFileViewProps> = ({
    index,
    url,
    type,
    file,
    selected = false,
    typeName,
    defaultFile = false,
}) => {
    const { lightTextColor, redTextColor, defaultTextColor } = useDefaultColors();

    const { removeSelectedFile, handleSelectFile } = useFileUploadContext();

    const [pending, setPending] = useState(false);

    const handleRemoveFile = (index: number) => {
        setPending(() => true);
        removeSelectedFile(index);
    };

    useEffect(() => {
        if (pending) {
            timeoutId = setTimeout(() => {
                setPending(() => false);
            }, 1000);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [pending]);

    if (!file) {
        return null;
    }

    return (
        <Flex
            alignItems="center"
            key={index}
            cursor="pointer"
            borderBottom="1px"
            borderBottomColor="inherit"
            justifyContent="space-between"
            p={2}
            pl={0}
            borderLeft="inherit"
        >
            <Flex
                alignItems="center"
                onClick={() => handleSelectFile(index)}
                pl={2}
                borderLeftColor={selected ? defaultColors.blue[100] : defaultColors.transparent}
                borderLeftWidth="2px"
                _hover={{ opacity: 0.7, borderLeftColor: defaultColors.blue[30] }}
            >
                {type === "img" ? (
                    <Img
                        height="40px"
                        width="40px"
                        mr={2}
                        borderRadius={2}
                        src={url}
                        alt="Prüfmittel Bild"
                        objectFit="contain"
                        opacity={defaultFile ? 0.5 : 1}
                    />
                ) : (
                    <Flex
                        height="40px"
                        borderRadius={2}
                        mr={2}
                        width="40px"
                        alignItems="center"
                        justifyContent="center"
                        backgroundColor={defaultColors.blue[100]}
                        opacity={defaultFile ? 0.5 : 1}
                    >
                        <RenderFileIcon type={type} />
                    </Flex>
                )}

                <Box opacity={defaultFile ? 0.5 : 1}>
                    <Text fontSize={{ base: "xs", "2xl": "md" }} color={lightTextColor}>
                        {file.name}
                    </Text>
                    <Text fontSize="xs" color={defaultTextColor} fontStyle="italic">
                        {typeName ? typeName : ""}
                    </Text>
                </Box>
            </Flex>
            <Flex alignItems="center">
                <Text fontSize="14px" color={lightTextColor} mr={3}>
                    {DateTime.fromMillis(file.lastModified).toFormat("dd.MM.yyyy HH:mm")}
                </Text>
                <Box
                    _hover={{ opacity: pending ? 0.1 : 0.5 }}
                    onClick={pending ? () => null : () => handleRemoveFile(index)}
                >
                    <FiXCircle color={redTextColor} />
                </Box>
            </Flex>
        </Flex>
    );
};

export default SingleFileView;
