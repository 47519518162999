/** @format */

import { inspectionAdapter, inspectionResultAdapter } from "@api/ApiRequest";
import { InspectionAddModel, InspectionType } from "@api/ext/InspectionAdapter";
import { InspectionResult, InspectionResultModel } from "@api/ext/InspectionResultAdapter";
import { EmptyExtraData, PaginationRequest } from "@components/Table/TableBuilder";
import { CheckIntervalReturn } from "@screen/authenticated/Apparatuses/ApparatusesForm/ApparatusesForm";

const allowedInspectionResultsForType = {
    [InspectionType.CALIBRATION]: [
        InspectionResult.OPERATIONAL,
        InspectionResult.OPERATIONAL_AFTER_INTERVENTION,
        InspectionResult.CONDITIONALLY_OPERATIONAL,
        InspectionResult.NOT_OPERATIONAL,
        InspectionResult.DEFECT,
        InspectionResult.SEE_REPORT,
        InspectionResult.SEE_PDF,
    ],
    [InspectionType.REPAIR]: [
        InspectionResult.OPERATIONAL_AFTER_INTERVENTION,
        InspectionResult.CONDITIONALLY_OPERATIONAL,
        InspectionResult.DEFECT,
        InspectionResult.SEE_REPORT,
        InspectionResult.SEE_PDF,
    ],
    [InspectionType.INSPECTION]: [
        InspectionResult.OPERATIONAL,
        InspectionResult.OPERATIONAL_AFTER_INTERVENTION,
        InspectionResult.CONDITIONALLY_OPERATIONAL,
        InspectionResult.NOT_OPERATIONAL,
        InspectionResult.DEFECT,
        InspectionResult.SEE_REPORT,
        InspectionResult.SEE_PDF,
    ],
    [InspectionType.OTHER]: [
        InspectionResult.DECOMMISSIONED,
        InspectionResult.SCRAPPED,
        InspectionResult.UNDETECTABLE,
        InspectionResult.RECOVERED,
    ],
    [InspectionType.DECISION]: [InspectionResult.OPERATIONAL, InspectionResult.NOT_OPERATIONAL],
};

export const getFilteredInspectionResults = (
    inspectionResults: InspectionResultModel[],
    type: InspectionType
) => {
    return inspectionResults.filter((entry) =>
        allowedInspectionResultsForType[type].some((result) => result === entry.name)
    );
};

export const fetchInspectionResults = async () => await inspectionResultAdapter.getIndexList();

export const fetchInspection = async (id?: string) =>
    id ? await inspectionAdapter.view(parseInt(id, 10)) : undefined;

export const fetchInspectionsList = async (data: PaginationRequest<EmptyExtraData>) =>
    await inspectionAdapter.index({
        pageIndex: data.pageIndex + 1,
        pageSize: data.pageSize,
        sort: data.sort,
        direction: data.direction,
        search: data.search,
    });

export const addInspection = async ({
    data,
    files,
    checkInterval,
}: {
    data: InspectionAddModel;
    files?: File[];
    checkInterval?: CheckIntervalReturn;
}) => await inspectionAdapter.addInspection(data, files, undefined, checkInterval);

export const updateInspection = async ({
    id,
    inspectionToSave,
    files,
}: {
    id: number;
    inspectionToSave: InspectionAddModel;
    files?: File[];
}) => await inspectionAdapter.editInspection(id, inspectionToSave, files);
