/** @format */

export const useGetFileSize = (): {
    getSize: (size: number) => string;
} => {
    const getSize = (size: number): string => {
        let iSize = size / 1024;
        let rSize = "";

        if (iSize / 1024 > 1) {
            if (iSize / 1024 / 1024 > 1) {
                iSize = Math.round((iSize / 1024 / 1024) * 100) / 100;
                rSize = `${iSize} GB`;
            } else {
                iSize = Math.round((iSize / 1024) * 100) / 100;
                rSize = `${iSize} MB`;
            }
        } else {
            iSize = Math.round(iSize * 100) / 100;
            rSize = `${iSize} KB`;
        }

        return rSize;
    };

    return {
        getSize,
    };
};
