/** @format */

import ChangeAdditional from "@screen/authenticated/Mandantor/MandantorView/ChangeAdditional/ChangeAdditional";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_smallView/_settingsLayout/settings/mandantor/change")(
    {
        component: ChangeAdditional,
        head: () => {
            return {
                meta: [
                    {
                        titleElement: {
                            title: i18n.t("profile.change", "Anpassungen"),
                            info: "",
                            mini: true,
                            miniHeadline: i18n.t("global:mandantor", "Unternehmen", {
                                ns: "global",
                            }),
                        },
                    },
                ],
            };
        },
    }
);
