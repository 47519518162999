/** @format */

import { CompanyAddModel } from "@api/ext/CompanyAdapter";
import { fetchCompanyData } from "@services/Models/CompanyService";
import { useQuery } from "@tanstack/react-query";

import CompanyForm from "./CompanyForm/CompanyForm";

const defaultData: CompanyAddModel = {
    name: "",
    address: "",
    zip: "",
    phone: "",
    city: "",
    email: "",
};
interface CompanyAddProps {
    defaultData?: CompanyAddModel;
    isEdit?: boolean;
}

const CompanyAdd: React.FC<CompanyAddProps> = () => {
    const { data: company, isLoading } = useQuery({
        queryKey: ["companies", "getAddCompany"],

        queryFn: () => fetchCompanyData(),
    });

    if (isLoading) {
        return null;
    }

    if (!company) {
        return null;
    }

    return <CompanyForm defaultData={defaultData} />;
};

export default CompanyAdd;
