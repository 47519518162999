/** @format */

import SuspenseLoad from "@components/SuspenseLoad/SuspenseLoad";
import UserView from "@screen/authenticated/Users/UserView/UserView";
import i18n from "@services/i18n";
import { fetchUser } from "@services/Models/UserService";
import { queryOptions } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { Suspense } from "react";

export const userQueryOptions = (id: string) =>
    queryOptions({
        queryKey: ["users", "view", id],
        queryFn: () => fetchUser(Number(id)),
        enabled: !!id,
    });

export const Route = createFileRoute("/_auth/_defaultView/users/view/$id")({
    loader: ({ context, params }) => {
        return context.queryClient.ensureQueryData(userQueryOptions(params.id));
    },
    component: () => (
        <Suspense fallback={<SuspenseLoad />}>
            <UserView />
        </Suspense>
    ),
    head: ({ loaderData }) => {
        return {
            meta: [
                {
                    titleElement: {
                        title: `${loaderData?.name} ${loaderData?.surname}`,
                        info: i18n.t("user:title", "Benutzer"),
                        mini: false,
                        miniHeadline: i18n.t("user:title", "Benutzer"),
                    },
                },
            ],
        };
    },
});
