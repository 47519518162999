/** @format */

import { FileUploads } from "@api/ext/ApparatusAdapter";

export const allowedFileTypesImg: string[] = ["image/png", "image/jpeg", "image/jpg"];

export const allowedSheetFormats: string[] = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/xml",
];

export const allowedArchiveFormats: string[] = [
    "application/x-freearc",
    "application/x-bzip",
    "application/x-bzip2",
    "application/gzip",
    "application/java-archive",
    "application/vnd.rar",
    "application/x-tar",
    "application/zip",
    "application/x-zip-compressed",
    "application/x-7z-compressed",
];

export const allowedAudioFormats: string[] = [
    "audio/aac",
    "application/x-cdf",
    "audio/mpeg",
    "audio/ogg",
    "audio/wav",
    "audio/webm",
];

export const allowedPdfFormats: string[] = ["application/pdf"];

export const allAllowedFilesType: string[] = [
    ...allowedPdfFormats,
    ...allowedSheetFormats,
    ...allowedArchiveFormats,
    ...allowedFileTypesImg,
    ...allowedAudioFormats,
];

export type FileType = "img" | "pdf" | "sheet" | "other" | "archive" | "audio";

export type SelectType = {
    key: number;
    name: string;
};

export type FileUrlType = {
    id: string;
    state: "default" | "uploaded" | "progress" | "tmp" | "deleted";
    file?: File;
    fileDefault?: FileUploads;
    selected: boolean;
    base?: boolean;
    url?: string;
    type: FileType;
    fileType?: SelectType;
};

export function getFileTypeFromFile(file: FileUploads): FileType {
    if (allowedFileTypesImg.includes(file.file_type)) {
        return "img";
    }

    if (allowedPdfFormats.includes(file.file_type)) {
        return "pdf";
    }

    if (allowedSheetFormats.includes(file.file_type)) {
        return "sheet";
    }

    if (allowedArchiveFormats.includes(file.file_type)) {
        return "archive";
    }

    if (allowedAudioFormats.includes(file.file_type)) {
        return "audio";
    }

    return "other";
}
