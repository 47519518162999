/** @format */

import Settings from "@screen/authenticated/Settings/ProfileSettings/ProfileSettings";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_smallView/_settingsLayout/settings/")({
    component: Settings,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("profile.profile", "Benutzerprofil"),
                        info: "",
                        mini: true,
                        miniHeadline: i18n.t("global:personal", "Persönliches", { ns: "global" }),
                    },
                },
            ],
        };
    },
});
