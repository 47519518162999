/** @format */

import { globalUserAdapter } from "@api/ApiRequest";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import ModalView from "@components/Modal/ModalView";
import LoadingContext from "@context/LoadingProvider";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

let intervalId: ReturnType<typeof setInterval>;

const Connecting: React.FC = () => {
    const [t] = useTranslation();
    const { setLoading } = useContext(LoadingContext);

    useEffect(() => {
        setLoading(false);

        intervalId = setInterval(() => {
            void globalUserAdapter.loggedin().then((check) => {
                if (check) {
                    window.location.reload();
                }
            });
        }, 15 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [setLoading]);

    return (
        <ModalView size="2xl" title={t("errorScreen.title", "Hoppla!")}>
            <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="full"
                height="50vh"
            >
                <Flex mb={10} justifyContent="center">
                    <Image src="/img/logo_main.png" alt="Memida Logo" />
                </Flex>
                <Flex flexDirection="column" maxW={300} mx="auto" alignItems="center">
                    <Text mb="10" textAlign="center">
                        {t(
                            "errorScreen.message",
                            "Der Service ist nicht erreichbar, bitte versuchen Sie es später noch einmal."
                        )}
                    </Text>

                    <Box mx="auto">
                        <Button onClick={() => window.location.reload()}>
                            {t("global:btn.tryAgain", "Erneut versuchen", { ns: "global" })}
                        </Button>
                    </Box>
                </Flex>
            </Flex>
        </ModalView>
    );
};

export default Connecting;
