/** @format */

import { costcenterAdapter } from "@api/ApiRequest";
import CostcentersView from "@screen/authenticated/Costcenters/CostcentersView/CostcentersView";
import i18n from "@services/i18n";
import { queryOptions } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

const fetchCostcenter = async (id: number) => await costcenterAdapter.view(id);

export const costcentersViewQueryOptions = (id: string) =>
    queryOptions({
        queryKey: ["costcenters", "view", id],
        queryFn: () => (id ? fetchCostcenter(parseInt(id, 10)) : undefined),
        enabled: !!id,
    });

export const Route = createFileRoute("/_auth/_defaultView/costcenters/view/$id")({
    loader: ({ context, params }) => {
        return context.queryClient.ensureQueryData(costcentersViewQueryOptions(params.id));
    },
    component: CostcentersView,
    head: ({ loaderData }) => {
        return {
            meta: [
                {
                    titleElement: {
                        title: loaderData?.name,
                        info: i18n.t("costcenter:view.title", "Kostenstelle"),
                        mini: false,
                        miniHeadline: i18n.t("costcenter:view.title", "Kostenstelle"),
                    },
                },
            ],
        };
    },
});
