/** @format */

import SuspenseLoad from "@components/SuspenseLoad/SuspenseLoad";
import SmallView from "@components/View/SmallView";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_smallView")({
    component: RouteComponent,
    pendingComponent: SuspenseLoad,
});

function RouteComponent() {
    return (
        <SmallView>
            <Outlet />
        </SmallView>
    );
}
