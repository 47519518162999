/** @format */

import { CustomFieldProvider } from "@context/CustomFieldProvider";
import InspectionsAdd from "@screen/authenticated/Inspections/InspectionsAdd/InspectionsAdd";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_defaultView/inspections/add")({
    component: RouteComponent,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("inspection:add.title", "Prüfbericht"),
                    },
                },
            ],
        };
    },
});

function RouteComponent() {
    return (
        <CustomFieldProvider customFieldKey="Inspections">
            <InspectionsAdd />
        </CustomFieldProvider>
    );
}
