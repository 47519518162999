/** @format */

import Password from "@screen/authenticated/Settings/Password/Password";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_smallView/_settingsLayout/settings/password")({
    component: Password,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("profile.password", "Kennwort"),
                        info: "",
                        mini: true,
                        miniHeadline: i18n.t("global:personal", "Persönliches", { ns: "global" }),
                    },
                },
            ],
        };
    },
});
