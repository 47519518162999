/** @format */

import { useTranslation } from "react-i18next";

const useApparatusBasedataFileType = () => {
    const [t] = useTranslation();

    return [
        {
            key: 6,
            name: t("apparatus:files.types.6", "Bild des Stammdatengeräts"),
        },
        {
            key: 7,
            name: t("apparatus:files.types.7", "technische Zeichnung"),
        },
        {
            key: 8,
            name: t("apparatus:files.types.8", "Bedienungsanleitung"),
        },
        {
            key: 9,
            name: t("apparatus:files.types.9", "Spezifikationen / Datenblatt"),
        },
    ];
};

export default useApparatusBasedataFileType;
