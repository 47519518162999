/** @format */

import { createRootRouteWithContext, Navigate, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { RouterContext } from "../App";

export const Route = createRootRouteWithContext<RouterContext>()({
    component: RootRoute,
    notFoundComponent: () => <Navigate to="/" />,
    errorComponent: () => <h1>HelloWorl</h1>,
});

function RootRoute() {
    return (
        <>
            <Outlet />
            <TanStackRouterDevtools position="top-left" />
        </>
    );
}
