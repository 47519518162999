/** @format */

import { CompanyModel } from "@api/ext/CompanyAdapter";
import { fetchCompanyData } from "@services/Models/CompanyService";
import { useQuery } from "@tanstack/react-query";

import CompanyForm from "./CompanyForm/CompanyForm";
import { useSearch } from "@tanstack/react-router";

interface CompanyEditProps {
    defaultData?: CompanyModel;
    isEdit?: boolean;
}

const CompanyEdit: React.FC<CompanyEditProps> = () => {
    const id = useSearch({
        from: "/_auth",
        select: (search) => search.extraId,
    });

    const { data: company, isLoading } = useQuery({
        queryKey: ["companies", "getAddCompany", id],

        queryFn: () => {
            if (!id) {
                return undefined;
            }

            return fetchCompanyData(Number(id));
        },

        enabled: !!id,
    });

    if (isLoading) {
        return null;
    }

    if (!company) {
        return null;
    }

    return <CompanyForm defaultData={company} isEdit />;
};

export default CompanyEdit;
