/** @format */

import { CustomFieldProvider } from "@context/CustomFieldProvider";
import InspectionsEdit from "@screen/authenticated/Inspections/InspectionsEdit/InspectionsEdit";
import i18n from "@services/i18n";
import { fetchInspection } from "@services/Models/InspectionService";
import { queryOptions } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

export const inspectionQueryOptions = (id: string) =>
    queryOptions({
        queryKey: ["inspections", "view", id],
        queryFn: () => (id ? fetchInspection(id) : undefined),
        enabled: !!id,
    });

export const Route = createFileRoute("/_auth/_defaultView/inspections/edit/$id")({
    loader: ({ context, params }) => {
        return context.queryClient.ensureQueryData(inspectionQueryOptions(params.id));
    },
    component: RouteComponent,
    head: ({ loaderData }) => {
        return {
            meta: [
                {
                    titleElement: {
                        title: loaderData?.apparatus.identno,
                        mini: false,
                        miniHeadline: i18n.t("inspection:add.title", "Prüfbericht"),
                        info: i18n.t("inspection:add.title", "Prüfbericht"),
                    },
                },
            ],
        };
    },
});

function RouteComponent() {
    return (
        <CustomFieldProvider customFieldKey="Inspections">
            <InspectionsEdit />
        </CustomFieldProvider>
    );
}
