/** @format */

import { FileType } from "@services/FileTypes";
import defaultColors from "@theme/defaultColors";
import { FaFileArchive, FaFileAudio, FaFilePdf, FaRegFileExcel } from "react-icons/fa";
import { FiFile } from "react-icons/fi";

const RenderFileIcon = ({
    type,
    size = 24,
    color = undefined,
}: {
    type?: FileType;
    size?: number;
    color?: string;
}) => {
    const renderColor = color ?? defaultColors.white;

    let result = <FiFile size={size} color={renderColor} />;

    switch (type) {
        case "pdf":
            result = <FaFilePdf size={size} color={renderColor} />;
            break;
        case "sheet":
            result = <FaRegFileExcel size={size} color={renderColor} />;
            break;
        case "archive":
            result = <FaFileArchive size={size} color={renderColor} />;
            break;
        case "audio":
            result = <FaFileAudio size={size} color={renderColor} />;
            break;
        default:
            break;
    }

    return result;
};

export default RenderFileIcon;
