/** @format */
import { ChakraProvider } from "@chakra-ui/react";
import PopUpLoading from "@components/PopUpAlert/Loading/PopUpLoading";
import PopUp from "@components/PopUpAlert/Save/PopUp";
import { LoadingProvider } from "@context/LoadingProvider";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { Settings } from "luxon";
import React, { useContext } from "react";
import {
    RouterProvider,
    createRouteMask,
    createRouter,
    ParsedLocation,
} from "@tanstack/react-router";
import { HistoryStackProvider } from "@context/HistoryStackProvider";

import AuthContext, { AuthProvider, defaultContextValue } from "./context/AuthProvider";
import theme from "./theme/theme";

import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/700.css";

import "./services/i18n";

import "./App.scss";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
        },
    },
});

const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
    key: "MEMIDA_SYNC",
});

persistQueryClient({
    queryClient,
    persister: localStoragePersister,
});

export interface PageTitleState {
    title: string;
    element?: string | React.ReactElement;
    info?: string;
    mini?: boolean;
    miniHeadline?: string;
}

export type RouterContext = {
    queryClient: typeof queryClient;
    auth: typeof defaultContextValue | undefined;
};

const unconfirmedMask = createRouteMask({
    routeTree,
    from: "/unconfirmed",
    to: "/",
    params: true,
});

const subscriptionMask = createRouteMask({
    routeTree,
    from: "/invalidSubscription",
    to: "/",
    params: true,
});

const loginMask = createRouteMask({
    routeTree,
    from: "/login",
    to: "/",
    params: true,
});

const dashboardMask = createRouteMask({
    routeTree,
    from: "/dashboard",
    to: "/",
    params: true,
});

// Create a new router instance
const router = createRouter({
    routeTree,
    context: {
        queryClient,
        auth: undefined,
    } as RouterContext,
    defaultPreload: "intent",
    defaultPreloadStaleTime: 0,
    routeMasks: [unconfirmedMask, subscriptionMask, loginMask, dashboardMask],
    scrollRestoration: true,
    InnerWrap: ({ children }) => {
        return <HistoryStackProvider>{children}</HistoryStackProvider>;
    },
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
    interface HistoryState {
        location: ParsedLocation;
    }
}

const MainRouter: React.FC = () => {
    const auth = useContext(AuthContext);

    return <RouterProvider router={router} context={{ auth }} />;
};

const App: React.FC = (): React.ReactElement => {
    Settings.defaultLocale = "de-DE";

    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <LoadingProvider>
                        <MainRouter />

                        <PopUpLoading />
                        <PopUp />
                    </LoadingProvider>
                </AuthProvider>

                <ReactQueryDevtools initialIsOpen={false} position="bottom" />
            </QueryClientProvider>
        </ChakraProvider>
    );
};
export default App;
