/** @format */

import SuspenseLoad from "@components/SuspenseLoad/SuspenseLoad";
import DefaultView from "@components/View/DefaultView";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_defaultView")({
    component: RouteComponent,
    pendingComponent: SuspenseLoad,
});

function RouteComponent() {
    return (
        <DefaultView>
            <Outlet />
        </DefaultView>
    );
}
