/** @format */

import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import AuthContext from "@context/AuthProvider";
import CenteredLayout from "@layouts/unauthenticated/CenteredLayout";
import { Navigate, redirect, retainSearchParams } from "@tanstack/react-router";
import { Link, Outlet, createFileRoute } from "@tanstack/react-router";
import { fallback, zodValidator } from "@tanstack/zod-adapter";
import defaultColors from "@theme/defaultColors";
import { useContext } from "react";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import i18n from "@services/i18n";

const allowedLanguages = ["de", "en"];

const searchShema = z.object({
    lang: fallback(z.enum(allowedLanguages as [string, ...string[]]).optional(), ""),
    redirect: z.string().optional(),
});

export const Route = createFileRoute("/_unauth")({
    validateSearch: zodValidator(searchShema),
    search: {
        middlewares: [retainSearchParams(["lang"])],
    },
    beforeLoad: ({ context, search }) => {
        if (context.auth && context.auth.authentication) {
            // eslint-disable-next-line no-throw-literal
            throw redirect({
                to: "/",
                search: {
                    redirect: location.href,
                },
            });
        }

        if (
            search &&
            search.lang &&
            search.lang !== i18n.resolvedLanguage &&
            allowedLanguages.includes(search.lang)
        ) {
            void i18n.changeLanguage(search.lang);
        }
    },
    component: UnAuthComponent,
    notFoundComponent: () => <Navigate to="/" />,
});

function UnAuthComponent() {
    const [t] = useTranslation();

    const { user, authentication } = useContext(AuthContext);

    const bodyBackground = useColorModeValue(defaultColors.grey[10], "gray.900");

    if (authentication && user) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Box backgroundColor={bodyBackground} minH="100vh">
                <CenteredLayout>
                    <Outlet />
                </CenteredLayout>
                <Flex
                    alignSelf="flex-end"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="row"
                    paddingBottom="30px"
                    fontSize="sm"
                    gap={5}
                >
                    <Link _hover={{ textDecoration: "none", opacity: 0.6 }} to="/imprint">
                        {t("global:imprint", "Impressum")}
                    </Link>
                    <Link _hover={{ textDecoration: "none", opacity: 0.6 }} mx={5} to="/privacy">
                        {t("global:privacy", "Datenschutz")}
                    </Link>
                    <Link _hover={{ textDecoration: "none", opacity: 0.6 }} to="/agb">
                        {t("global:agb", "Nutzungsbedingungen")}
                    </Link>
                </Flex>
            </Box>
        </>
    );
}
