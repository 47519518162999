/** @format */

import SubscriptionPlan from "@screen/subscription/Invalid/SubscriptionPlan";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
    "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/"
)({
    component: SubscriptionPlan,
});
