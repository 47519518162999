/** @format */

import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import CenteredLayout from "@layouts/unauthenticated/CenteredLayout";
import { createFileRoute, Link, Outlet } from "@tanstack/react-router";
import defaultColors from "@theme/defaultColors";
import { useTranslation } from "react-i18next";

export const Route = createFileRoute("/_default")({
    component: DefaultComponent,
});

function DefaultComponent() {
    const [t] = useTranslation();

    const bodyBackground = useColorModeValue(defaultColors.grey[10], "gray.900");

    return (
        <>
            <Box backgroundColor={bodyBackground} minH="100vh">
                <CenteredLayout>
                    <Outlet />
                </CenteredLayout>
                <Flex
                    alignSelf="flex-end"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="row"
                    paddingBottom="30px"
                    fontSize="sm"
                    gap={5}
                >
                    <Link _hover={{ textDecoration: "none", opacity: 0.6 }} to="/imprint">
                        {t("global:imprint", "Impressum")}
                    </Link>
                    <Link _hover={{ textDecoration: "none", opacity: 0.6 }} mx={5} to="/privacy">
                        {t("global:privacy", "Datenschutz")}
                    </Link>
                    <Link _hover={{ textDecoration: "none", opacity: 0.6 }} to="/agb">
                        {t("global:agb", "Nutzungsbedingungen")}
                    </Link>
                </Flex>
            </Box>
        </>
    );
}
