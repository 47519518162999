/** @format */

import CompanyIndex from "@screen/authenticated/Mandantor/Company/CompanyIndex";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_smallView/_settingsLayout/settings/company")({
    component: CompanyIndex,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("mandantor.companies.view.title", "Firmen"),
                        info: "",
                        mini: true,
                        miniHeadline: i18n.t("global:mandantor", "Unternehmen", { ns: "global" }),
                    },
                },
            ],
        };
    },
});
