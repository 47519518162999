/** @format */

import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Text,
    SliderProps,
} from "@chakra-ui/react";
import defaultColors from "@theme/defaultColors";
import useDefaultColors from "@theme/useDefaultColors";

const CustomSlider = (props: SliderProps) => {
    const { blueLightBoxBgColor, defaultTextColor } = useDefaultColors();

    return (
        <Slider {...props}>
            <SliderTrack h={2}>
                <SliderFilledTrack bg={defaultColors.blue[60]} />
            </SliderTrack>
            <SliderThumb boxSize={8} bg={blueLightBoxBgColor}>
                <Text color={defaultTextColor} fontSize={20} fontWeight="bold">
                    {props.value}
                </Text>
            </SliderThumb>
        </Slider>
    );
};

export default CustomSlider;
