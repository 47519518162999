/** @format */

import PaymentSubscription from "@screen/authenticated/Mandantor/Subscription/PaymentSubscription/PaymentSubscription";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
    "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout/payment"
)({
    component: PaymentSubscription,
});
