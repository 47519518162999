/** @format */

import SubscriptionDone from "@screen/subscription/SubscriptionDone/SubscriptionDone";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
    "/_auth/_smallView/_settingsLayout/settings/subscription/done"
)({
    component: SubscriptionDone,
});
