/** @format */

import { createFileRoute, Outlet, useLocation, useNavigate } from "@tanstack/react-router";
import { Flex, IconButton, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import SubscriptionInfoNavigation from "@components/SubscriptionNavigation/SubscriptionInfoNavigation";
import { ChangeSubscriptionContextProvider } from "@context/ChangeSubscriptionProvider";
import { defaultGap } from "@constants/styles";
import defaultColors from "@theme/defaultColors";
import { useEffect } from "react";
import { FiX } from "react-icons/fi";

export const Route = createFileRoute(
    "/_auth/_smallView/_settingsLayout/settings/subscription/_subscriptionLayout"
)({
    component: () => (
        <ChangeSubscriptionContextProvider>
            <SubscriptionChangeModal />
        </ChangeSubscriptionContextProvider>
    ),
});

const SubscriptionChangeModal: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === "/settings/subscription/done") {
            navigate({ to: "/settings/subscription/info" });
        }
    }, [location.pathname, navigate]);

    return (
        <Modal
            isOpen
            isCentered
            size="full"
            onClose={() => void navigate({ to: "/settings/subscription/info" })}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody p={0}>
                    <Flex position="relative" flexDirection={{ base: "column", lg: "row" }}>
                        <SubscriptionInfoNavigation trialMode={false} />

                        <Flex
                            w={{ base: "100%", xl: "80%" }}
                            p={defaultGap}
                            mt={{ base: 3, lg: 10 }}
                            flexDirection="column"
                            alignItems="center"
                        >
                            <IconButton
                                position="fixed"
                                right={5}
                                top={5}
                                zIndex={1003}
                                variant="ghost"
                                size="lg"
                                aria-label="close subscription modal"
                                icon={<FiX size={40} color={defaultColors.blue[60]} />}
                                _hover={{ opacity: 0.6 }}
                                onClick={() => void navigate({ to: "/settings/subscription/info" })}
                            />
                            <Flex
                                w={{ base: "full", xl: "90%" }}
                                h="full"
                                justifyContent="center"
                                mt={3}
                            >
                                <Outlet />
                            </Flex>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
