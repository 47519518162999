/** @format */

import { companyAdapter } from "@api/ApiRequest";
import { CompanyAddModel, CompanyModel } from "@api/ext/CompanyAdapter";

export const fetchCompanyData = async (id?: number) => {
    if (!id) {
        return await companyAdapter.getAdd();
    }

    return await companyAdapter.getEdit(id);
};

export const saveCompany = async ({
    company,
    image,
}: {
    company: CompanyAddModel;
    image?: File;
}) => {
    if ("id" in company && company.id) {
        return await companyAdapter.saveEdit(company as CompanyModel, image);
    }

    return await companyAdapter.saveAdd(company, image);
};
