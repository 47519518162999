/** @format */

import { attributeAdapter } from "@api/ApiRequest";
import {
    CompanyAttributeUpdate,
    MandantorAttributeUpdate,
    UserAttributeUpdate,
} from "@api/ext/AttributeAdapter";

export async function getAttributes<T>(
    model: "user" | "mandantor" | "company",
    foreignKey: number
) {
    return await attributeAdapter.getAttributes<T>(model, foreignKey);
}

export async function updateUserAttribute(postData: UserAttributeUpdate) {
    return await attributeAdapter.updateUserAttribute(postData);
}
export async function updateMandantorAttribute(postData: MandantorAttributeUpdate) {
    return await attributeAdapter.updateMandantorAttribute(postData);
}
export async function updateCompanyAttribute(postData: {
    attributes: CompanyAttributeUpdate;
    companyId: number;
}) {
    return await attributeAdapter.updateCompanyAttribute(postData);
}
