/** @format */

import { createFileRoute } from "@tanstack/react-router";
import { fallback, zodValidator } from "@tanstack/zod-adapter";
import { z } from "zod";

const resolveSearchSchema = z.object({
    isResolve: fallback(z.boolean().optional(), false),
});

export const Route = createFileRoute("/_auth/_defaultView/inspection")({
    validateSearch: zodValidator(resolveSearchSchema),
});
