/** @format */

import ApparatusesIndexExport from "@screen/authenticated/Apparatuses/ApparatusesIndex/ApparatusesIndexExport";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_defaultView/apparatuses/_apparatusesMenue/export")({
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("apparatus:export.title", "Export"),
                        mini: true,
                        miniHeadline: i18n.t("apparatus:index.title", "Prüfmittel"),
                    },
                },
            ],
        };
    },
    component: ApparatusesIndexExport,
});
