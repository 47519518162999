/** @format */

import { captureSentryException } from "@services/sentry";

export const useRequestError = (): {
    handleError: (message: unknown) => void;
} => {
    const handleError = (message: unknown) => {
        captureSentryException(message);
    };

    return {
        handleError,
    };
};
