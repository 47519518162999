/** @format */

import { useTranslation } from "react-i18next";

const useApparatusFileType = () => {
    const [t] = useTranslation();

    return [
        {
            key: 1,
            name: t("apparatus:files.types.1", "technische Zeichnung"),
        },
        {
            key: 2,
            name: t("apparatus:files.types.2", "Bedienungsanleitung"),
        },
        {
            key: 3,
            name: t("apparatus:files.types.3", "Bild des Prüfmittels"),
        },
        {
            key: 4,
            name: t("apparatus:files.types.4", "Spezifikationen / Datenblatt"),
        },
        {
            key: 5,
            name: t("apparatus:files.types.5", "Service-und Wartungsunterlagen "),
        },
    ];
};

export default useApparatusFileType;
