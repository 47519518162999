/** @format */

import { ApparatusViewProvider } from "@context/ApparatusViewProvider";
import { LocationProvider } from "@context/LocationProvider";
import ApparatusSuspense from "@screen/authenticated/Apparatuses/ApparatusView/ApparatusSuspense";
import i18n from "@services/i18n";
import { fetchApparatus } from "@services/Models/ApparatusService";
import { queryOptions } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { lazy, Suspense } from "react";

const ApparatusView = lazy(
    () => import("@screen/authenticated/Apparatuses/ApparatusView/ApparatusView")
);

export const apparatusQueryOptions = (id: string) =>
    queryOptions({
        queryKey: ["apparatuses", "view", id],
        queryFn: () => {
            if (!id) {
                return;
            }

            return fetchApparatus(parseInt(id, 10));
        },
    });

export const Route = createFileRoute("/_auth/_defaultView/apparatuses/view/$id")({
    loader: ({ context, params }) => {
        return context.queryClient.ensureQueryData(apparatusQueryOptions(params.id));
    },
    component: RouteComponent,
    head: ({ loaderData }) => {
        return {
            meta: [
                {
                    titleElement: {
                        title: loaderData?.identno,
                        mini: false,
                        info: i18n.t("apparatus:index.title", "Prüfmittel"),
                        miniHeadline: i18n.t("apparatus:index.title", "Prüfmittel"),
                    },
                },
            ],
        };
    },
});

function RouteComponent() {
    return (
        <LocationProvider>
            <ApparatusViewProvider>
                <Suspense fallback={<ApparatusSuspense />}>
                    <ApparatusView />
                </Suspense>
            </ApparatusViewProvider>
        </LocationProvider>
    );
}
