/** @format */

import CostcentersIndex from "@screen/authenticated/Costcenters/CostcentersIndex/CostcentersIndex";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_defaultView/costcenters/")({
    component: CostcentersIndex,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("costcenter:titles", "Kostenstellen"),
                    },
                },
            ],
        };
    },
});
