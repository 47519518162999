/** @format */

import {
    Box,
    Text,
    Input,
    Flex,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from "@chakra-ui/react";
import SingleFileViewList from "@components/FileUploadList/SingleFileView/SingleFileView";
import Form from "@components/Form/Form";
import { useFileUploadContext } from "@context/FileUploadProvider";
import { useGetFileSize } from "@hook/useGetFileSize";
import { SelectType, allAllowedFilesType } from "@services/FileTypes";
import defaultColors from "@theme/defaultColors";
import useDefaultColors from "@theme/useDefaultColors";
import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FiFilePlus, FiFile } from "react-icons/fi";

import FileSlideView from "./FileSlideView/FileSlideView";
import SingleFileView from "./SingleFileView/SingleFileView";
import "./FileUploadView.scss";

const max = Number(import.meta.env.VITE_UPLOAD_MAX_BULK_SIZE);

interface FileUploadViewProps {
    onFileValidate?: (validate: boolean) => void;
    selectTypes?: SelectType[];
    maxFiles?: number;
}

const FileUploadView: React.FC<FileUploadViewProps> = ({
    onFileValidate = () => null,
    selectTypes,
    maxFiles = 10,
}) => {
    const [t] = useTranslation();

    const scrollRef = useRef<HTMLDivElement>(null);

    const [dragActive, setDragActive] = useState<boolean>(false);

    const { handleRemoveUploadFile, handleFileSelect, files, selectTypesList } =
        useFileUploadContext();

    const { getSize } = useGetFileSize();

    const { redTextColor } = useDefaultColors();

    const [hoverState, setHoverState] = useState<boolean>(false);

    const textDefault = useColorModeValue(defaultColors.grey[40], defaultColors.grey[30]);

    const handleDrag = (e: React.DragEvent<HTMLFormElement | HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                handleFileSelect(e.dataTransfer.files, scrollRef);
            }
        },
        [handleFileSelect]
    );

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            if (e.target.files && e.target.files[0]) {
                handleFileSelect(e.target.files, scrollRef);
            }
        },
        [handleFileSelect]
    );

    const fileSize = useMemo(() => {
        let size = 0;
        if (!files) return size;

        files.map((value) => (size += value.file?.size ? value.file?.size : 0));

        return size;
    }, [files]);

    const fileSizeMax = fileSize >= max;

    useEffect(() => {
        if (fileSizeMax) {
            onFileValidate(true);
        } else {
            onFileValidate(false);
        }
    }, [fileSizeMax, onFileValidate]);

    const selectedFileIndex = files.findIndex(
        (value) => value.selected === true && value.state !== "deleted"
    );

    const selectedFile = files[selectedFileIndex];

    const fileUploadLimit = files.filter(
        (value) => value.state === "progress" || value.state === "uploaded"
    ).length;

    const defaultFile = !!files.find((value) => value.state === "default");

    const filesList = useMemo(() => files.filter((value) => value.state !== "deleted"), [files]);

    return (
        <Flex position="relative">
            <Box width="100%" p={4}>
                <Flex
                    ref={scrollRef}
                    mb={4}
                    flexDirection="column"
                    minHeight="100px"
                    maxHeight="225px"
                    overflowY="auto"
                    className="selected-files-list"
                >
                    {filesList && filesList.length ? (
                        <>
                            {files.map((file, index) => {
                                if (file.state === "default") {
                                    return (
                                        <React.Fragment key={`${file.id}-defaultFile`}>
                                            <SingleFileViewList
                                                index={index}
                                                selected={selectedFileIndex === index}
                                                removeSelectedImg={handleRemoveUploadFile}
                                                file={file.fileDefault}
                                                base={file.base}
                                                viewEdit
                                                typeName={
                                                    selectTypesList?.find(
                                                        (value) =>
                                                            value.key ===
                                                            file.fileDefault?.uploadtype_id
                                                    )?.name
                                                }
                                            />
                                        </React.Fragment>
                                    );
                                } else if (file.state !== "deleted") {
                                    return (
                                        <React.Fragment key={index}>
                                            <SingleFileView
                                                index={index}
                                                defaultFile={defaultFile}
                                                type={file.type}
                                                url={file.url}
                                                typeName={file.fileType?.name}
                                                file={file.file}
                                                selected={file.selected}
                                            />
                                        </React.Fragment>
                                    );
                                }
                            })}
                        </>
                    ) : (
                        <Flex justifyContent="center" alignItems="center" mb={2}>
                            <Text color={textDefault}>
                                {t(
                                    "global:upload.info",
                                    "So laden Sie Dateien hoch: ziehen Sie dafür die Dateien in das Fenster oder klicken Sie in das Fenster.",
                                    { ns: "global" }
                                )}
                            </Text>
                        </Flex>
                    )}
                </Flex>
                {fileSizeMax && (
                    <Flex my={5}>
                        <Alert status="error">
                            <AlertIcon />
                            <Trans
                                i18nKey="upload.error.maxSize"
                                values={{ size: getSize(fileSize), max: getSize(max) }}
                            >
                                <AlertTitle>Achtung!</AlertTitle>
                                <AlertDescription>
                                    Sie überschreiten mit <i>{`{{ size }}`}</i> das Aktuelle
                                    Größenlimit mit <i>{`{{ max }}`}</i>
                                </AlertDescription>
                            </Trans>
                        </Alert>
                    </Flex>
                )}

                {files && fileUploadLimit > 10 && (
                    <Flex my={5}>
                        <Alert status="error">
                            <AlertIcon />
                            <Trans i18nKey="upload.error.maxFiles" values={{ maxFiles }}>
                                <AlertTitle>Achtung!</AlertTitle>
                                <AlertDescription>
                                    {`Sie können nur {{maxFiles}}. Dateien gleichzeitig hochladen.`}
                                </AlertDescription>
                            </Trans>
                        </Alert>
                    </Flex>
                )}

                <Flex justifyContent="flex-end">
                    <Text
                        fontSize={15}
                        color={files && fileUploadLimit > maxFiles ? redTextColor : textDefault}
                    >
                        {fileUploadLimit}/{maxFiles}
                    </Text>
                </Flex>
                <Box position="relative" height="200px">
                    <Form
                        position="relative"
                        height="200px"
                        maxWidth="100%"
                        textAlign="center"
                        onDragEnter={(e) => handleDrag(e)}
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <Input
                            id="upload-drag-files"
                            display="none"
                            accept={allAllowedFilesType.toString()}
                            className="select-image"
                            type="file"
                            multiple
                            onChange={(e) => handleChange(e)}
                        />
                        <label
                            htmlFor="upload-drag-files"
                            className={`upload-drag-zone ${dragActive ? "drag-active" : ""}`}
                            style={{
                                backgroundColor: useColorModeValue(
                                    hoverState ? defaultColors.grey[10] : defaultColors.white,
                                    defaultColors.grey[90]
                                ),
                            }}
                            onMouseEnter={() => setHoverState(() => true)}
                            onMouseLeave={() => setHoverState(() => false)}
                        >
                            {dragActive ? (
                                <FiFilePlus size={80} color={defaultColors.blue[30]} />
                            ) : (
                                <FiFile size={80} color={defaultColors.blue[30]} />
                            )}
                        </label>
                        {dragActive && (
                            <div
                                id="drag-file-element"
                                onDragEnter={(e) => handleDrag(e)}
                                onDragLeave={(e) => handleDrag(e)}
                                onDragOver={(e) => handleDrag(e)}
                                onDrop={(e) => handleDrop(e)}
                            />
                        )}
                    </Form>
                </Box>
            </Box>
            {selectedFile ? (
                <Box
                    width={{ base: "200px", lg: "300px" }}
                    minW={{ base: "200px", lg: "300px" }}
                    maxW={{ base: "200px", lg: "300px" }}
                    display={{ base: "none", md: "block", lg: "none", xl: "block" }}
                    borderLeft="2px"
                    borderLeftColor="inherit"
                    p={4}
                >
                    <Flex justifyContent="center" alignItems="center">
                        {selectedFile.state === "default" && selectedFile.fileDefault ? (
                            <FileSlideView
                                name={selectedFile.fileDefault.original_name}
                                size={selectedFile.fileDefault.file_size}
                                lastModifiedDate={selectedFile.fileDefault.created}
                                url={selectedFile.fileDefault.url}
                                fileType={selectedFile.type}
                            />
                        ) : (
                            <>
                                {selectedFile && (
                                    <FileSlideView
                                        selectTypes={selectTypes}
                                        name={selectedFile.file?.name}
                                        size={selectedFile.file?.size}
                                        lastModified={selectedFile.file?.lastModified}
                                        url={selectedFile.url}
                                        fileType={selectedFile.type}
                                        defaultSelectType={selectedFile.fileType}
                                    />
                                )}
                            </>
                        )}
                    </Flex>
                </Box>
            ) : null}
        </Flex>
    );
};

export default FileUploadView;
