/** @format */

import { Flex, FormControl, FormLabel, IconButton, Text } from "@chakra-ui/react";
import AtomSaveButtonGroup from "@components/AtomSaveButtonGroup/AtomSaveButtonGroup";
import CustomSlider from "@components/CustomSlider/CustomSlider";
import defaultColors from "@theme/defaultColors";
import useDefaultColors from "@theme/useDefaultColors";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiEdit3 } from "react-icons/fi";

interface AdditionalSettingsProps {
    name: string;
    inputName: string;
    sliderChange: (value: number) => void;
    value: number;
    changed: boolean;
    handleSave: () => void;
    handleReset: () => void;
    odd?: boolean;
}

const AdditionalSliderSettings: React.FC<AdditionalSettingsProps> = ({
    name,
    inputName,
    sliderChange,
    value,
    changed,
    handleSave,
    handleReset,
    odd = false,
}) => {
    const [t] = useTranslation();

    const { defaultBoxBgColor, defaultTextColor } = useDefaultColors();

    const [change, setChange] = useState<boolean>(false);

    return (
        <Flex alignItems="flex-end" p={2} bg={odd ? defaultBoxBgColor : "unset"}>
            {change ? (
                <>
                    <FormControl>
                        <FormLabel htmlFor="input-name" className="form-label">
                            {name}
                        </FormLabel>
                        <Flex>
                            <CustomSlider
                                aria-label="slider-escalater-interval"
                                onChange={(value) => sliderChange(value)}
                                value={value}
                                min={1}
                                max={12}
                                step={1}
                                mr={5}
                            />
                        </Flex>
                    </FormControl>
                    <AtomSaveButtonGroup
                        isAttached
                        aria-label={`save-btn-${inputName}`}
                        hasChanged={changed}
                        onSubmit={() => {
                            setChange(false);
                            handleSave();
                        }}
                        onReset={() => {
                            setChange(false);
                            handleReset();
                        }}
                        ml={2}
                    />
                </>
            ) : (
                <Flex flexDirection="column" w="full">
                    <FormLabel htmlFor="input-name" className="form-label">
                        {name}
                    </FormLabel>
                    <Flex
                        flexDirection="row"
                        w="full"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Text color={defaultTextColor}>
                            {value}{" "}
                            {value > 1
                                ? t("global:week_other", "Wochen", { count: 0 })
                                : t("global:week_one", "Woche", { count: 1 })}
                        </Text>
                        <IconButton
                            icon={<FiEdit3 color={defaultColors.blue[60]} />}
                            size="sm"
                            aria-label={`inputName-${inputName}`}
                            variant="ghost"
                            onClick={() => setChange((value) => !value)}
                        />
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};

export default AdditionalSliderSettings;
