/** @format */

import { CompanyAttribute } from "@api/ext/AttributeAdapter";
import { CompanyAddModel, CompanyModel } from "@api/ext/CompanyAdapter";
import {
    Grid,
    Flex,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    useToast,
    AvatarGroup,
    Avatar,
} from "@chakra-ui/react";
import AvatarImageUpload from "@components/AvatarImageUpload/AvatarImageUpload";
import FlexShadow from "@components/FlexShadow/FlexShadow";
import ModalView from "@components/Modal/ModalView";
import { defaultGap } from "@constants/styles";
import { getAttributes } from "@services/Models/AttributeService";
import { saveCompany } from "@services/Models/CompanyService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import defaultColors from "@theme/defaultColors";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "@tanstack/react-router";

import CompanyAdditionalSettings from "./CompanyAdditionalSettings/CompanyAdditionalSettings";
import MandantorCompanyLocationBox from "../../MandantorCompanyLocationBox/MandantorCompanyLocationBox";

interface CompanyFormProps {
    defaultData: CompanyAddModel;
    isEdit?: boolean;
}

let timeoutId: ReturnType<typeof setTimeout>;

const CompanyForm: React.FC<CompanyFormProps> = ({ defaultData, isEdit = false }) => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const toast = useToast();
    const queryClient = useQueryClient();

    const [imageChanged, setImageChanged] = useState<boolean>(false);
    const [imageView, setImageView] = useState<File>();
    const [imageUrl, setImageUrl] = useState<string>(defaultData?.uploads?.[0]?.url ?? "");

    const [avatarUpdateName, setAvatarUpdateName] = useState<string>();

    const [companyData, setCompanyData] = useState<CompanyAddModel>(() => {
        const { id = undefined, name, address, zip, phone, city, email } = defaultData;

        return {
            ...(id && { id }),
            name,
            address,
            zip,
            phone,
            city,
            email,
        } as CompanyAddModel;
    });

    const { data: companyAttributes } = useQuery({
        queryKey: ["companies", "attributes", defaultData.id],

        queryFn: () =>
            !defaultData.id
                ? undefined
                : getAttributes<CompanyAttribute>("company", defaultData.id),

        enabled: !!defaultData.id,
    });

    const canSubmit = useMemo(() => {
        const isSameName = companyData.name === defaultData.name;
        const isSameAddress = companyData.address === defaultData.address;
        const isSameZip = companyData.zip === defaultData.zip;
        const isSameCity = companyData.city === defaultData.city;
        const isSamePhone = companyData.phone === defaultData.phone;
        const isSameEmail = companyData.email === defaultData.email;

        return !(
            isSameName &&
            isSameAddress &&
            isSameZip &&
            isSameCity &&
            isSamePhone &&
            isSameEmail &&
            !imageChanged
        );
    }, [imageChanged, defaultData, companyData]);

    const { mutate: submitCompany } = useMutation({
        mutationFn: saveCompany,
        onSuccess: (data) => {
            if (data) {
                toast({
                    title: t("company.change.success.title", "Gespeichert"),
                    description: t("company.change.success.text", "Daten wurden aktualisiert"),
                    status: "success",
                    duration: 2000,
                });

                navigate({ to: "/settings/mandantor" });

                return;
            }

            toast({
                title: t("company.change.failed.title", "Fehlgeschlagen"),
                description: t("company.change.failed.text", "Daten wurden nicht aktualisiert!"),
                status: "error",
                duration: 2000,
            });
        },
        onSettled: () => {
            void queryClient.invalidateQueries({
                queryKey: ["company", "edit"],
            });
            void queryClient.invalidateQueries({
                queryKey: ["mandantor", "edit"],
            });
        },
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    useEffect(() => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setAvatarUpdateName((oldName) => {
                if (companyData.name !== undefined && companyData.name !== oldName) {
                    return companyData.name;
                }

                return oldName;
            });
        }, 250);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [companyData.name]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        submitCompany({ company: companyData, image: imageView });
    };

    useEffect(() => {
        if (imageView) {
            const reader = new FileReader();

            reader.onload = (result) => {
                setImageUrl(result?.target?.result as string);
            };

            reader.readAsDataURL(imageView);
        }
    }, [imageView, defaultData?.uploads]);

    return (
        <ModalView
            title={
                isEdit
                    ? t("companies.edit.title", "Firma bearbeiten")
                    : t("companies.add.title", "Firma hinzufügen")
            }
            size="6xl"
            scrollBehavior="inside"
        >
            <form onSubmit={(e) => handleSubmit(e)}>
                <Grid
                    py={5}
                    gap={defaultGap}
                    templateColumns={{
                        sm: "1fr",
                        lg: "1fr 1fr",
                    }}
                >
                    <Grid gap={defaultGap}>
                        <FlexShadow>
                            <Flex flexDirection="column" alignItems="center" py={20} h="full">
                                <AvatarImageUpload
                                    avatarName={avatarUpdateName ?? ""}
                                    imageChanged={(value) => {
                                        setImageChanged(true);
                                        setImageView(
                                            value.target.files ? value.target.files[0] : undefined
                                        );
                                    }}
                                    imageUrl={imageUrl}
                                    company
                                />
                                <Heading as="h3" size="lg">{`${companyData.name ?? "-"}`}</Heading>
                            </Flex>
                        </FlexShadow>

                        {isEdit && (
                            <FlexShadow>
                                <Heading as="h3" size="lg" fontWeight="base" mb={5}>
                                    {t("companies.edit.users", "Benutzer")}
                                </Heading>
                                <AvatarGroup size="sm" max={10}>
                                    {defaultData.users ? (
                                        defaultData.users.map((user, index) => (
                                            <Avatar
                                                key={`user-avatar-${index}`}
                                                name={`${user.name} ${user.surname}`}
                                                src={user.img && user.img.url ? user.img.url : ""}
                                            />
                                        ))
                                    ) : (
                                        <Avatar bg={defaultColors.grey[50]} />
                                    )}
                                </AvatarGroup>
                            </FlexShadow>
                        )}
                    </Grid>

                    <Grid gap={defaultGap}>
                        <FlexShadow>
                            <Flex justifyContent="space-between">
                                <Heading as="h3" size="lg" fontWeight="base" mb={5}>
                                    {t("company.view.title", "Firma")}
                                </Heading>
                            </Flex>

                            <FormControl mt={3} isRequired>
                                <FormLabel>{t("company.name.label", "Firmenname")}</FormLabel>
                                <Input
                                    name="name"
                                    placeholder={t("company.name.placeholder", "Musterfirma GmbH")}
                                    onChange={handleInputChange}
                                    value={companyData.name}
                                />
                            </FormControl>

                            <Flex gap={5}>
                                <Flex flexDirection="column" w="full">
                                    <FormControl w="100%" mt={3} isRequired>
                                        <FormLabel>
                                            {t("company.address.label", "Adresse")}
                                        </FormLabel>
                                        <Input
                                            name="address"
                                            placeholder={t(
                                                "company.address.placeholder",
                                                "Ihre Firmenadresse"
                                            )}
                                            onChange={handleInputChange}
                                            value={companyData.address}
                                        />
                                    </FormControl>

                                    <Flex gap={5}>
                                        <FormControl mt={3} w="auto" isRequired>
                                            <FormLabel>{t("company.zip.label", "PLZ")}</FormLabel>
                                            <Input
                                                name="zip"
                                                placeholder={t("company.zip.placeholder", "00000")}
                                                onChange={handleInputChange}
                                                value={companyData.zip}
                                            />
                                        </FormControl>

                                        <FormControl mt={3} isRequired>
                                            <FormLabel>
                                                {t("company.city.label", "Stadt")}
                                            </FormLabel>
                                            <Input
                                                name="city"
                                                placeholder={t(
                                                    "company.city.placeholder",
                                                    "Ihre Stadt"
                                                )}
                                                onChange={handleInputChange}
                                                value={companyData.city}
                                            />
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                {isEdit && (
                                    <Flex
                                        position="relative"
                                        overflow="hidden"
                                        h="130px"
                                        w="250px"
                                        mt={10}
                                        rounded="md"
                                    >
                                        <MandantorCompanyLocationBox />
                                    </Flex>
                                )}
                            </Flex>

                            <FormControl mt={9} isRequired>
                                <FormLabel>{t("company.email.label", "E-Mail")}</FormLabel>
                                <Input
                                    name="email"
                                    placeholder={t(
                                        "company.email.placeholder",
                                        "Ihre Firmen E-Mail"
                                    )}
                                    onChange={handleInputChange}
                                    value={companyData.email}
                                />
                            </FormControl>

                            <FormControl mt={6} mb={10}>
                                <FormLabel>{t("company.phone.label", "Telefonnummer")}</FormLabel>
                                <Input
                                    name="phone"
                                    placeholder={t(
                                        "company.phone.placeholder",
                                        "+49 1111 11111111"
                                    )}
                                    onChange={handleInputChange}
                                    value={companyData.phone}
                                />
                            </FormControl>

                            <Flex mt="auto" justifyContent="flex-end">
                                <Button variant="solid" type="submit" isDisabled={!canSubmit}>
                                    {t("user:save", "Speichern")}
                                </Button>
                            </Flex>
                        </FlexShadow>

                        {defaultData.id && companyAttributes && (
                            <FlexShadow>
                                <CompanyAdditionalSettings
                                    company={defaultData as CompanyModel}
                                    companyAttributes={companyAttributes}
                                />
                            </FlexShadow>
                        )}
                    </Grid>
                </Grid>
            </form>
        </ModalView>
    );
};

export default CompanyForm;
