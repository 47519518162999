/** @format */

import InspectionsIndex from "@screen/authenticated/Inspections/InspectionsIndex/InspectionsIndex";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_defaultView/inspections/")({
    component: InspectionsIndex,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("reports.title", "Prüfberichte"),
                    },
                },
            ],
        };
    },
});
