/** @format */

import { useColorModeValue } from "@chakra-ui/react";

import defaultColors from "./defaultColors";

const useDefaultColors = () => {
    const defaultHeadlineTextColor = useColorModeValue("#000", "#fff");
    const defaultTextColor = useColorModeValue(defaultColors.grey[90], defaultColors.grey[10]);
    const lightTextColor = useColorModeValue(defaultColors.grey[70], defaultColors.grey[30]);

    const defaultBgColor = useColorModeValue(defaultColors.white, defaultColors.grey[100]);
    const defaultBoxBgColor = useColorModeValue(defaultColors.grey[10], defaultColors.grey[90]);
    const inverseBoxBgColor = useColorModeValue(defaultColors.grey[10], defaultColors.grey[100]);
    const mutedBoxBgColor = useColorModeValue(defaultColors.grey[20], defaultColors.grey[80]);

    const blueTextColor = useColorModeValue(defaultColors.blue[100], defaultColors.blue[30]);
    const blueLightTextColor = useColorModeValue(defaultColors.blue[60], defaultColors.blue[60]);

    const blueBoxBgColor = useColorModeValue(defaultColors.blue[60], defaultColors.blue[30]);
    const blueLightBoxBgColor = useColorModeValue(defaultColors.blue[30], defaultColors.blue[60]);

    const redTextColor = useColorModeValue(defaultColors.red[100], defaultColors.red[60]);
    const redBoxBgColor = useColorModeValue(
        defaultColors.betterRed[100],
        defaultColors.betterRed[60]
    );
    const redLightBoxBgColor = useColorModeValue(
        defaultColors.betterRed[100],
        defaultColors.betterRed[60]
    );

    const yellowTextColor = useColorModeValue(defaultColors.yellow[100], defaultColors.yellow[50]);

    const orangeTextColor = useColorModeValue(defaultColors.orange[100], defaultColors.orange[60]);
    const orangeBoxBgColor = useColorModeValue(defaultColors.orange[100], defaultColors.orange[60]);
    const orangeLightBoxBgColor = useColorModeValue(
        defaultColors.orange[100],
        defaultColors.orange[60]
    );

    const statisticBoxBgColor = useColorModeValue(defaultColors.grey[80], defaultColors.grey[20]);

    const betterRedTextColor = useColorModeValue(
        defaultColors.betterRed[100],
        defaultColors.betterRed[60]
    );

    const greenTextColor = useColorModeValue(defaultColors.green[100], defaultColors.green[60]);

    return {
        defaultTextColor,
        lightTextColor,

        defaultHeadlineTextColor,

        defaultBgColor,
        defaultBoxBgColor,
        inverseBoxBgColor,
        mutedBoxBgColor,

        blueTextColor,
        blueLightTextColor,

        blueBoxBgColor,
        blueLightBoxBgColor,

        redBoxBgColor,
        redLightBoxBgColor,

        redTextColor,

        yellowTextColor,

        orangeTextColor,
        orangeBoxBgColor,
        orangeLightBoxBgColor,

        betterRedTextColor,

        greenTextColor,

        statisticBoxBgColor,
    };
};

export default useDefaultColors;
