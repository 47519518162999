/** @format */

import { Flex, Image, Link, Text } from "@chakra-ui/react";
import FlexShadow from "@components/FlexShadow/FlexShadow";
import ModalView from "@components/Modal/ModalView";
import { defaultGap } from "@constants/styles";
import React from "react";
import { Trans } from "react-i18next";

const AppInfo: React.FC = () => {
    return (
        <ModalView size="xl">
            <Flex mt={10} flexDirection="column">
                <Flex flexDirection="row" w="full" gap={defaultGap} mb={defaultGap}>
                    <Flex
                        w="50%"
                        as={Link}
                        href="https://apps.apple.com/de/app/memida/id6479538474"
                        target="_blank"
                    >
                        <Image objectFit="contain" src="/img/badge-apple-app.png" />
                    </Flex>
                    <Flex
                        w="50%"
                        as={Link}
                        href="https://play.google.com/store/apps/details?id=de.memida.appkit.andorid"
                        target="_blank"
                    >
                        <Image objectFit="contain" src="/img/badge-google-app.png" />
                    </Flex>
                </Flex>
                <FlexShadow>
                    <Trans i18nKey="global:appinfo">
                        <Text>
                            Die Memida App ist jetzt im <strong>Play Store</strong> und{" "}
                            <strong>App Store</strong> verfügbar! 📱✨ Lade sie dir herunter und
                            entdecke alle Features.
                        </Text>
                    </Trans>
                </FlexShadow>
            </Flex>
        </ModalView>
    );
};

export default AppInfo;
