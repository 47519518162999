/** @format */

import SuspenseLoad from "@components/SuspenseLoad/SuspenseLoad";
import { CustomFieldProvider } from "@context/CustomFieldProvider";
import { InspectionUploadProvider } from "@context/InspectionUploadProvider";
import { LocationProvider } from "@context/LocationProvider";
import ApparatusesAdd from "@screen/authenticated/Apparatuses/ApparatusesAdd/ApparatusesAdd";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";
import { Suspense } from "react";

export const Route = createFileRoute("/_auth/_defaultView/apparatuses/add")({
    component: RouteComponent,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("apparatus:add.title", "Prüfmittel"),
                    },
                },
            ],
        };
    },
});

function RouteComponent() {
    return (
        <CustomFieldProvider customFieldKey="Apparatuses">
            <InspectionUploadProvider>
                <LocationProvider>
                    <Suspense fallback={<SuspenseLoad />}>
                        <ApparatusesAdd />
                    </Suspense>
                </LocationProvider>
            </InspectionUploadProvider>
        </CustomFieldProvider>
    );
}
