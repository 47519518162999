/** @format */

import { ChakraComponent, Flex, FlexProps } from "@chakra-ui/react";
import useDefaultColors from "@theme/useDefaultColors";
import React from "react";

type FormComponent = ChakraComponent<"div", object>;

const FlexShadow: React.FC<FlexProps> = React.forwardRef((props, ref) => {
    const { defaultBgColor } = useDefaultColors();
    const { p = { base: 2, md: 3, "2xl": 5 } } = props;

    return (
        <Flex
            ref={ref}
            bg={defaultBgColor}
            rounded="md"
            boxShadow="md"
            flexDirection="column"
            p={p}
            {...props}
        />
    );
});

export default FlexShadow as FormComponent;
