/** @format */

import SuspenseLoad from "@components/SuspenseLoad/SuspenseLoad";
import { CustomFieldProvider } from "@context/CustomFieldProvider";
import TestplansEdit from "@screen/authenticated/Testplans/TestplansEdit/TestplansEdit";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";
import { Suspense } from "react";

export const Route = createFileRoute("/_auth/_defaultView/testplans/edit/$id")({
    component: () => (
        <Suspense fallback={<SuspenseLoad />}>
            <CustomFieldProvider customFieldKey="Testplans">
                <TestplansEdit />
            </CustomFieldProvider>
        </Suspense>
    ),
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("testplan.add.title", "Prüfanweisung"),
                    },
                },
            ],
        };
    },
});
