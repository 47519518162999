/** @format */

import SignupDone from "@screen/unauthenticated/Signup/SignupDone/SignupDone";
import { createFileRoute } from "@tanstack/react-router";
import { fallback, zodValidator } from "@tanstack/zod-adapter";
import { z } from "zod";

const emailSearchSchema = z.object({
    email: fallback(z.string().optional(), ""),
});

export const Route = createFileRoute("/_unauth/signup-done")({
    validateSearch: zodValidator(emailSearchSchema),
    component: SignupDone,
});
