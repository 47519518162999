/** @format */

import { ChangeSubscriptionContextProvider } from "@context/ChangeSubscriptionProvider";
import Subscription from "@screen/authenticated/Mandantor/Subscription/Subscription";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
    "/_auth/_smallView/_settingsLayout/settings/subscription/info"
)({
    component: () => (
        <ChangeSubscriptionContextProvider>
            <Subscription />
        </ChangeSubscriptionContextProvider>
    ),
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("global:mandantorSubscription", "Konto"),
                        mini: true,
                        miniHeadline: i18n.t("global:mandantor", "Unternehmen"),
                    },
                },
            ],
        };
    },
});
