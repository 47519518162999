/** @format */

import SuspenseLoad from "@components/SuspenseLoad/SuspenseLoad";
import i18n from "@services/i18n";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/(lang)/de/$")({
    beforeLoad: ({ context, location }) => {
        const cleanedUrl = location.pathname.replace(/^\/(en|de)(\/|$)/, "/");

        const match = location.pathname.match(/^\/(en|de)(\/.*)?$/);

        if (match) {
            const auth = context.auth;

            if ((!auth || !auth.authentication) && i18n.resolvedLanguage !== match[1]) {
                void i18n.changeLanguage(match[1]);
            }
        }

        // eslint-disable-next-line no-throw-literal
        throw redirect({
            to: cleanedUrl,
            search: {
                ...location.search,
                lang: i18n.resolvedLanguage,
            },
        });
    },
    component: SuspenseLoad,
});
