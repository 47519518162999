/** @format */

import { apparatusAdapter } from "@api/ApiRequest";
import { ApparatusAddModel, ExportTypes } from "@api/ext/ApparatusAdapter";
import { PaginationRequest, EmptyExtraData } from "@components/Table/TableBuilder";
import { PaginatorParams } from "@hook/usePaginationParams";

import { getPaginationParams } from "./PaginationUtils";

interface ExportApparatusesParams {
    ids: number[];
    search?: string;
    selectedFields: string[];
    selectedHeaders: string[];
    type?: ExportTypes;
    paginator?: PaginatorParams;
}

export const fetchApparatus = async (id?: number, draft = false) =>
    id ? await apparatusAdapter.view(id, draft) : undefined;

export const fetchApparatusesList = async (data: PaginationRequest<EmptyExtraData>) =>
    await apparatusAdapter.index(getPaginationParams(data));

export const fetchGetExportApparatusesList = async (data: ExportApparatusesParams) =>
    await apparatusAdapter.generateListExport(
        data.ids,
        data.selectedFields,
        data.selectedHeaders,
        data.type,
        data.paginator
    );

export const fetchFilteredApparatusesList = async (searchString?: string) =>
    await apparatusAdapter.getIndexList(searchString);

export const fetchIntervalIndex = async () => await apparatusAdapter.getIntervalIndex();

export const changeCostcenterOfApparatus = async ({
    apparatusId,
    costcenterId,
}: {
    apparatusId: number | number[];
    costcenterId: number;
}) => await apparatusAdapter.changeCostcenter(apparatusId, costcenterId);

export const addApparatus = async (postData: { apparatusAdd: ApparatusAddModel }) =>
    await apparatusAdapter.addApparatus(postData.apparatusAdd);

export const fetchApparatusDraft = async () => await apparatusAdapter.getDraftApparatus();

export const updateApparatus = async ({
    id,
    apparatusEdit,
}: {
    id: number;
    apparatusEdit: ApparatusAddModel;
}) => await apparatusAdapter.editApparatus(id, apparatusEdit);

export const removeDraft = async (id: number) => await apparatusAdapter.removeDraft(id);
