/** @format */

import { captureSentryException } from "@services/sentry";
import axios, {
    AxiosInstance,
    HeadersDefaults,
    AxiosRequestConfig,
    AxiosResponse,
    Canceler,
    AxiosError,
} from "axios";
import debugLib from "debug";
import merge from "lodash/merge";

import { CommonHeaderProperties } from "./auth/AuthAdapter";
import {
    RequestAdapterConfiguration,
    RequestAdapterInterface,
    RequestResponseType,
} from "./auth/RequestAdapterInterface";
import deepCloneAsync from "./auth/deepCloneAsync";
import { Connection } from "../services/Connection";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CancelerReceiver = (canceler: Canceler) => any;

const debug = debugLib("prima:core:requestAdapter");

export default class RequestAdapter implements RequestAdapterInterface<AxiosRequestConfig> {
    private defaultHeaders = {
        Accept: "application/json",
    };

    private requestConfig: AxiosRequestConfig = {
        baseURL: undefined,
        headers: {
            ...this.defaultHeaders,
        },
    };

    private readonly request: AxiosInstance;

    constructor() {
        this.request = axios.create({
            withCredentials: true,
            headers: this.defaultHeaders,
        });
    }

    /**
     * Set base url for requests
     *
     * @param base Base url (eg. https://api.quadio.de)
     * @return {void}
     */
    public setBaseUrl(base: string): void {
        this.request.defaults.baseURL = base;

        debug("new request baseUrl: ", this.request.defaults.baseURL);
    }

    /**
     * Update Default Request Config
     *
     * PLEASE READ: keep use of this function to the absolute minimum.
     * Options that are set with this method apply to ALL requests made.
     * This method is therefore meant to set things that are commonly needed - as API-Keys.
     *
     * @param options default options to add
     */
    public updateDefaultRequestHeaders(options: unknown): void {
        const defaultHeaders: unknown = this.request.defaults.headers;

        this.request.defaults.headers = merge(defaultHeaders, options) as HeadersDefaults;

        debug("new request settings: ", this.request.defaults.headers);
    }

    /**
     * Get a copy of default request options
     */
    public async getDefaultRequestHeaders(): Promise<CommonHeaderProperties> {
        return deepCloneAsync(this.request.defaults.headers);
    }

    protected prepareConfig(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        originalConfig?: RequestAdapterConfiguration<AxiosRequestConfig>
    ): RequestAdapterConfiguration<AxiosRequestConfig> {
        if (!originalConfig) {
            return {};
        }

        const config: AxiosRequestConfig = {
            ...this.requestConfig,
            ...originalConfig.config,
        };

        if (originalConfig.params) {
            const params: { [key: string]: string } = {};

            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            originalConfig.config &&
                originalConfig.config.params &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                Object.entries(originalConfig.config.params)
                    .filter(([, value]) => !!value)
                    .forEach(([param, value]) => {
                        params[param] = value as string;
                    });

            Object.entries(originalConfig.params)
                .filter(([, value]) => !!value)
                .forEach(([param, value]) => {
                    params[param] = value as string;
                });

            config.params = params;
        }

        if (originalConfig.headers) {
            const headers: { [key: string]: string } = {
                ...(this.requestConfig.headers as { [key: string]: string }),
            };

            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            originalConfig.config &&
                originalConfig.config.headers &&
                Object.entries(originalConfig.config.headers).forEach(([header, value]) => {
                    headers[header] = value as string;
                });

            Object.entries(originalConfig.headers).forEach(([header, value]) => {
                headers[header] = value;
            });

            config.headers = headers;
        }

        return config as RequestAdapterConfiguration<AxiosRequestConfig>;
    }

    protected async extractResponseData<D>(
        response: Promise<AxiosResponse<D>>
    ): Promise<RequestResponseType<D>> {
        let result = {
            headers: {},
            data: {} as D,
            status: 200,
            statusText: "",
        } as RequestResponseType<D>;

        await response
            .then(({ headers, data, status, statusText }) => {
                result = {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    headers,
                    data,
                    status,
                    statusText,
                };
                Connection.setReachable(true);
            })
            .catch((error: AxiosError) => {
                captureSentryException(error);

                if (error.isAxiosError) {
                    result.status = 400;
                }

                // skip 404 delete errors
                if (error.code === "ERR_NETWORK" && error.config.method === "delete") {
                    Connection.setReachable(true);
                } else {
                    Connection.setReachable(false);
                }
            });

        return result;
    }

    public get<D>(
        action: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        config?: RequestAdapterConfiguration<AxiosRequestConfig<any>>
    ): Promise<RequestResponseType<D>> {
        config = this.prepareConfig(config);

        return this.extractResponseData(this.request.get(action, config));
    }

    public post<D>(
        action: string,
        data?: D,
        config?: RequestAdapterConfiguration<AxiosRequestConfig>
    ): Promise<RequestResponseType<D>> {
        config = this.prepareConfig(config);

        return this.extractResponseData(this.request.post(action, data, config));
    }

    public put<D>(
        action: string,
        data?: D,
        config?: RequestAdapterConfiguration<AxiosRequestConfig>
    ): Promise<RequestResponseType<D>> {
        config = this.prepareConfig(config);

        return this.extractResponseData(this.request.put(action, data, config));
    }

    public patch<D>(
        action: string,
        data?: D,
        config?: RequestAdapterConfiguration<AxiosRequestConfig>
    ): Promise<RequestResponseType<D>> {
        config = this.prepareConfig(config);

        return this.extractResponseData(this.request.patch(action, data, config));
    }

    public delete<D>(
        action: string,
        config?: RequestAdapterConfiguration<AxiosRequestConfig>
    ): Promise<RequestResponseType<D>> {
        config = this.prepareConfig(config);

        return this.extractResponseData(this.request.delete(action, config));
    }
}
