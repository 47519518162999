/** @format */

import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_defaultView/")({
    head: () => {
        return { meta: [{ titleElement: { title: i18n.t("dashboard.title", "Dashboard") } }] };
    },
});
