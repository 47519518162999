/** @format */

import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import Navigation from "@components/Navigation/Navigation/Navigation";
import SuspenseLoad from "@components/SuspenseLoad/SuspenseLoad";
import AuthContext from "@context/AuthProvider";
import Onboarding from "@screen/authenticated/Onboarding/Onboarding";
import {
    Navigate,
    Outlet,
    createFileRoute,
    redirect,
    retainSearchParams,
    useRouter,
} from "@tanstack/react-router";
import defaultColors from "@theme/defaultColors";
import { Suspense, useContext, useEffect, useLayoutEffect, useMemo } from "react";
import { fallback, zodValidator } from "@tanstack/zod-adapter";
import { z } from "zod";
import { Settings } from "luxon";
import i18n from "@services/i18n";

import { modals } from "../router/ModalRoutes";
import { ChangeSubscriptionContextProvider } from "@context/ChangeSubscriptionProvider";
import SubscriptionNavigation from "@components/SubscriptionNavigation/SubscriptionNavigation";
import SpecialView from "@screen/authenticated/Superadmin/SpecialView";

import { isSelectedUnconfirmedRoute } from "@services/specialRoute";

const modalNames = modals.map((modal) => modal.name);

const modalSearchShema = z.object({
    modal: fallback(z.enum(modalNames as [string, ...string[]]).optional(), ""),
    extraId: fallback(z.union([z.string(), z.number()]).optional(), ""),
    size: fallback(z.number().optional(), 25),
    page: fallback(z.number().optional(), 0),
    filter: fallback(z.string().optional(), ""),
    redirect: z.string().optional(),
    lang: fallback(z.enum(["de", "en"] as [string, ...string[]]).optional(), ""),
});

export const Route = createFileRoute("/_auth")({
    validateSearch: zodValidator(modalSearchShema),
    search: {
        middlewares: [retainSearchParams(["lang"])],
    },
    beforeLoad: ({ context, location, matches, cause }) => {
        Settings.defaultLocale = i18n.resolvedLanguage;

        if (context.auth && context.auth.authentication === false) {
            throw redirect({
                to: "/login",
                search: {
                    redirect: location.href,
                },
            });
        }

        const auth = context.auth;

        if (auth && auth.user && auth.user.lang && i18n.resolvedLanguage !== auth.user.lang) {
            void i18n.changeLanguage(auth.user.lang);
        }

        const match = matches.find(
            (match) =>
                match.cause === "enter" &&
                cause !== "stay" &&
                (match.pathname === location.pathname || match.pathname === location.pathname + "/")
        );

        if (auth && !auth.user?.emailconfirmed && match) {
            let path = match?.fullPath;
            path = path && path.endsWith("/") ? (path.slice(0, -1) as "/") : path;

            if (!isSelectedUnconfirmedRoute(path)) {
                throw redirect({
                    to: "/unconfirmed",
                });
            }
        }

        if (
            auth &&
            auth.subscription &&
            !auth.subscription?.is_active &&
            auth.user &&
            auth.user.is_admin &&
            match
        ) {
            let path = match?.fullPath;
            path = path && path.endsWith("/") ? (path.slice(0, -1) as "/") : path;

            if (!isSelectedUnconfirmedRoute(path)) {
                throw redirect({
                    to: "/invalidSubscription",
                });
            }
        }

        return {
            ...context,
        };
    },
    pendingComponent: SuspenseLoad,
    component: AuthComponent,
});

let timeoutId: ReturnType<typeof setTimeout>;

function AuthComponent() {
    const { user, authentication, logout, subscription } = useContext(AuthContext);

    const router = useRouter();

    const search = Route.useSearch();

    const bodyBackgroundMain = useColorModeValue(defaultColors.grey[10], defaultColors.grey[100]);

    const ModalComp = useMemo(() => {
        if (search && search.modal) {
            const modal = modals.find((modal) => modal.name === search.modal);
            if (modal) {
                return modal.component as React.FC;
            }
        }
    }, [search, search?.modal]);

    useLayoutEffect(() => {
        if (user && user.lang && i18n.resolvedLanguage !== user.lang) {
            router.invalidate();
        }
    }, [i18n, user]);

    useLayoutEffect(() => {
        if (user && search.redirect) {
            router.history.push(search.redirect);
        }
    }, [user, search.redirect]);

    useEffect(() => {
        clearTimeout(timeoutId);

        if (!user && authentication) {
            timeoutId = setTimeout(() => {
                void logout();
            }, 3000);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [user, authentication, logout]);

    if (authentication === false && !user) {
        return <Navigate to="/login" />;
    }

    if (user && user.group_id === 95) {
        return (
            <Box m="auto">
                <SpecialView />
            </Box>
        );
    }

    if (subscription && !subscription?.is_active && user && user.is_admin) {
        return (
            <>
                {ModalComp && (
                    <Suspense>
                        <ModalComp />
                    </Suspense>
                )}
                <Box minH="100vh" bg={bodyBackgroundMain}>
                    <ChangeSubscriptionContextProvider>
                        <SubscriptionNavigation>
                            <Outlet />
                        </SubscriptionNavigation>
                    </ChangeSubscriptionContextProvider>
                </Box>
            </>
        );
    }

    return (
        <>
            {!user ? (
                <Flex
                    minH="100vh"
                    bg={bodyBackgroundMain}
                    justifyContent="center"
                    alignItems="center"
                >
                    <SuspenseLoad />
                </Flex>
            ) : (
                <>
                    {ModalComp && (
                        <Suspense>
                            <ModalComp />
                        </Suspense>
                    )}
                    <Box minH="100vh" bg={bodyBackgroundMain}>
                        {!user?.onboarding && <Onboarding show />}
                        <Box m="auto" bg={bodyBackgroundMain}>
                            <Navigation>
                                <Suspense fallback={<SuspenseLoad />}>
                                    <Outlet />
                                </Suspense>
                            </Navigation>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
}
