/** @format */

import { LocationProvider } from "@context/LocationProvider";
import MandantorView from "@screen/authenticated/Mandantor/MandantorView/MandantorView";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_smallView/_settingsLayout/settings/mandantor/")({
    component: () => (
        <LocationProvider>
            <MandantorView />
        </LocationProvider>
    ),
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("global:mandantor", "Unternehmen"),
                        mini: true,
                    },
                },
            ],
        };
    },
});
