/** @format */

import { apparatusLocationAdapter } from "@api/ApiRequest";
import { ApparatusLocationBaseModel } from "@api/ext/ApparatusLocationAdapter";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";

export interface TypesOption {
    label: string;
    value: string;
    geo?: string | null;
    isNew?: boolean;
}

const fetchDefaultLocation = async () => await apparatusLocationAdapter.getDefaultLocation();

const defaultValue: {
    setSelectPlacesOptions: React.Dispatch<React.SetStateAction<TypesOption | undefined>>;
    setSelectLocationOptions: React.Dispatch<React.SetStateAction<TypesOption | undefined>>;
    selectPlacesOptions?: TypesOption;
    selectLocationOptions?: TypesOption;
    defaultLocation?: null | string;
    updateIsLocation: React.Dispatch<React.SetStateAction<boolean>>;
    isLocation: boolean;
    setUpdateId: React.Dispatch<React.SetStateAction<number | undefined>>;
    setBaseInfo: React.Dispatch<React.SetStateAction<string>>;
    updateId?: number;
    baseInfo: string;
    handleDefaultUpdate: (base: ApparatusLocationBaseModel) => void;
} = {
    setSelectPlacesOptions: () => null,
    setSelectLocationOptions: () => null,
    updateIsLocation: () => null,
    isLocation: false,
    setUpdateId: () => null,
    setBaseInfo: () => null,
    baseInfo: "",
    handleDefaultUpdate: () => null,
};

const LocationContext = React.createContext(defaultValue);

interface LocationProviderProps {
    children?: React.ReactNode;
}

const LocationProvider: React.FC<LocationProviderProps> = ({ children }) => {
    const [selectLocationOptions, setSelectLocationOptions] = useState<TypesOption>();
    const [selectPlacesOptions, setSelectPlacesOptions] = useState<TypesOption>();

    const [updateId, setUpdateId] = useState<number | undefined>();
    const [baseInfo, setBaseInfo] = useState<string>("");

    const [locationIsSet, setLocationIsSet] = useState<boolean>(false);

    const { data: location, isLoading } = useQuery({
        queryKey: ["defaultLocation"],
        queryFn: fetchDefaultLocation,
    });

    const handleDefaultUpdate = useCallback((base: ApparatusLocationBaseModel) => {
        if (base.apparatuses_location && base.apparatuses_location?.id) {
            setSelectLocationOptions({
                label: base.apparatuses_location?.location,
                value: String(base.apparatuses_location?.id),
            });
        }

        if (base.apparatuses_locations_place) {
            setSelectPlacesOptions({
                label: base.apparatuses_locations_place?.location,
                value: String(base.apparatuses_locations_places_id),
                geo: base.apparatuses_locations_place.geoPoint,
            });
        }

        setUpdateId(base.id);
        setBaseInfo(base.info);
    }, []);

    const providerValue = {
        handleDefaultUpdate,
        selectLocationOptions,
        setSelectLocationOptions,
        setSelectPlacesOptions,
        selectPlacesOptions,
        defaultLocation: location,
        updateIsLocation: setLocationIsSet,
        setUpdateId,
        updateId,
        setBaseInfo,
        baseInfo,
        isLocation: locationIsSet,
    };

    if (isLoading) {
        return <></>;
    }

    return <LocationContext.Provider value={providerValue}>{children}</LocationContext.Provider>;
};

export { LocationProvider };
export default LocationContext;
