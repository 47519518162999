/** @format */

import Faq from "@screen/authenticated/FAQ/FaqView";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_smallView/faq")({
    component: Faq,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("faq.title", "FAQ"),
                        miniHeadline: i18n.t("faq.small", "Hilfe"),
                    },
                },
            ],
        };
    },
});
