/** @format */

import { apparatusAdapter, subscriptionAdapter } from "@api/ApiRequest";
import { AuthUserModel } from "@api/ext/GlobalUserAdapter";
import { SubscriptionInfo } from "@api/ext/SubscriptionAdapter";
import { useColorMode } from "@chakra-ui/react";
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";

import { useAuth } from "../hook/useAuth";

export const defaultContextValue: {
    authentication: boolean | null;
    user: AuthUserModel | undefined;
    isAdmin: boolean;
    isAdminOrUser: boolean;
    refetchUser: () => void;
    logout: () => void;
    validateAuth: () => void;
    connected: boolean;
    refetchSubscription: UseMutateFunction<SubscriptionInfo, Error, void, unknown>;
    removeSubscription: () => void;
    subscription?: SubscriptionInfo;
    apparatusCount: number;
    refetchApparatusCount: () => void;
} = {
    authentication: null,
    user: undefined,
    isAdmin: false,
    isAdminOrUser: false,
    refetchUser: () => null,
    logout: () => null,
    validateAuth: () => null,
    connected: true,
    refetchSubscription: () => null,
    removeSubscription: () => null,
    subscription: undefined,
    apparatusCount: 0,
    refetchApparatusCount: () => null,
};

const AuthContext = React.createContext(defaultContextValue);

interface AuthProviderProps {
    children?: React.ReactNode;
}

const fetchSubscription = async () => await subscriptionAdapter.info();
const fetchApparatusCount = async () => await apparatusAdapter.getCount();

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [authentication, user, refetchUser, doLogout, validateAuth] = useAuth();

    const queryClient = useQueryClient();

    const { setColorMode } = useColorMode();

    const [connected] = useState<boolean>(true);

    const isAdminOrUser = user ? user.is_admin || user.is_user : false;
    const isAdmin = user?.is_admin ? user.is_admin : false;

    const { data: apparatusCount = 0, refetch: refetchApparatusCount } = useQuery({
        queryKey: ["apparatusCount"],
        queryFn: fetchApparatusCount,
        enabled: false,
    });

    const {
        data: subscription = queryClient.getQueryData(["subscription"]),
        mutate: refetchSubscription,
    } = useMutation({
        mutationKey: ["subscription"],
        mutationFn: fetchSubscription,
        onSettled(data) {
            if (!data) {
                return;
            }

            queryClient.setQueryData(["subscription"], data);
        },
        onError(err: Error) {
            if (err.message === "invalid response") {
                setTimeout(() => {
                    void refetchSubscription();
                }, 5000);
            }
        },
    });

    useEffect(() => {
        if (authentication && user) {
            void setColorMode(
                user.users_attributes ? user.users_attributes.color_mode ?? "light" : "light"
            );

            if (!user.is_admin && !user.is_user) {
                return;
            }

            void refetchSubscription();
            void refetchApparatusCount();
        }
        // only update attributes and subscription when user changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const removeSubscription = useCallback(() => {
        queryClient.removeQueries({ queryKey: ["subscription"] });
    }, [queryClient]);

    const handleLogout = useCallback(() => {
        removeSubscription();
        void doLogout();
    }, [doLogout, removeSubscription]);

    // useEffect(() => {
    //     Connection.unsubscribe();
    //     setConnected(true);

    //     Connection.subscribe({
    //         connected: true,
    //         event(data: boolean) {
    //             setConnected((preState) => {
    //                 if (!preState) {
    //                     return preState;
    //                 }

    //                 return data;
    //             });
    //         },
    //     });

    //     return () => {
    //         Connection.unsubscribe();
    //     };
    // }, [locationRoute]);

    const providerValue = {
        authentication,
        user,
        isAdmin,
        isAdminOrUser,
        logout: handleLogout,
        refetchUser,
        validateAuth,
        connected,
        subscription,
        refetchSubscription,
        removeSubscription,
        apparatusCount,
        refetchApparatusCount,
    };

    return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
export default AuthContext;
