/** @format */

import {
    CompanyAttribute,
    CompanyAttributeIdentifier,
    CompanyAttributeUpdate,
} from "@api/ext/AttributeAdapter";
import { CompanyModel } from "@api/ext/CompanyAdapter";
import { Flex, Heading, useToast } from "@chakra-ui/react";
import AdditionalSliderSettings from "@components/AdditionalSliderSettings/AdditionalSliderSettings";
import AuthContext from "@context/AuthProvider";
import { updateCompanyAttribute } from "@services/Models/AttributeService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const CompanyAdditionalSettings = ({
    company,
    companyAttributes,
}: {
    company: CompanyModel;
    companyAttributes: CompanyAttribute;
}) => {
    const [t] = useTranslation();
    const toast = useToast();
    const queryClient = useQueryClient();
    const { user } = useContext(AuthContext);

    const [escalaterInterval, setEscalaterInterval] = useState<number>(
        companyAttributes ? parseInt(companyAttributes.escalater_interval, 10) : 0
    );
    const [reminderInterval, setReminderInterval] = useState<number>(
        companyAttributes ? parseInt(companyAttributes.reminder_interval, 10) : 0
    );
    const [reminderForecast, setReminderForecast] = useState<number>(
        companyAttributes ? parseInt(companyAttributes.reminder_forecast, 10) : 0
    );

    const { mutate } = useMutation({
        mutationFn: updateCompanyAttribute,
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ["companies", "attributes", company.id],
            });
            toast({
                title: t("company.attribute.admin.save.title", "Einstellungen aktualisiert"),
                description: t(
                    "company.attribute.admin.save.description",
                    "Einstellungen für die Firma wurden aktualisiert."
                ),
                status: "success",
                duration: 2000,
            });
        },
    });

    const escalateIntervalChanged = useMemo(() => {
        if (!companyAttributes) {
            return false;
        }

        return parseInt(companyAttributes.escalater_interval, 10) !== escalaterInterval;
    }, [companyAttributes, escalaterInterval]);

    const reminderIntervalChanged = useMemo(() => {
        if (!companyAttributes) {
            return false;
        }

        return parseInt(companyAttributes.reminder_interval, 10) !== reminderInterval;
    }, [companyAttributes, reminderInterval]);

    const reminderForecastChanged = useMemo(() => {
        if (!companyAttributes) {
            return false;
        }

        return parseInt(companyAttributes.reminder_forecast, 10) !== reminderForecast;
    }, [companyAttributes, reminderForecast]);

    const handleSaveAttribute = useCallback(
        (type: "reminder" | "forecast" | "escalater") => {
            if (!user || !user.is_admin || !company.id) {
                return;
            }

            let attributes: CompanyAttributeUpdate | null = null;

            if (type === "reminder") {
                attributes = {
                    [CompanyAttributeIdentifier.REMINDER_INTERVAL]: String(reminderInterval),
                };
            }

            if (type === "forecast") {
                attributes = {
                    [CompanyAttributeIdentifier.REMINDER_FORECAST]: String(reminderForecast),
                };
            }

            if (type === "escalater") {
                attributes = {
                    [CompanyAttributeIdentifier.ESCALATER_INTERVAL]: String(escalaterInterval),
                };
            }

            if (attributes) {
                mutate({ attributes, companyId: company.id });
            }
        },
        [user, company.id, reminderInterval, reminderForecast, escalaterInterval, mutate]
    );

    if (!user || !user?.is_admin || !companyAttributes) {
        return null;
    }

    return (
        <Flex flexDirection="column">
            <Heading as="h2" size="md" fontWeight="base" my={3}>
                {t("company.view.additionalSettings.title", "Weitere Einstellungen")}
            </Heading>

            <AdditionalSliderSettings
                name={t("company.attribute.admin.reminderInterval", "Erinnerungen-Interval")}
                inputName="reminder"
                changed={reminderIntervalChanged}
                value={reminderInterval}
                sliderChange={setReminderInterval}
                handleSave={() => handleSaveAttribute("reminder")}
                handleReset={() =>
                    setReminderInterval(parseInt(companyAttributes.reminder_interval, 10))
                }
            />

            <AdditionalSliderSettings
                name={t("company.attribute.admin.reminderForecast", "Erinnerungen-Forecast")}
                inputName="forecast"
                odd
                changed={reminderForecastChanged}
                value={reminderForecast}
                sliderChange={setReminderForecast}
                handleSave={() => handleSaveAttribute("forecast")}
                handleReset={() =>
                    setReminderForecast(parseInt(companyAttributes.reminder_forecast, 10))
                }
            />

            <AdditionalSliderSettings
                name={t("company.attribute.admin.escalaterInterval", "Alarm-Intervall")}
                inputName="escalater"
                odd
                changed={escalateIntervalChanged}
                value={escalaterInterval}
                sliderChange={setEscalaterInterval}
                handleSave={() => handleSaveAttribute("escalater")}
                handleReset={() =>
                    setEscalaterInterval(parseInt(companyAttributes.escalater_interval, 10))
                }
            />
        </Flex>
    );
};

export default CompanyAdditionalSettings;
