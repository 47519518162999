/** @format */

import { useColorModeValue, Flex, Avatar, FormLabel, Input, Text } from "@chakra-ui/react";
import { useGetFileSize } from "@hook/useGetFileSize";
import { BuildingIcon } from "@icons/building-icon";
import defaultColors from "@theme/defaultColors";
import React, { useCallback, useState } from "react";
import { Trans } from "react-i18next";
import { FiEdit3 } from "react-icons/fi";

interface AvatarImageUploadProps {
    avatarName: string;
    imageChanged?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    imageUrl?: string | null;
    hideUpload?: boolean;
    company?: boolean;
}

const max = Number(import.meta.env.VITE_UPLOAD_MAX_SIZE);

const AvatarImageUpload: React.FC<AvatarImageUploadProps> = ({
    avatarName,
    imageChanged = () => null,
    imageUrl,
    hideUpload = false,
    company = false,
}) => {
    const uploadButtonBg = useColorModeValue(defaultColors.white, defaultColors.grey[100]);
    const uploadButtonHoverBg = useColorModeValue(defaultColors.grey[20], defaultColors.grey[90]);

    const { getSize } = useGetFileSize();

    const [sizeError, setSizeError] = useState<boolean>(false);

    const handleImageChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files ? e.target.files[0] : null;

            if (file) {
                if (file && file.size > max) {
                    setSizeError(() => true);
                    return;
                }

                setSizeError(() => false);

                imageChanged(e);
            }
        },
        [imageChanged]
    );

    return (
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <Flex position="relative">
                {avatarName.trim().length > 0 || imageUrl ? (
                    <Avatar
                        my={4}
                        size="2xl"
                        borderRadius={company ? "25px" : "full"}
                        name={!company ? `${avatarName ?? undefined}` : undefined}
                        src={imageUrl || undefined}
                        icon={company ? <BuildingIcon fontSize={48} /> : undefined}
                        bg={company ? defaultColors.grey[50] : undefined}
                    />
                ) : (
                    <Avatar
                        my={4}
                        size="2xl"
                        borderRadius={company ? "25px" : "full"}
                        bg={defaultColors.grey[50]}
                        icon={company ? <BuildingIcon fontSize={48} /> : undefined}
                    />
                )}

                {!hideUpload && (
                    <FormLabel
                        sx={{
                            position: "absolute",
                            backgroundColor: uploadButtonBg,
                            borderRadius: "100%",
                            padding: "10px 12px",
                            bottom: "10px",
                            right: "-15px",
                            margin: 0,
                            "&:hover": {
                                color: "rgba(0, 0, 0, 0.4)",
                                backgroundColor: uploadButtonHoverBg,
                                cursor: "pointer",
                            },
                        }}
                    >
                        <Input
                            type="file"
                            accept="image/*"
                            opacity={0}
                            overflow="hidden"
                            position="absolute"
                            zIndex={-1}
                            onChange={handleImageChange}
                        />
                        <FiEdit3 size={25} color={defaultColors.blue[60]} />
                    </FormLabel>
                )}
            </Flex>
            {sizeError && (
                <Text color={defaultColors.betterRed[100]}>
                    <Trans i18nKey="user:upload.error">
                        Fehler das Bild darf nicht mehr als <Text as="span">{getSize(max)}</Text>{" "}
                        haben
                    </Trans>
                </Text>
            )}
        </Flex>
    );
};

export default AvatarImageUpload;
