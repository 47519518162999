/** @format */

import { Img, ImgProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const ProgressiveImage = ({
    placeholderSrc,
    src,
    ...props
}: ImgProps & {
    placeholderSrc: string;
}) => {
    const [imgSrc, setImgSrc] = useState<string | undefined>(placeholderSrc || src);

    const checkBaseType = imgSrc ? imgSrc.search("base64") >= 0 : false;

    const isShowingPlaceholder = checkBaseType ? false : imgSrc === placeholderSrc;

    useEffect(() => {
        if (!src) return;

        const img = new Image();

        img.src = src;
        img.onload = () => {
            setImgSrc(src);
        };
    }, [src]);

    if (!imgSrc) return null;

    return (
        <Img
            {...{ src: imgSrc, ...props }}
            alt={props.alt || ""}
            filter={isShowingPlaceholder ? "blur(10px)" : "blur(0px)"}
            clipPath={isShowingPlaceholder ? "inset(0)" : "unset"}
            transition={isShowingPlaceholder ? "unset" : "filter 0.5s linear"}
        />
    );
};
export default ProgressiveImage;
