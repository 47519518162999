/** @format */

import ApparatusesIndex from "@screen/authenticated/Apparatuses/ApparatusesIndex/ApparatusesIndex";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_defaultView/apparatuses/_apparatusesMenue/")({
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("apparatus:index.title", "Prüfmittel"),
                        mini: true,
                    },
                },
            ],
        };
    },
    component: ApparatusesIndex,
});
