/** @format */

import { extendedUserAdapter, globalUserAdapter } from "@api/ApiRequest";
import {
    ChangeCostcenterUserAssignmentMode,
    ChangeCostcenterUserNotificationMode,
    FetchCostcenterUserMode,
} from "@api/ext/CostcenterAdapter";
import { ExtendedUserAdd } from "@api/ext/ExtendedUserAdapter";
import { EmptyExtraData, PaginationRequest } from "@components/Table/TableBuilder";

import { getPaginationParams } from "./PaginationUtils";

export type UserCostcentersExtraData = {
    userId: number;
    mode: FetchCostcenterUserMode;
};

export const fetchUser = async (id: number) => await extendedUserAdapter.getUser(id);
export const fetchAddUser = async (id?: string) => await extendedUserAdapter.getAddUser(id);

export const fetchUsers = async (data: PaginationRequest<EmptyExtraData>) =>
    await extendedUserAdapter.getIndex(false, getPaginationParams(data));
export const fetchFilteredUsersList = async (search?: string) =>
    await extendedUserAdapter.searchUsers(search);

export const addUser = async (data: {
    userSend: ExtendedUserAdd;
    image: File | null;
    doInvite?: boolean;
}) => await extendedUserAdapter.addUser(data.userSend, data.image, data?.doInvite);

export const editUser = async (data: {
    userSend: ExtendedUserAdd;
    id: string;
    doInvite?: boolean;
}) => await extendedUserAdapter.editUser(data.userSend, data.id, data?.doInvite);

export const archiveUsers = async (userIds: number | number[]) =>
    await globalUserAdapter.archive(userIds);

export const unarchiveUsers = async (userIds: number | number[]) =>
    await globalUserAdapter.unarchive(userIds);

export const fetchUserCostcenters = async (data: PaginationRequest<UserCostcentersExtraData>) =>
    await extendedUserAdapter.getCostcenters(data.userId, data.mode, getPaginationParams(data));

export const changeUserCostcenterAssignment = async ({
    costcenterIds,
    userId,
    mode,
}: {
    userId: number;
    costcenterIds: number[];
    mode: ChangeCostcenterUserAssignmentMode;
}) => await extendedUserAdapter.changeUserCostcenterAssignment(userId, costcenterIds, mode);

export const changeCostcentersNotifications = async ({
    userId,
    costcenterIds,
    mode,
}: {
    userId: number;
    costcenterIds: number[];
    mode: ChangeCostcenterUserNotificationMode;
}) => await extendedUserAdapter.changeCostcentersNotifications(userId, costcenterIds, mode);
