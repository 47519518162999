/** @format */

import TicketView from "@screen/authenticated/Tickets/TicketsOverview/TicketView/TicketView";
import i18n from "@services/i18n";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_smallView/tickets/view/$ticketId")({
    component: TicketView,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("ticket.index.title", "Tickets"),
                        miniHeadline: i18n.t("ticket.info.support", "Support"),
                    },
                },
            ],
        };
    },
});
