/** @format */

import { ButtonGroup, ButtonGroupProps, IconButton } from "@chakra-ui/react";
import { FiCheck, FiTrash } from "react-icons/fi";

const AtomSaveButtonGroup = ({
    hasChanged,
    onReset,
    onSubmit,
    ...rest
}: ButtonGroupProps & { hasChanged: boolean; onReset: () => void; onSubmit: () => void }) => {
    return (
        <ButtonGroup {...rest}>
            <IconButton
                icon={<FiCheck />}
                size="sm"
                aria-label={`save ${rest["aria-label"] ?? ""}`}
                colorScheme={hasChanged ? "default" : "transparent"}
                isDisabled={!hasChanged}
                onClick={onSubmit}
            />
            <IconButton
                icon={<FiTrash />}
                size="sm"
                aria-label={`reset ${rest["aria-label"] ?? ""}`}
                colorScheme="danger"
                variant="ghost"
                onClick={onReset}
            />
        </ButtonGroup>
    );
};

export default AtomSaveButtonGroup;
