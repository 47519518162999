/** @format */

import SuspenseLoad from "@components/SuspenseLoad/SuspenseLoad";
import ApiView from "@screen/authenticated/Api/ApiView";
import i18n from "@services/i18n";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { Suspense } from "react";

export const Route = createFileRoute("/_auth/_smallView/api")({
    beforeLoad: ({ context }) => {
        if (!context.auth?.user?.subscription_rights.canUseAPI) {
            // eslint-disable-next-line no-throw-literal
            throw redirect({
                to: "/",
            });
        }
    },
    component: () => (
        <Suspense fallback={<SuspenseLoad />}>
            <ApiView />
        </Suspense>
    ),
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("api:title", "API"),
                        miniHeadline: i18n.t("api:small", "Schnittstelle"),
                    },
                },
            ],
        };
    },
});
