/** @format */

import { CustomFieldProvider } from "@context/CustomFieldProvider";
import InspectionsAdd from "@screen/authenticated/Inspections/InspectionsAdd/InspectionsAdd";
import i18n from "@services/i18n";
import { fetchApparatus } from "@services/Models/ApparatusService";
import { queryOptions } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

export const apparatusInspectionQueryOptions = (id: string) =>
    queryOptions({
        queryKey: ["apparatuses", "view", id],
        queryFn: () => fetchApparatus(Number(id)),
        enabled: !!id,
    });

export const Route = createFileRoute("/_auth/_defaultView/inspections/add/$apparatusId")({
    loader: ({ context, params }) => {
        return context.queryClient.ensureQueryData(
            apparatusInspectionQueryOptions(params.apparatusId)
        );
    },
    component: RouteComponent,
    head: ({ loaderData }) => {
        return {
            meta: [
                {
                    titleElement: {
                        title: loaderData?.identno,
                        mini: false,
                        miniHeadline: i18n.t("inspection:add.title", "Prüfbericht"),
                    },
                },
            ],
        };
    },
});

function RouteComponent() {
    return (
        <CustomFieldProvider customFieldKey="Inspections">
            <InspectionsAdd />
        </CustomFieldProvider>
    );
}
