/** @format */

import { Box, Flex } from "@chakra-ui/react";
import LocationContext from "@context/LocationProvider";
import Map from "ol/Map";
import View from "ol/View";
import { defaults as defaultControls } from "ol/control";
import GeoJSON from "ol/format/GeoJSON";
import MVT from "ol/format/MVT";
import { defaults as defaultInteractions } from "ol/interaction";
import { Vector as VectorLayer } from "ol/layer";
import VectorTileLayer from "ol/layer/VectorTile";
import { fromLonLat, Projection } from "ol/proj";
import VectorSource from "ol/source/Vector";
import VectorTileSource from "ol/source/VectorTile";
import { applyStyle } from "ol-mapbox-style";
import React, { useEffect, useCallback, useRef, useContext } from "react";

import "ol/ol.css";

const esrijsonFormat = new GeoJSON();

interface MandantorCompanyLocationBoxProps {
    defaultHeight?: string;
    roundedLeft?: boolean;
}

const MandantorCompanyLocationBox: React.FC<MandantorCompanyLocationBoxProps> = ({
    defaultHeight,
    roundedLeft,
}) => {
    const { defaultLocation, updateIsLocation } = useContext(LocationContext);

    const mapRefView = useRef<HTMLDivElement>(null);
    const mapRef = useRef<Map | null>(null);

    const vectorRef = useRef<VectorSource | null>(null);
    const vectorPointRef = useRef<VectorSource | null>(null);

    const handleMapLoader = useCallback(
        (projection: Projection) => {
            const defaultFeature = esrijsonFormat.readFeature(defaultLocation, {
                featureProjection: projection,
            });

            if (defaultFeature) {
                const geometry = defaultFeature.getGeometry();
                if (geometry) {
                    mapRef.current?.getView().fit(geometry.getExtent(), {
                        size: mapRef.current?.getSize(),
                        minResolution: 1,
                    });
                }
            }

            updateIsLocation(() => false);
        },
        [defaultLocation, updateIsLocation]
    );

    useEffect(() => {
        if (!vectorRef.current) {
            vectorRef.current = new VectorSource({
                loader(extent, resolution, projection) {
                    handleMapLoader(projection);
                },
            });
        }
    }, [vectorRef, handleMapLoader]);

    useEffect(() => {
        if (!defaultLocation) {
            return;
        }

        if (mapRefView.current && !mapRef.current && vectorRef.current) {
            const vector = new VectorLayer({
                source: vectorRef.current,
            });

            const vectorSource = new VectorTileLayer({
                source: new VectorTileSource({
                    attributions:
                        '© <a href="https://www.openstreetmap.org/copyright">' +
                        "OpenStreetMap contributors</a>",
                    format: new MVT(),
                    url: import.meta.env.VITE_MAP_URL,
                }),
            });

            void applyStyle(vectorSource, `${import.meta.env.VITE_FRONTEND_URL}map/style.json`);

            const layer = vectorSource;

            vectorPointRef.current = new VectorSource();

            const vectorPoint = new VectorLayer({
                source: vectorPointRef.current,
                style: {
                    "icon-src": "/img/pin_map.svg",
                    "icon-opacity": 0.95,
                    "icon-anchor": [0.5, 46],
                    "icon-anchor-x-units": "fraction",
                    "icon-anchor-y-units": "pixels",
                },
            });

            mapRef.current = new Map({
                controls: defaultControls({
                    zoom: false,
                }).extend([]),
                interactions: defaultInteractions({
                    mouseWheelZoom: false,
                    pinchZoom: false,
                }),
                layers: [layer, vector, vectorPoint],
                target: mapRefView.current,
                view: new View({
                    //center: fromLonLat([10.451526, 51.165691]),
                    center: fromLonLat([10.451526, 51.165691]),
                    zoom: 5,
                }),
            });
        }
    }, [mapRef, mapRefView, vectorRef, defaultLocation]);

    return (
        <Flex
            position="absolute"
            flexDirection="column"
            top={0}
            left={0}
            w="full"
            h="full"
            roundedRight={!roundedLeft ? "md" : undefined}
            roundedLeft={roundedLeft ? "md" : undefined}
        >
            <Flex
                position="relative"
                flexDirection="column"
                width="100%"
                roundedRight={!roundedLeft ? "md" : undefined}
                roundedLeft={roundedLeft ? "md" : undefined}
            >
                <Box
                    ref={mapRefView}
                    bg="#fff"
                    className="map"
                    width="100%"
                    height={defaultHeight ? defaultHeight : "130px"}
                    position="relative"
                    roundedRight={!roundedLeft ? "md" : undefined}
                    roundedLeft={roundedLeft ? "md" : undefined}
                    overflow="hidden"
                />
            </Flex>
        </Flex>
    );
};

export default MandantorCompanyLocationBox;
