/** @format */

import { customFieldMetadataAdapter } from "@api/ApiRequest";
import CustomFieldsAdd from "@screen/authenticated/Mandantor/CustomFieldsAdd/CustomFieldsAdd";
import i18n from "@services/i18n";
import { queryOptions } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

const fetchCustomFields = async () => await customFieldMetadataAdapter.getFieldList("all");

export const customFieldsMetadataQueryOptions = () =>
    queryOptions({
        queryKey: ["customFieldsMetadatas", "all"],
        queryFn: () => fetchCustomFields(),
    });

export const Route = createFileRoute("/_auth/_smallView/_settingsLayout/settings/customfields")({
    loader: ({ context }) => {
        return context.queryClient.ensureQueryData(customFieldsMetadataQueryOptions());
    },
    component: CustomFieldsAdd,
    head: () => {
        return {
            meta: [
                {
                    titleElement: {
                        title: i18n.t("global:customFields", "Zusätzliche Felder"),
                        mini: true,
                        miniHeadline: i18n.t("global:mandantor", "Unternehmen"),
                    },
                },
            ],
        };
    },
});
