/** @format */

import { FileUploadProvider } from "@context/FileUploadProvider";
import { LocationProvider } from "@context/LocationProvider";
import AppInfo from "@screen/authenticated/App/AppInfo";
import ApparatusesBasedataAdd from "@screen/authenticated/Apparatuses/ApparatusesBasedataAdd/ApparatusesBasedataAdd";
import Connecting from "@screen/authenticated/Connecting/Connecting";
import CompanyAdd from "@screen/authenticated/Mandantor/Company/CompanyAdd";
import CompanyEdit from "@screen/authenticated/Mandantor/Company/CompanyEdit";
import { allowedFileTypesImg } from "@services/FileTypes";
import { lazy } from "react";

/** @format */
const TicketAdd = lazy(() => import("@screen/authenticated/Tickets/TicketAdd/TicketAdd"));
const CostcentersAdd = lazy(
    () => import("@screen/authenticated/Costcenters/CostcentersAdd/CostcentersAdd")
);
const CostcentersEdit = lazy(
    () => import("@screen/authenticated/Costcenters/CostcentersEdit/CostcentersEdit")
);
const AssignUsersToCostcenter = lazy(
    () =>
        import("@screen/authenticated/Costcenters/AssignUsersToCostcenter/AssignUsersToCostcenter")
);
const UnassignUsersToCostcenter = lazy(
    () =>
        import(
            "@screen/authenticated/Costcenters/UnassignUsersToCostcenter/UnassignUsersToCostcenter"
        )
);
const AssignApparatusesToCostcenter = lazy(
    () =>
        import(
            "@screen/authenticated/Costcenters/AssignApparatusesToCostcenter/AssignApparatusesToCostcenter"
        )
);
const UserAdd = lazy(() => import("@screen/authenticated/Users/UserAdd/UserAdd"));
const AssignCostcentersToUser = lazy(
    () => import("@screen/authenticated/Users/AssignCostcentersToUser/AssignCostcentersToUser")
);
const ApparatusesLogs = lazy(
    () => import("@screen/authenticated/Logs/ApparatusesLogs/ApparatusesLogs")
);

export const modals = [
    {
        name: "ticket-add",
        component: TicketAdd,
    },
    {
        name: "costcenters-add",
        component: CostcentersAdd,
    },
    {
        name: "costcenters-edit",
        extraId: true,
        component: CostcentersEdit,
    },
    {
        name: "costcenters-assignUsers",
        extraId: true,
        component: AssignUsersToCostcenter,
    },
    {
        name: "costcenters-unAssignUsers",
        extraId: true,
        component: UnassignUsersToCostcenter,
    },
    {
        name: "costcenters-assignApparatuses",
        extraId: true,
        component: AssignApparatusesToCostcenter,
    },
    {
        name: "connected",
        component: Connecting,
    },
    {
        name: "appInfo",
        component: AppInfo,
    },
    {
        name: "users-add",
        extraId: true,
        component: UserAdd,
    },
    {
        name: "users-assignCostcenters",
        extraId: true,
        component: AssignCostcentersToUser,
    },
    {
        name: "apparatuses-basedata",
        extraId: true,
        component: () => (
            <FileUploadProvider
                defaultSelect={{
                    selectId: 6,
                    types: allowedFileTypesImg,
                }}
            >
                <ApparatusesBasedataAdd />
            </FileUploadProvider>
        ),
    },
    {
        name: "logs-apparatuses",
        extraId: true,
        component: ApparatusesLogs,
    },
    {
        name: "companies-add",
        extraId: true,
        component: CompanyAdd,
    },
    {
        name: "companies-edit",
        extraId: true,
        component: () => (
            <LocationProvider>
                <CompanyEdit />
            </LocationProvider>
        ),
    },
];
