/** @format */

import { FileRoutesByFullPath } from "../routeTree.gen";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface UnconfirmedRoutes
    extends Pick<
        FileRoutesByFullPath,
        | "/tickets"
        | "/tickets/add"
        | "/tickets/view/$ticketId"
        | "/logout"
        | "/unconfirmed"
        | "/faq"
        | "/logout"
    > {}

const validUnconfirmedRoutes: Array<keyof UnconfirmedRoutes> = [
    "/tickets",
    "/tickets/add",
    "/tickets/view/$ticketId",
    "/logout",
    "/unconfirmed",
    "/faq",
    "/logout",
];

export function isSelectedUnconfirmedRoute(route: string): route is keyof UnconfirmedRoutes {
    return validUnconfirmedRoutes.includes(route as keyof UnconfirmedRoutes);
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface SubscriptionEndRoutes
    extends Pick<
        FileRoutesByFullPath,
        | "/tickets"
        | "/tickets/add"
        | "/tickets/view/$ticketId"
        | "/logout"
        | "/unconfirmed"
        | "/faq"
        | "/logout"
        | "/invalidSubscription"
        | "/invalidSubscription/company"
        | "/invalidSubscription/done"
        | "/invalidSubscription/payment"
    > {}

const validSubscriptionEndRoutes: Array<keyof SubscriptionEndRoutes> = [
    "/tickets",
    "/tickets/add",
    "/tickets/view/$ticketId",
    "/logout",
    "/unconfirmed",
    "/faq",
    "/logout",
    "/invalidSubscription",
    "/invalidSubscription/company",
    "/invalidSubscription/done",
    "/invalidSubscription/payment",
];

export function isSelectedSubscriptionEndRoute(
    route: string
): route is keyof SubscriptionEndRoutes {
    return validSubscriptionEndRoutes.includes(route as keyof SubscriptionEndRoutes);
}
