/** @format */

import { EmptyExtraData, PaginationRequest } from "@components/Table/TableBuilder";

export const getPaginationParams = (data: PaginationRequest<EmptyExtraData>) => {
    return {
        pageIndex: data.pageIndex + 1,
        pageSize: data.pageSize,
        sort: data.sort,
        direction: data.direction,
        search: data.search,
        filter: data.filter ?? "",
    };
};
