/** @format */

import { apparatusBasedataAdapter } from "@api/ApiRequest";
import { BasedataAdd } from "@api/ext/ApparatusBasedataAdapter";
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    Skeleton,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import FileUploadView from "@components/FileUploadView/FileUploadView";
import FlexShadow from "@components/FlexShadow/FlexShadow";
import ModalView from "@components/Modal/ModalView";
import { defaultGap } from "@constants/styles";
import { useFileUploadContext } from "@context/FileUploadProvider";
import useApparatusBasedataFileType from "@hook/translations/useApparatusBasedataFileType";
import { useLoading } from "@hook/useLoading";
import { useRequestError } from "@hook/useRequestError";
import { FileUrlType } from "@services/FileTypes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CreatableSelect } from "chakra-react-select";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "@tanstack/react-router";
import { MultiValue, SingleValue } from "react-select";

import "./ApparatusesBasedataAdd.scss";

interface TypesOption {
    label: string;
    value: string;
    isNew?: boolean;
}

const addBasedata = async (postData: { dataAdd: BasedataAdd; files?: FileUrlType[] }) =>
    await apparatusBasedataAdapter.addBasedata(postData.dataAdd, postData.files);

const fetchManufacturers = async () => await apparatusBasedataAdapter.getManufactors();

const fetchTypes = async () => await apparatusBasedataAdapter.getTypes();

const ApparatusesBasedataAdd: React.FC = () => {
    const [t] = useTranslation();
    const queryClient = useQueryClient();
    const toast = useToast();
    const { handleError } = useRequestError();

    const { history } = useRouter();

    const { files } = useFileUploadContext();

    const fileType = useApparatusBasedataFileType();

    const { setLoading } = useLoading();

    const [post, setPost] = useState<boolean>(false);

    const [model, setModel] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const [error] = useState<boolean>(false);

    const [manufactorOptions, setManufactorOptions] = useState<TypesOption[]>();
    const [selectManufactorOptions, setSelectManufactorOptions] = useState<TypesOption>();

    const [fileError, setFileError] = useState(false);

    const [typesOptions, setTypesOptions] = useState<TypesOption[]>();
    const [selectTypesOptions, setSelectTypesOptions] = useState<TypesOption[]>();

    const { data: manufacturersList, isLoading: manufacturersLoading } = useQuery({
        queryKey: ["manufacturers"],
        queryFn: fetchManufacturers,
    });
    const { data: typesList, isLoading: typesLoading } = useQuery({
        queryKey: ["types"],
        queryFn: fetchTypes,
    });

    const onAddBasedata = useMutation({
        mutationFn: addBasedata,

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ["basedatas"],
            });
            const previousData = queryClient.getQueriesData({
                queryKey: ["basedatas"],
            });

            setPost(() => true);

            return { previousData };
        },

        onSuccess: () => {
            toast({
                title: t("apparatusesBasedata.add.success.title", "Stammdaten gespeichert"),
                description: t(
                    "apparatusesBasedata.add.success.description",
                    "Stammdaten wurden angelegt"
                ),
                status: "success",
                duration: 2000,
            });
        },

        onError: (error, data, ctx) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore expecting ctx can be type unknown
            for (const [key, value] of ctx?.previousData ?? []) {
                queryClient.setQueryData([key], value);
            }

            toast({
                title: t("apparatusesBasedata.add.failed.title", "Fehler"),
                description: t(
                    "apparatusesBasedata.add.failed.description",
                    "Stammdaten konnten nicht angelegt werden"
                ),
                status: "error",
                duration: 2000,
            });

            handleError(error);
        },

        onSettled: () => {
            void queryClient.invalidateQueries({
                queryKey: ["basedatas"],
            });

            setPost(() => false);

            history.go(-1);
        },
    });

    const handleSubmit = useCallback(() => {
        const typeBasedata: number[] = [];
        const typeBasedataNew: string[] = [];

        if (fileError) {
            return;
        }

        selectTypesOptions?.map((data) => {
            if (!data.isNew) {
                typeBasedata.push(Number(data.value));
            } else if (data.isNew) {
                typeBasedataNew.push(data.value);
            }
        });

        const filesFilter = files.filter((file) => !!file.file && file.state === "uploaded");

        const dataAdd: BasedataAdd = {
            model,
            description,
            manufactor_id: selectManufactorOptions?.isNew
                ? undefined
                : Number(selectManufactorOptions?.value),
            manufactor: selectManufactorOptions?.isNew ? selectManufactorOptions?.value : undefined,
            basedata_type: typeBasedata,
            basedata_type_new: typeBasedataNew,
        };

        onAddBasedata.mutate({ dataAdd, files: filesFilter });
    }, [
        fileError,
        selectTypesOptions,
        files,
        model,
        description,
        selectManufactorOptions?.isNew,
        selectManufactorOptions?.value,
        onAddBasedata,
    ]);

    const handleManufactor = useCallback((option: SingleValue<TypesOption>) => {
        if (!option) {
            setSelectManufactorOptions(undefined);
            return;
        }

        const manufactor: TypesOption = {
            label: option?.label,
            value: option?.value,
        };
        setSelectManufactorOptions(manufactor);
    }, []);

    const handleTypes = useCallback((option: MultiValue<TypesOption>) => {
        if (!option) {
            setSelectTypesOptions(undefined);
            return;
        }

        const types: TypesOption[] = option ? (option as TypesOption[]) : [];

        setSelectTypesOptions(types);
    }, []);

    const handleCreateManufactor = useCallback((name: string) => {
        const manufactor: TypesOption = {
            label: name,
            value: name,
            isNew: true,
        };
        setSelectManufactorOptions(manufactor);
    }, []);

    const handleCreateType = useCallback(
        (name: string) => {
            const manufactor: TypesOption = {
                label: name,
                value: name,
                isNew: true,
            };

            const updatedTypes: TypesOption[] = selectTypesOptions ? [...selectTypesOptions] : [];

            updatedTypes.push(manufactor);

            setSelectTypesOptions(updatedTypes);
        },
        [selectTypesOptions]
    );

    useEffect(() => {
        const options: TypesOption[] = [];
        manufacturersList?.map((v) => {
            options.push({
                value: String(v.id),
                label: v.name,
            });
        });
        setManufactorOptions(options);
    }, [manufacturersList]);

    useEffect(() => {
        const options: TypesOption[] = [];
        typesList?.map((v) => {
            options.push({
                value: String(v.id),
                label: v.name,
            });
        });
        setTypesOptions(options);
    }, [typesList]);

    useEffect(() => {
        setLoading(manufacturersLoading && typesLoading);
    }, [manufacturersLoading, setLoading, typesLoading]);

    const errorSubmit = !model;

    return (
        <ModalView
            size="6xl"
            title={t("apparatusesBasedata.add.title", "Geräte-Stammdaten Hinzufügen")}
        >
            <Flex flexDirection="column" mb={3}>
                <FlexShadow zIndex={100}>
                    <Flex pb={3} width="100%">
                        <InputGroup my={4} width="100%" mr={2}>
                            <FormControl isRequired isInvalid={!!error}>
                                <FormLabel htmlFor="input-model" className="form-label">
                                    {t("apparatusesBasedata.model.add", "Modell")}
                                </FormLabel>
                                <Input
                                    id="input-model"
                                    aria-describedby="Kostenstelle"
                                    placeholder={t(
                                        "apparatusesBasedata.model.placeholder",
                                        "Modell"
                                    )}
                                    required
                                    value={model}
                                    onChange={(e) => {
                                        setModel(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </InputGroup>
                    </Flex>
                    <Flex pb={3} gap={defaultGap} width="100%">
                        <InputGroup width="100%">
                            <FormControl isInvalid={!!error}>
                                <FormLabel htmlFor="input-manufacturer" className="form-label">
                                    {t("apparatusesBasedata.manufacturer.add", "Hersteller")}
                                </FormLabel>
                                {manufacturersLoading ? (
                                    <Skeleton height="40px" />
                                ) : (
                                    <CreatableSelect
                                        id="input-manufacturer"
                                        placeholder={t(
                                            "apparatusesBasedata.manufacturer.name",
                                            "Hersteller"
                                        )}
                                        value={selectManufactorOptions}
                                        options={manufactorOptions}
                                        onCreateOption={(e) => handleCreateManufactor(e)}
                                        isClearable
                                        backspaceRemovesValue
                                        onChange={(e) => handleManufactor(e)}
                                    />
                                )}
                            </FormControl>
                        </InputGroup>

                        <InputGroup width="100%">
                            <FormControl isInvalid={!!error}>
                                <FormLabel htmlFor="input-name" className="form-label">
                                    {t("apparatusesBasedata.types.add", "Gerätekategorie")}
                                </FormLabel>
                                {typesLoading ? (
                                    <Skeleton height="40px" />
                                ) : (
                                    <CreatableSelect
                                        id="input-name"
                                        isMulti
                                        placeholder={t(
                                            "apparatusesBasedata.types.name",
                                            "Gerätekategorie"
                                        )}
                                        isClearable
                                        value={selectTypesOptions}
                                        options={typesOptions}
                                        menuPlacement="bottom"
                                        onCreateOption={(e) => handleCreateType(e)}
                                        onChange={(e) => handleTypes(e)}
                                    />
                                )}
                            </FormControl>
                        </InputGroup>
                    </Flex>
                </FlexShadow>
                <FlexShadow mt={defaultGap} zIndex={50}>
                    <FileUploadView onFileValidate={setFileError} selectTypes={fileType} />
                </FlexShadow>

                <FlexShadow mt={defaultGap}>
                    <Flex pb={3} width="100%">
                        <InputGroup my={4} width="100%" mr={1}>
                            <FormControl isInvalid={!!error}>
                                <FormLabel htmlFor="input-description" className="form-label">
                                    {t("apparatusesBasedata.description.add", "Beschreibung")}
                                </FormLabel>
                                <Textarea
                                    rows={4}
                                    id="input-description"
                                    aria-describedby={t(
                                        "apparatusesBasedata.description.name",
                                        "Beschreibung | Technische Informationen"
                                    )}
                                    placeholder={t(
                                        "apparatusesBasedata.description.name",
                                        "Beschreibung | Technische Informationen"
                                    )}
                                    required
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </InputGroup>
                    </Flex>

                    <Flex justifyContent="flex-end">
                        <Button
                            variant="solid"
                            isDisabled={errorSubmit}
                            colorScheme="default"
                            isLoading={post}
                            size="sm"
                            onClick={() => handleSubmit()}
                        >
                            {t("costcenter:btn.add", "Hinzufügen")}
                        </Button>
                    </Flex>
                </FlexShadow>
            </Flex>
        </ModalView>
    );
};

export default ApparatusesBasedataAdd;
