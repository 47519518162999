/** @format */

import { Box, Text, Flex, Tooltip, Select } from "@chakra-ui/react";
import ProgressiveImage from "@components/ProgressiveImage/ProgressiveImage";
import RenderFileIcon from "@components/RenderFileIcon/RenderFileIcon";
import { useFileUploadContext } from "@context/FileUploadProvider";
import { useGetFileSize } from "@hook/useGetFileSize";
import { FileType, SelectType } from "@services/FileTypes";
import defaultColors from "@theme/defaultColors";
import useDefaultColors from "@theme/useDefaultColors";
import { DateTime } from "luxon";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface FileSlideViewProps {
    name?: string;
    fileType?: FileType;
    url?: string;
    lastModified?: number;
    lastModifiedDate?: string;
    size?: number;
    selectTypes?: SelectType[];
    onSelectType?: (type?: SelectType) => void;
    defaultSelectType?: SelectType;
}

const FileSlideView: React.FC<FileSlideViewProps> = ({
    url = "",
    fileType,
    name = "",
    lastModified,
    lastModifiedDate,
    size,
    selectTypes,
    defaultSelectType,
}) => {
    const [t] = useTranslation(["global"]);
    const { blueTextColor, lightTextColor } = useDefaultColors();
    const isImg = fileType === "img";

    const { handleSelectType } = useFileUploadContext();

    const { getSize } = useGetFileSize();

    const checkPlaceholder = url.search("base64") >= 0;

    const onSelectType = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const select = selectTypes?.find((value) => value.key === Number(e.target.value));

            if (select) {
                handleSelectType(select);
            }
        },
        [handleSelectType, selectTypes]
    );

    const defaultSelectValue = defaultSelectType?.key;

    return (
        <Flex flexDirection="column" w="full">
            <Flex justifyContent="center" alignItems="center" key={`${name}_${String(size)}`}>
                {isImg && url !== "" ? (
                    <ProgressiveImage
                        src={checkPlaceholder ? undefined : url}
                        placeholderSrc={checkPlaceholder ? url : url + "/thumb"}
                        mr={2}
                        borderRadius="md"
                        height="125px"
                        width="125px"
                        objectFit="contain"
                    />
                ) : (
                    <Flex
                        height="125px"
                        borderRadius="md"
                        mr={2}
                        width="125px"
                        alignItems="center"
                        justifyContent="center"
                        backgroundColor={defaultColors.blue[100]}
                    >
                        <RenderFileIcon type={fileType} size={55} />
                    </Flex>
                )}
            </Flex>
            <Box mt={5}>
                {name && name.length >= 25 ? (
                    <Tooltip label={name} hasArrow placement="top">
                        <Text
                            mb={1}
                            fontSize="12px"
                            color={lightTextColor}
                            wordBreak="keep-all"
                            overflowX="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            maxW="200px"
                        >
                            <Text as="span" color={blueTextColor} mr={2}>
                                {t("global:upload.name", "Name")}:
                            </Text>{" "}
                            {name}
                        </Text>
                    </Tooltip>
                ) : (
                    <Text
                        mb={1}
                        fontSize="12px"
                        color={lightTextColor}
                        wordBreak="keep-all"
                        overflowX="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        maxW="200px"
                    >
                        <Text as="span" color={blueTextColor} mr={2}>
                            {t("global:upload.name", "Name")}:
                        </Text>{" "}
                        {name}
                    </Text>
                )}

                {lastModified && (
                    <Text mb={1} fontSize="12px" color={lightTextColor}>
                        <Text as="span" color={blueTextColor} mr={2}>
                            {t("global:upload.modified", "Letzte Änderung")}:
                        </Text>{" "}
                        {DateTime.fromMillis(lastModified).toFormat("dd.MM.yyyy HH:mm")}
                    </Text>
                )}

                {lastModifiedDate && (
                    <Text mb={1} fontSize="12px" color={lightTextColor}>
                        <Text as="span" color={blueTextColor} mr={2}>
                            {t("global:upload.modified", "Letzte Änderung")}:
                        </Text>{" "}
                        {DateTime.fromISO(lastModifiedDate).toFormat("dd.MM.yyyy HH:mm")}
                    </Text>
                )}

                {size && (
                    <Text mb={1} fontSize="12px" color={lightTextColor}>
                        <Text as="span" color={blueTextColor} mr={2}>
                            {t("global:upload.size", "Größe")}:
                        </Text>{" "}
                        <>{getSize(size)}</>
                    </Text>
                )}

                {selectTypes && (
                    <Select
                        size="xs"
                        mt={5}
                        variant="filled"
                        placeholder={t("file.slide.placeholder", "Auswählen")}
                        value={String(defaultSelectValue)}
                        onChange={(e) => onSelectType(e)}
                    >
                        {selectTypes.map((value, index) => (
                            <option key={index} value={value.key}>
                                {value.name}
                            </option>
                        ))}
                    </Select>
                )}
            </Box>
        </Flex>
    );
};

export default FileSlideView;
