/** @format */

import { FileUploads } from "@api/ext/ApparatusAdapter";
import {
    Avatar,
    Box,
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
} from "@chakra-ui/react";
import ProgressiveImage from "@components/ProgressiveImage/ProgressiveImage";
import RenderFileIcon from "@components/RenderFileIcon/RenderFileIcon";
import { useFileUploadContext } from "@context/FileUploadProvider";
import { allowedFileTypesImg, getFileTypeFromFile } from "@services/FileTypes";
import defaultColors from "@theme/defaultColors";
import useDefaultColors from "@theme/useDefaultColors";
import axios from "axios";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FiMoreHorizontal, FiXCircle } from "react-icons/fi";

interface SingleFileViewProps {
    index: number;
    removeSelectedImg?: (id: number) => void;
    selected?: boolean;
    file?: FileUploads;
    lastItem?: boolean;
    viewList?: boolean;
    viewEdit?: boolean;
    typeName?: string;
    base?: boolean;
}

const SingleFileView: React.FC<SingleFileViewProps> = ({
    index,
    removeSelectedImg = () => null,
    selected = false,
    file,
    lastItem = false,
    viewList = false,
    viewEdit = false,
    typeName,
    base = false,
}) => {
    const [t] = useTranslation();
    const { defaultTextColor, lightTextColor, redTextColor, defaultBoxBgColor } =
        useDefaultColors();

    const { handleSelectFile } = useFileUploadContext();

    const handleGetFile = useCallback((file: FileUploads) => {
        const url = file && file.url ? `${file.url}` : "#";

        if (!url) {
            return;
        }

        void axios({
            url,
            method: "GET",
            responseType: "blob",
            withCredentials: true,
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${file.original_name}`);
            document.body.appendChild(link);
            link.click();
        });
    }, []);

    const user = file?.uploadowner?.user;

    const avatarSrc = `${import.meta.env.VITE_BACKEND_URL}/core/uploads/getAvatar/`;

    if (!file) {
        return null;
    }

    return (
        <Flex
            alignItems="center"
            key={`${file.id}-index`}
            cursor="pointer"
            borderBottom={lastItem ? "none" : "1px"}
            borderBottomColor="inherit"
            justifyContent="space-between"
            bg={base ? defaultBoxBgColor : "transparent"}
            p={2}
            pl={0}
            borderLeft="inherit"
        >
            <Flex
                alignItems="center"
                onClick={() => handleSelectFile(index)}
                pl={2}
                borderLeftColor={selected ? defaultColors.blue[100] : defaultColors.transparent}
                borderLeftWidth="2px"
                _hover={{ opacity: 0.7, borderLeftColor: defaultColors.blue[30] }}
            >
                {allowedFileTypesImg.includes(file.file_type) ? (
                    <ProgressiveImage
                        src={file.url}
                        placeholderSrc={file.url + "/thumb"}
                        mr={2}
                        borderRadius={2}
                        height="40px"
                        width="40px"
                        objectFit="contain"
                    />
                ) : (
                    <Flex
                        borderRadius={2}
                        mr={2}
                        height="40px"
                        width="40px"
                        alignItems="center"
                        justifyContent="center"
                        rounded="sm"
                        backgroundColor={defaultColors.blue[100]}
                    >
                        <RenderFileIcon type={getFileTypeFromFile(file)} />
                    </Flex>
                )}
                <Box>
                    <Text
                        maxW={{ base: "200px", "2xl": "400px" }}
                        wordBreak="keep-all"
                        overflowX="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        fontSize={{ base: "xs", "2xl": "md" }}
                        color={defaultTextColor}
                    >
                        {file.original_name}
                    </Text>
                    <Flex flexDirection="row" gap={5}>
                        {user && (
                            <Flex alignItems="center">
                                <Avatar
                                    size="2xs"
                                    name={`${user.name} ${user.surname}`}
                                    src={avatarSrc + user.uuid + "/thumb"}
                                />
                                <Text
                                    ml={2}
                                    fontWeight="bold"
                                    fontSize="xs"
                                    color={lightTextColor}
                                >{`${user.name} ${user.surname}`}</Text>
                            </Flex>
                        )}

                        {typeName && (
                            <>
                                {user && <Text>|</Text>}
                                <Text fontSize="xs" color={defaultTextColor} fontStyle="italic">
                                    {typeName ? typeName : ""}
                                </Text>
                            </>
                        )}
                    </Flex>
                </Box>
            </Flex>
            <Flex alignItems="center">
                <Flex alignItems="center" display={{ base: "none", lg: "flex" }}>
                    <Text fontSize={{ base: "12px", "2xl": "14px" }} color={lightTextColor} mr={3}>
                        {DateTime.fromISO(file.created).toFormat("dd.MM.yyyy HH:mm")}
                    </Text>
                    {viewEdit && !base && (
                        <Box _hover={{ opacity: 0.5 }} onClick={() => removeSelectedImg(index)}>
                            <FiXCircle color={redTextColor} />
                        </Box>
                    )}
                </Flex>
                {viewList && (
                    <Menu>
                        <MenuButton
                            as={Button}
                            size="sm"
                            variant="ghost"
                            colorScheme="info"
                            _hover={{ opacity: 0.6 }}
                        >
                            <FiMoreHorizontal fontSize={20} />
                        </MenuButton>
                        <MenuList>
                            <MenuItem as="a" href={file.url} target="blank">
                                {t("singleFileDownload.show", "Ansicht")}
                            </MenuItem>

                            <MenuDivider />

                            <MenuItem onClick={() => handleGetFile(file)}>
                                {t("singleFileDownload.download", "Herunterladen")}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                )}
            </Flex>
        </Flex>
    );
};

export default SingleFileView;
